import React from 'react';
import { useBreakpointValue } from '@chakra-ui/react';

import { Container } from './styles';

const Background: React.FC = () => {
  const show = useBreakpointValue({
    base: false,
    lg: true,
  });

  return <>{show && <Container />}</>;
};

export default Background;
