import React, { useCallback, useEffect, useState } from 'react';

import Table from 'Components/Molecules/Table';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import IMarketplace from 'Types/Entities/IMarketplace';
import ISetState from 'Types/Standards/ISetState';
import {
  IPaginatedMarketplaceAccounts,
  useMarketplaces,
} from 'Hooks/marketplaces';
import Button from 'Components/Atoms/Button';

interface IProps {
  marketplace: IMarketplace;
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  marketplaceAccounts: IPaginatedMarketplaceAccounts;
  setMarketplaceAccounts: ISetState<IPaginatedMarketplaceAccounts>;
  onOpenForm(): void;
}

const MarketplaceAccountsTable: React.FC<IProps> = ({
  onOpenForm,
  marketplace,
  loadingTable,
  setLoadingTable,
  marketplaceAccounts,
  setMarketplaceAccounts,
}) => {
  const [page, setPage] = useState(1);

  const { indexMarketplaceAccounts, setCurrentMarketplaceAccount } =
    useMarketplaces();

  const handleIndex = useCallback(async () => {
    setLoadingTable(true);
    const accounts = await indexMarketplaceAccounts({
      data: {
        page,
        marketplace_id: marketplace.id,
      },
    });
    setLoadingTable(false);
    if (accounts) {
      setMarketplaceAccounts(accounts);
    }
  }, [
    indexMarketplaceAccounts,
    page,
    setLoadingTable,
    marketplace,
    setMarketplaceAccounts,
  ]);

  useEffect(() => {
    handleIndex();
  }, [handleIndex]);

  return (
    <Table
      isLoading={loadingTable}
      pagination={{
        currentPage: page,
        limit: 25,
        setPage,
        total: marketplaceAccounts.total,
      }}
      columns={[
        {
          key: 'username',
          title: 'username',
          dataIndex: 'username',
        },
        {
          key: 'actions',
          title: 'Actions',
          render: row => (
            <Button
              isPrimary
              onClick={() => {
                onOpenForm();
                setCurrentMarketplaceAccount(row as IMarketplaceAccount);
              }}
            >
              Edit Settings
            </Button>
          ),
        },
      ]}
      rows={marketplaceAccounts.entities}
    />
  );
};

export default MarketplaceAccountsTable;
