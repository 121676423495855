import React, { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import IMarketplace from 'Types/Entities/IMarketplace';
import IPaginated from 'Types/Standards/IPaginated';
import IOffer from 'Types/Entities/IOffer';
import OffersTable from '../OffersTable';
import OffersForm from '../OffersForm';

interface IProps {
  marketplace: IMarketplace;
}

const OffersTab: React.FC<IProps> = ({ marketplace }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  const [offers, setOffers] = useState<IPaginated<IOffer>>(
    {} as IPaginated<IOffer>,
  );

  return (
    <>
      <OffersForm
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        setLoadingTable={setLoadingTable}
        setOffers={setOffers}
        marketplace={marketplace}
      />
      <OffersTable
        onOpenForm={onOpen}
        marketplace={marketplace}
        loadingTable={loadingTable}
        setLoadingTable={setLoadingTable}
        offers={offers}
        setOffers={setOffers}
      />
    </>
  );
};

export default OffersTab;
