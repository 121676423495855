import React, { useCallback, useMemo, useState } from 'react';
import CSS from 'csstype';
import {
  Box,
  Table as BaseTable,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
  ResponsiveValue,
  useColorModeValue,
  BoxProps,
  Skeleton,
  Alert,
  AlertIcon,
  AlertDescription,
  IconButton,
} from '@chakra-ui/react';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';

import Pagination, { IPaginationProps } from '../Pagination';

interface IRow {
  [key: string]: any;
}

interface IColumn {
  key: string;
  title: string;
  dataIndex?: string;
  render?(row: IRow): any;
  visible?: boolean;
}

interface IProps extends BoxProps {
  columns: IColumn[];
  rows: IRow[];
  pagination?: IPaginationProps;
  isLoading?: boolean;
  accordion?: (row: any) => JSX.Element;
  accordionKey?: string;
}

type OverflowX = ResponsiveValue<CSS.Property.OverflowX>;

const Table: React.FC<IProps> = ({
  rows,
  pagination,
  isLoading,
  accordion,
  accordionKey,
  ...rest
}) => {
  const thBackgroundColor = useColorModeValue('gray.100', 'gray.600');
  const thTextColor = useColorModeValue('gray.600', 'gray.100');
  const trColor = useColorModeValue('gray.300', 'gray.900');
  const box = useBreakpointValue({
    base: {
      overflowX: 'scroll' as OverflowX,
    },
    lg: {
      overflowX: 'auto' as OverflowX,
    },
  });
  const [accordionSelectedIndex, setAccordionSelectedIndex] = useState<
    string | number
  >();

  const hasContent = useMemo(
    () => !pagination || (pagination && pagination.total > 0),
    [pagination],
  );

  const getRow = useCallback((column: IColumn, row: IRow) => {
    if (column.render) return column.render(row);
    if (column.dataIndex) return row[column.dataIndex];
    return '';
  }, []);

  const columns = useMemo(() => {
    return rest.columns.filter(
      column =>
        column.visible === true || typeof column.visible === 'undefined',
    );
  }, [rest.columns]);

  return (
    <Skeleton w="100%" isLoaded={!isLoading}>
      {hasContent && (
        <Box w="100%" overflowX={box?.overflowX} {...rest}>
          <BaseTable w="100%">
            <Thead>
              <Tr>
                {accordion && <Th backgroundColor={thBackgroundColor} />}
                {columns.map(column => (
                  <Th
                    backgroundColor={thBackgroundColor}
                    textColor={thTextColor}
                    key={column.key}
                  >
                    {column.title}
                  </Th>
                ))}
              </Tr>
            </Thead>
            <Tbody>
              {rows &&
                rows.map((row, index) => {
                  const key = accordionKey ? row[accordionKey] : index;
                  const isExpanded = accordionSelectedIndex === key;

                  return (
                    <>
                      <Tr bg={isExpanded ? trColor : ''} key={index}>
                        {accordion && (
                          <Td>
                            <IconButton
                              aria-label="Open"
                              size="sm"
                              icon={
                                isExpanded ? <FaChevronUp /> : <FaChevronDown />
                              }
                              variant="ghost"
                              rounded="md"
                              onClick={() =>
                                setAccordionSelectedIndex(isExpanded ? -1 : key)
                              }
                            />
                          </Td>
                        )}
                        {columns.map(column => (
                          <Td key={column.key}>{getRow(column, row)}</Td>
                        ))}
                      </Tr>
                      {accordion && isExpanded && (
                        <Tr bg={isExpanded ? trColor : ''}>
                          <Td colSpan={columns.length + 1}>{accordion(row)}</Td>
                        </Tr>
                      )}
                    </>
                  );
                })}
            </Tbody>
          </BaseTable>

          {pagination && <Pagination {...pagination} />}
        </Box>
      )}

      {!hasContent && (
        <Alert>
          <AlertIcon />
          <AlertDescription>No data found!</AlertDescription>
        </Alert>
      )}
    </Skeleton>
  );
};

export default Table;
