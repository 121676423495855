import React, { useCallback, useState } from 'react';
import { Flex, Text } from '@chakra-ui/react';

import { useNotifications } from 'Hooks/notifications';
import { useColor } from 'Hooks/color';

const HeaderNotificationsClearAll: React.FC = () => {
  const color = useColor();
  const { markAllNotificationsAsRead, indexNotifications } = useNotifications();

  const [loading, setLoading] = useState(false);

  const handleMarkAllAsRead = useCallback(async () => {
    if (loading) {
      return;
    }

    setLoading(true);
    await markAllNotificationsAsRead();
    await indexNotifications({});
    setLoading(false);
  }, [markAllNotificationsAsRead, loading, indexNotifications]);

  return (
    <Flex justifyContent="flex-end" pr="10px" pb="5px">
      <Text
        textDecoration="underline"
        color={loading ? `${color}.100` : `${color}.300`}
        cursor="pointer"
        fontSize="sm"
        onClick={handleMarkAllAsRead}
      >
        Mark all as read
      </Text>
    </Flex>
  );
};

export default HeaderNotificationsClearAll;
