import React, { useEffect } from 'react';

import WithDrawer from 'Components/Templates/WithDrawer';
import PageContainer from 'Components/Atoms/PageContainer';
import JobsTable from 'Components/Organisms/JobsTable';

const Jobs: React.FC = () => {
  useEffect(() => {
    document.title = 'Jobs - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <JobsTable />
      </PageContainer>
    </WithDrawer>
  );
};

export default Jobs;
