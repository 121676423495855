import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import ISetState from 'Types/Standards/ISetState';
import { useUsers } from 'Hooks/users';
import ICreateUserData from 'Types/DTOs/ICreateUserData';
import { Role } from '@spiry-capital/modules';
import { useRoles } from 'Hooks/roles';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
}

const UsersForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);
  const [roles, setRoles] = useState<Role[]>([]);
  const [loadinRoles, setLoadingRoles] = useState(false);

  const {
    indexUsers,
    setCurrentUser,
    currentUser,
    editUser,
    createUser,
    getUser,
    user,
  } = useUsers();
  const { indexRoles } = useRoles();

  useEffect(() => {
    setLoadingForm(true);
    getUser().finally(() => {
      setLoadingForm(false);
    });
  }, [getUser]);

  const isEdit = useMemo(() => !!currentUser, [currentUser]);
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} user`,
    [isEdit],
  );
  const companyName = useMemo(
    () => (isEdit ? '' : `_${user.vendor?.name}`),
    [isEdit, user.vendor?.name],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);
  const roleOptions = useMemo<ISelectOption[]>(
    () =>
      roles.map(role => ({
        label: role.name.replace('_', ' '),
        value: role.id,
      })),
    [roles],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentUser(null);
  }, [onClose, setCurrentUser]);

  const submitForm = useCallback(
    async (data: ICreateUserData) => {
      setLoadingForm(true);
      if (isEdit && currentUser) {
        await editUser({
          data: {
            ...data,
            id: currentUser.id,
          },
          formRef,
          onClose,
        });
      } else {
        await createUser({
          data,
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);

      setLoadingTable(true);
      await indexUsers({
        data: {
          page: 1,
        },
      });
      setLoadingTable(false);
    },
    [
      isEdit,
      currentUser,
      indexUsers,
      setLoadingTable,
      editUser,
      onClose,
      createUser,
    ],
  );

  useEffect(() => {
    setLoadingRoles(true);
    indexRoles()
      .then(data => {
        if (!data) {
          return;
        }
        setRoles(data);
      })
      .finally(() => {
        setLoadingRoles(false);
      });
  }, [indexRoles]);

  return (
    <Card title="Actions">
      <Button type="button" onClick={onOpen} isPrimary>
        Create user
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>
          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentUser || {}}
            >
              <Input
                placeholder="Email"
                label="Email"
                name="email"
                type="text"
                defaultValue={currentUser?.email}
              />
              <Input
                placeholder="Username"
                label="Username"
                name="username"
                type="text"
                defaultValue={currentUser?.username}
                rightAddon={companyName}
                isDisabled={isEdit}
              />
              <Input
                placeholder="Fullname"
                label="Fullname"
                name="fullname"
                type="text"
                defaultValue={currentUser?.fullname}
              />
              <Select
                placeholder="Select a role"
                name="role_id"
                label="Role"
                defaultValue={currentUser?.role?.name}
                options={roleOptions}
                isLoading={loadinRoles}
              />
              <Input
                placeholder="Password"
                label="Password"
                defaultValue=""
                name="password"
                type="password"
                autoComplete="new-password"
              />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default UsersForm;
