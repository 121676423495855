import React, { useCallback, useEffect, useState } from 'react';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import ISetState from 'Types/Standards/ISetState';
import formatDate from 'Helpers/formatDate';
import { useCharges } from 'Hooks/charges';
import { useAuth } from 'Hooks/auth';
import userHasRole from 'Helpers/userHasRole';
import { Tag } from '@chakra-ui/react';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
}

const ChargesTable: React.FC<IProps> = ({ loadingTable, setLoadingTable }) => {
  const [page, setPage] = useState(1);

  const { charges, indexCharges } = useCharges();
  const { user } = useAuth();

  const getRowStatusColor = useCallback((failed: boolean) => {
    switch (failed) {
      case false:
        return {
          color: 'green',
          text: 'Completed',
        };
      case true:
        return {
          color: 'red',
          text: 'Errored',
        };
      default:
        return {
          color: 'yellow',
          text: 'Pending',
        };
    }
  }, []);

  useEffect(() => {
    setLoadingTable(true);
    indexCharges({
      data: {
        page,
      },
    }).finally(() => {
      setLoadingTable(false);
    });
  }, [setLoadingTable, page, indexCharges]);

  return (
    <Card title="Billing">
      <Table
        isLoading={loadingTable}
        columns={[
          {
            key: 'status',
            title: 'status',
            dataIndex: 'status',
            render: row => (
              <Tag
                textTransform="capitalize"
                colorScheme={getRowStatusColor(row.failed).color}
              >
                {getRowStatusColor(row.failed).text}
              </Tag>
            ),
          },
          {
            key: 'attempts',
            title: 'Attempts',
            dataIndex: 'attempts',
          },
          {
            key: 'name',
            title: 'Vendor',
            render: row => row.vendor.name,
            visible: !!userHasRole(user, 5),
          },
          {
            key: 'charged',
            title: 'Charged',
            dataIndex: 'charged',
          },
          {
            key: 'trade_volume',
            title: 'Trade Volume',
            dataIndex: 'trade_volume',
          },
          {
            key: 'from',
            title: 'period',
            render: row => {
              return `${formatDate(row.from)} - ${formatDate(row.to)}`;
            },
          },
          {
            key: 'created_at',
            title: 'created at',
            render: row => formatDate(row.created_at),
          },
        ]}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: charges.total,
        }}
        rows={charges.entities}
      />
    </Card>
  );
};

export default ChargesTable;
