import * as Yup from 'yup';

export default Yup.object().shape({
  funds_auto_enabled: Yup.boolean().required('enabled required'),
  funds_minimum: Yup.string().required('funds minimum required'),
  funds_target: Yup.string().required('funds target required'),
  marketplace_account_id: Yup.string().required(
    'marketplace account id required',
  ),
});
