import React, { useEffect } from 'react';

import WithDrawer from 'Components/Templates/WithDrawer';
import PageContainer from 'Components/Atoms/PageContainer';
import ChargeVendors from 'Components/Organisms/ChargeVendors';

const ChargeVendorsSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'Billing Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <ChargeVendors />
      </PageContainer>
    </WithDrawer>
  );
};

export default ChargeVendorsSettings;
