import React, { useEffect } from 'react';
import { Box, IconButton, MenuButton } from '@chakra-ui/react';
import { FiBell } from 'react-icons/fi';

import { useColor } from 'Hooks/color';
import { useNotifications } from 'Hooks/notifications';

const HeaderNotificationsBell: React.FC = () => {
  const color = useColor();
  const { countUnreadNotifications, unreadCount } = useNotifications();

  useEffect(() => {
    countUnreadNotifications({
      type: 'internal',
    });
  }, [countUnreadNotifications]);

  return (
    <Box position="relative">
      <MenuButton
        _hover={{}}
        _active={{}}
        as={IconButton}
        icon={<FiBell />}
        variant="ghost"
      />
      <Box
        position="absolute"
        bottom="-5px"
        right="0"
        borderRadius="50%"
        backgroundColor={`${color}.300`}
        width="20px"
        height="20px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        fontSize="12px"
      >
        {unreadCount}
      </Box>
    </Box>
  );
};

export default HeaderNotificationsBell;
