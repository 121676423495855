import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import ISetState from 'Types/Standards/ISetState';
import { useUsers } from 'Hooks/users';
import { User } from '@spiry-capital/modules';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpenForm(): void;
}

const UsersTable: React.FC<IProps> = ({
  onOpenForm,
  loadingTable,
  setLoadingTable,
}) => {
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { indexUsers, deleteUser, setCurrentUser, users } = useUsers();

  useEffect(() => {
    setLoadingTable(true);
    indexUsers({
      data: {
        page,
      },
    }).finally(() => {
      setLoadingTable(false);
    });
  }, [indexUsers, page, setLoadingTable]);

  return (
    <Card title="Users">
      <Table
        isLoading={loadingTable}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: users.total,
        }}
        columns={[
          {
            key: 'username',
            title: 'username',
            render: row => row.username,
          },
          {
            key: 'fullname',
            title: 'fullname',
            render: row => row.fullname,
          },
          {
            key: 'role',
            title: 'role',
            render: row =>
              row.role?.name
                .replace('_', ' ')
                .replace(/\w\S*/g, (w: string) =>
                  w.replace(/^\w/, (c: string) => c.toUpperCase()),
                ),
          },
          {
            key: 'actions',
            title: 'actions',
            render: row => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick() {
                      onOpenForm();
                      setCurrentUser(row as User);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteUser({
                        data: {
                          user_id: row.id,
                        },
                      });
                      setLoadingDelete(false);
                      setLoadingTable(true);
                      await indexUsers({
                        data: {
                          page: 1,
                        },
                      });
                      setLoadingTable(false);
                    },
                    confirmation: {
                      title: `Delete user ${row.username}`,
                      body: 'Are you sure you want to delete this user and all data attached to it?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        rows={users.entities}
      />
    </Card>
  );
};

export default UsersTable;
