import React, { useMemo, useState } from 'react';
import {
  Box,
  Flex,
  Icon,
  Skeleton,
  Text,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';
import {
  FiAlertCircle,
  FiUpload,
  FiCheckCircle,
  FiArrowUpCircle,
  FiArrowRightCircle,
} from 'react-icons/fi';

import { Trade } from '@spiry-capital/modules';

import { useColor } from 'Hooks/color';
import formatDate from 'Helpers/formatDate';
import Button from 'Components/Atoms/Button';
import ControlledSwitch from 'Components/Atoms/ControlledSwitch';
import { useTrades } from 'Hooks/trades';
import TradeActionsModal from '../TradeActionsModal';

interface IProps {
  trade: Trade;
  loading: boolean;
  hash: string;
  attached?: boolean;
}

const TradeInfo: React.FC<IProps> = ({ trade, loading, hash, attached }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [whichModal, setWhichModal] = useState('');

  const userColor = useColor();
  const { updateAutoMessages } = useTrades();

  const defaultColor = useColorModeValue(
    `${userColor}.500`,
    `${userColor}.600`,
  );

  const footerColor = useColorModeValue(`gray.200`, `gray.600`);
  const bgColor = useColorModeValue(`gray.100`, `gray.800`);

  const startedAt = useMemo(
    () =>
      trade.started_at
        ? formatDate(trade.started_at, 'MM/dd/yyyy - HH:mm')
        : '',
    [trade.started_at],
  );

  return (
    <>
      <Skeleton isLoaded={!loading}>
        <Box>
          <Text fontSize="2xl">Actions</Text>
          <Flex alignItems="center" mt={3}>
            <ControlledSwitch
              key={trade.id}
              onChange={async ({ value, setLoading, setChecked }) => {
                setLoading(true);
                await updateAutoMessages({
                  data: {
                    trade_hash: hash,
                    auto_messages_disabled: !value,
                  },
                  setChecked,
                });
                setLoading(false);
              }}
              defaultValue={!trade.auto_messages_disabled}
            />
            <Text fontSize="1xl" ml={2}>
              Automatic messages
            </Text>
          </Flex>

          <Flex my={5} gridGap={3}>
            <Button
              isPrimary
              variant="outline"
              disabled={trade.status === 'Released'}
              onClick={() => {
                onOpen();
                setWhichModal('upload');
              }}
            >
              <Icon as={FiUpload} mr={1} w={4} h={4} /> Upload image
            </Button>

            <Button
              colorScheme="red"
              disabled={
                trade.status === 'Dispute open' || trade.status === 'Released'
              }
              onClick={() => {
                onOpen();
                setWhichModal('dispute');
              }}
            >
              <Icon as={FiAlertCircle} mr={1} w={4} h={4} /> Start dispute
            </Button>
            <Button
              isPrimary
              disabled={
                trade.status === 'Dispute open' || trade.status === 'Released'
              }
              onClick={() => {
                onOpen();
                setWhichModal('release');
              }}
            >
              {' '}
              <Icon as={FiCheckCircle} mr={1} w={4} h={4} />{' '}
              {trade.status === 'Released' ? 'Released' : 'Release bitcoins'}
            </Button>
          </Flex>
        </Box>
        <Box w="100%" maxWidth="600px">
          <Box
            w="100%"
            d="flex"
            p={2}
            borderTopRadius={5}
            justifyContent="center"
            bg={defaultColor}
          >
            <Text fontSize="1xl"> Trade: {trade.hash} </Text>
          </Box>
          <Box p={5} bg={bgColor}>
            <Flex justifyContent="space-between" mb={4}>
              <Flex flexDir="column" gridGap={attached ? 3 : ''}>
                Amount ({trade?.fiat_currency_code}):
                <Text
                  fontSize="3xl"
                  color={defaultColor}
                  mt={0}
                  fontWeight="bold"
                >
                  ${Number(trade?.fiat_amount_requested).toFixed(2)}
                </Text>
              </Flex>
              <Flex alignItems="center">
                <Icon as={FiArrowUpCircle} mr={1} w={6} h={6} />
                {trade.margin}%
              </Flex>
              <Flex flexDir="column" gridGap={attached ? 3 : ''}>
                Amount (BTC):
                <Text fontSize="3xl" color={defaultColor} fontWeight="bold">
                  {(trade.crypto_amount_requested * 10 ** -8).toFixed(8)}
                </Text>
              </Flex>
            </Flex>
            <Flex flexDir="column" mb={4} gridGap={attached ? 3 : ''}>
              Payment method:
              <Text fontSize="xl">{trade.payment_method_name}</Text>
            </Flex>
            <Flex flexDir="column" mb={4} gridGap={attached ? 3 : ''}>
              Status:
              <Text fontSize="xl">{trade.status}</Text>
            </Flex>
            <Flex flexDir="column" mb={4} gridGap={attached ? 3 : ''}>
              Started at:
              <Text fontSize="xl">{startedAt}</Text>
            </Flex>
            <Flex flexDir="column" gridGap={attached ? 3 : ''}>
              Marketplace:
              <Text fontSize="xl">
                {trade?.marketplace_account?.marketplace.name}
              </Text>
            </Flex>
          </Box>
          <Box
            w="100%"
            d="flex"
            p={2}
            borderBottomRadius={5}
            alignItems="center"
            justifyContent="space-evenly"
            bg={footerColor}
          >
            <Text fontSize="1xl">
              {trade.owner_username ? trade.owner_username : trade.seller}
            </Text>
            <Icon as={FiArrowRightCircle} w={6} h={6} />
            <Text fontSize="1xl">
              {trade.responder_username
                ? trade.responder_username
                : trade.buyer}
            </Text>
          </Box>
        </Box>
      </Skeleton>

      <TradeActionsModal
        hash={hash}
        isOpen={isOpen}
        onClose={onClose}
        whichModal={whichModal}
      />
    </>
  );
};

export default TradeInfo;
