import React, { useEffect } from 'react';

import WithDrawer from 'Components/Templates/WithDrawer';
import PageContainer from 'Components/Atoms/PageContainer';
import PayQuickSettingsForm from 'Components/Organisms/PayQuickSettingsForm';

const PayQuickSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'Payquick Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <PayQuickSettingsForm />
      </PageContainer>
    </WithDrawer>
  );
};

export default PayQuickSettings;
