import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import OfferGroupsForm from 'Components/Organisms/OfferGroupsForm';
import OfferGroupsTable from 'Components/Organisms/OfferGroupsTable';
import WithDrawer from 'Components/Templates/WithDrawer';

const OfferGroups: React.FC = () => {
  useEffect(() => {
    document.title = 'Offer Groups - Orion';
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  return (
    <WithDrawer>
      <PageContainer>
        <OfferGroupsForm
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setLoadingTable={setLoadingTable}
        />
        <OfferGroupsTable
          onOpenForm={onOpen}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
        />
      </PageContainer>
    </WithDrawer>
  );
};

export default OfferGroups;
