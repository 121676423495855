import React, { useCallback, useEffect, useState } from 'react';

import Table from 'Components/Molecules/Table';
import Button from 'Components/Atoms/Button';
import { Skeleton } from '@chakra-ui/react';
import { usePredefinedMessages } from 'Hooks/predefinedMessages';

const PredefinedMessagesTable: React.FC = () => {
  const [page, setPage] = useState(1);
  const [loadingTable, setLoadingTable] = useState(true);
  const [handlingPredefinedMessage, setHandlingPredefinedMessage] =
    useState(false);

  const { predefinedMessages, getPredefinedMessages, deletePredefinedMessage } =
    usePredefinedMessages();

  useEffect(() => {
    setLoadingTable(true);
    getPredefinedMessages().then(() => setLoadingTable(false));
  }, [getPredefinedMessages, setLoadingTable]);

  const handleDelete = useCallback(
    id => {
      setHandlingPredefinedMessage(true);

      deletePredefinedMessage({ data: id })
        .then(() => setHandlingPredefinedMessage(false))
        .then(() => setLoadingTable(true))
        .then(() => getPredefinedMessages())
        .finally(() => setLoadingTable(false));
    },
    [deletePredefinedMessage, getPredefinedMessages],
  );

  return (
    <Skeleton isLoaded={!loadingTable}>
      <Table
        isLoading={loadingTable}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: predefinedMessages.total,
        }}
        columns={[
          {
            key: 'message',
            title: 'message',
            dataIndex: 'message',
          },
          {
            key: 'actions',
            title: 'Actions',
            render: row => (
              <Button
                colorScheme="red"
                isPrimary
                onClick={() => handleDelete(row.id)}
                confirmation={{
                  body: ' Are you sure you want to delete this message?',
                  buttonText: 'Delete',
                  colorScheme: 'red',
                  placement: 'top',
                  isLoading: handlingPredefinedMessage,
                }}
              >
                Delete
              </Button>
            ),
          },
        ]}
        rows={predefinedMessages.predefined_messages}
      />
    </Skeleton>
  );
};

export default PredefinedMessagesTable;
