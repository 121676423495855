import React, { createContext, useCallback, useContext, useState } from 'react';
import api from 'Services/api';

import IDefaultDeleteData from 'Types/DTOs/IDefaultDeleteData';
import IPredefinedMessage from 'Types/Entities/IPredefinedMessage';
import IPaginationData from 'Types/Standards/IPaginationData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import createPredefinedMessageSchema from 'Schemas/createPredefinedMessage';
import { useErrors } from './errors';

interface ICreatePredefinedMessage
  extends Omit<IDefaultRequest<IPaginationData>, 'data'> {
  message: string;
}

type IDeletePredefinedMessage = Omit<
  IDefaultRequest<IDefaultDeleteData>,
  'formRef'
>;

interface IPredefinedMessagesContext {
  predefinedMessages: IPredefinedMessage;
  getPredefinedMessages(): Promise<void>;
  createPredefinedMessage(props: ICreatePredefinedMessage): Promise<void>;
  deletePredefinedMessage(props: IDeletePredefinedMessage): Promise<void>;
}

const PredefinedMessagesContext = createContext<IPredefinedMessagesContext>(
  {} as IPredefinedMessagesContext,
);

export const PredefinedMessagesProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [predefinedMessages, setPredefinedMessages] = useState(
    {} as IPredefinedMessage,
  );

  const getPredefinedMessages = useCallback(async () => {
    try {
      const response = await api.get<IPredefinedMessage>(
        `/predefined-messages`,
      );
      setPredefinedMessages(response.data);
    } catch (err) {
      handleErrors('Error when trying to get the predefined messages', err);
    }
  }, [handleErrors]);

  const createPredefinedMessage = useCallback(
    async ({ message }: ICreatePredefinedMessage) => {
      try {
        await createPredefinedMessageSchema.validate(
          { message },
          {
            abortEarly: true,
          },
        );

        await api.post(`/predefined-messages`, {
          message,
        });
      } catch (err) {
        handleErrors('Error when trying to create a predefined message', err);
      }
    },
    [handleErrors],
  );

  const deletePredefinedMessage = useCallback(
    async ({ data: id }: IDeletePredefinedMessage): Promise<void> => {
      try {
        await api.delete(`/predefined-messages/${id}`);
      } catch (err) {
        handleErrors('Error when trying to delete predefined message', err);
      }
    },
    [handleErrors],
  );

  return (
    <PredefinedMessagesContext.Provider
      value={{
        getPredefinedMessages,
        createPredefinedMessage,
        deletePredefinedMessage,
        predefinedMessages,
      }}
    >
      {children}
    </PredefinedMessagesContext.Provider>
  );
};

export const usePredefinedMessages = (): IPredefinedMessagesContext => {
  const context = useContext(PredefinedMessagesContext);
  if (!context) {
    throw new Error(
      'usePredefinedMessages must be used within PredefinedMessagesContext',
    );
  }
  return context;
};

export default PredefinedMessagesProvider;
