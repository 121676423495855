import React, { useEffect, useMemo, useState } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import { Skeleton } from '@chakra-ui/react';
import OffersTab from 'Components/Organisms/OffersTab';
import { useMarketplaces } from 'Hooks/marketplaces';
import Tabs from 'Components/Molecules/Tabs';
import Card from 'Components/Atoms/Card';

const Offers: React.FC = () => {
  useEffect(() => {
    document.title = 'Offers Management - Orion';
  }, []);

  const [loadingOffers, setLoadingOffers] = useState(false);
  const { indexMarketplaces, marketplaces } = useMarketplaces();

  const tabs = useMemo(
    () =>
      marketplaces.map(marketplace => ({
        title: marketplace.name,
        component: <OffersTab marketplace={marketplace} />,
      })),
    [marketplaces],
  );

  useEffect(() => {
    setLoadingOffers(true);
    indexMarketplaces().finally(() => {
      setLoadingOffers(false);
    });
  }, [indexMarketplaces]);

  return (
    <WithDrawer>
      <PageContainer>
        <Card title="Offers management">
          <Skeleton isLoaded={!loadingOffers}>
            <Tabs tabs={tabs} />
          </Skeleton>
        </Card>
      </PageContainer>
    </WithDrawer>
  );
};

export default Offers;
