import React, { useCallback, useMemo, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Center,
  Flex,
  Icon,
  Menu,
  MenuButton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import { IconType } from 'react-icons';
import userHasRole from 'Helpers/userHasRole';
import { useAuth } from 'Hooks/auth';
import localStorageConfig from 'Config/localStorage';
import { useColor } from 'Hooks/color';
import { useDrawer } from 'Hooks/drawer';
import { User } from '@spiry-capital/modules';
import IRoleLevels from 'Types/Enums/IRoleLevels';

export interface INavItemProps {
  navSize?: string;
  setNavSize?: any;
  icon?: IconType;
  title: string;
  active?: boolean;
  link?: string;
  roleLevel?: IRoleLevels;
  subItems?: INavItemProps[];
}

const NavItem: React.FC<INavItemProps> = ({
  title,
  navSize,
  setNavSize,
  icon,
  subItems,
  link,
  roleLevel,
  active = false,
}) => {
  const themeVariant = useColorModeValue('gray.200', 'gray.800');
  const iconColor = useColorModeValue('gray.600', 'white');
  const acordionBackground = useColorModeValue('gray.100', 'gray.800');
  const hoverBackground = useColorModeValue('gray.200', 'gray.600');

  const { user: storeUser } = useAuth();
  const accordion = useRef(null);
  const history = useHistory();
  const userColor = useColor();
  const { onClose } = useDrawer();

  const user = useMemo<User | null>(
    () => {
      const localUser = localStorage.getItem(localStorageConfig.userKey);
      if (!localUser) {
        return null;
      }
      return JSON.parse(localUser);
    },
    // eslint-disable-next-line
    [storeUser],
  );

  const showAccordion = useMemo(() => {
    if (!subItems) return false;
    return subItems.some(item => userHasRole(user, item.roleLevel));
  }, [subItems, user]);

  const showLink = useMemo(() => {
    if (subItems) return false;
    return userHasRole(user, roleLevel);
  }, [subItems, user, roleLevel]);

  const isActive = useMemo(
    () => history.location.pathname === link,
    [history, link],
  );

  const hasSubItemActive = useMemo(
    () =>
      subItems?.reduce(
        (result, item) => result || item.link === history.location.pathname,
        false,
      ),
    [history, subItems],
  );

  const handleRedirect = useCallback(
    (to?: string) => {
      history.push(to || '/');
      onClose();
    },
    [history, onClose],
  );

  if (userHasRole(user, roleLevel))
    return (
      <Flex
        flexDir="column"
        w="100%"
        alignItems={navSize === 'small' ? 'center' : 'flex-start'}
      >
        <Menu placement="right">
          {showAccordion && subItems && (
            <Accordion
              allowToggle
              w="100%"
              mt={0}
              onChange={() => setNavSize('large')}
              ref={accordion}
              borderRadius="4px"
              overflow="hidden"
              defaultIndex={hasSubItemActive ? 0 : 1}
              _hover={{
                textDecor: 'none',
                backgroundColor: isActive
                  ? `${userColor}.300`
                  : hoverBackground,
              }}
            >
              <AccordionItem border="none">
                <h2>
                  <AccordionButton
                    _expanded={{
                      bg: navSize === 'small' ? 'none' : themeVariant,
                    }}
                    p="0"
                    borderRadius="4px 4px 0 0"
                    w="100%"
                    d="flex"
                    justifyContent="space-between"
                  >
                    <Flex
                      justifyContent={
                        navSize === 'small' ? 'center' : 'space-between'
                      }
                      w="100%"
                      p={2}
                    >
                      <Flex alignItems="center">
                        <Icon as={icon} fontSize="xl" color={iconColor} />
                        <Text ml={2} d={navSize === 'small' ? 'none' : 'flex'}>
                          {title}
                        </Text>
                      </Flex>
                      {navSize !== 'small' && <AccordionIcon mt={0.5} />}
                    </Flex>
                  </AccordionButton>
                </h2>
                {navSize === 'large' && (
                  <AccordionPanel
                    p={0}
                    bg={acordionBackground}
                    borderRadius="0 0 8px 8px"
                  >
                    {subItems.map(
                      (item, i) =>
                        userHasRole(user, item.roleLevel) && (
                          <MenuButton
                            key={i}
                            backgroundColor={
                              history.location.pathname === item.link
                                ? `${userColor}.300`
                                : 'inherit'
                            }
                            _hover={{
                              backgroundColor:
                                history.location.pathname === item.link
                                  ? `${userColor}.300`
                                  : 'inherit',
                            }}
                            w="100%"
                            px={5}
                            onClick={() => handleRedirect(item.link)}
                          >
                            <Flex>
                              <Center>
                                <Text ml={4}>{item.title}</Text>
                              </Center>
                            </Flex>
                          </MenuButton>
                        ),
                    )}
                  </AccordionPanel>
                )}
              </AccordionItem>
            </Accordion>
          )}

          {showLink && (
            <MenuButton
              bg={active === true ? 'gray.200' : 'none'}
              w="100%"
              p={2}
              borderRadius="4"
              _hover={{
                textDecor: 'none',
                backgroundColor: isActive
                  ? `${userColor}.300`
                  : hoverBackground,
              }}
              backgroundColor={isActive ? `${userColor}.300` : 'inherit'}
              onClick={() => handleRedirect(link)}
            >
              <Flex w="100%">
                <Center w={navSize === 'small' ? '100%' : 'auto'}>
                  <Icon as={icon} fontSize="xl" color={iconColor} />
                  <Text ml={2} d={navSize === 'small' ? 'none' : 'flex'}>
                    {title}
                  </Text>
                </Center>
              </Flex>
            </MenuButton>
          )}
        </Menu>
      </Flex>
    );

  return <></>;
};

export default NavItem;
