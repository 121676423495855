import * as Yup from 'yup';

export default Yup.object().shape({
  name: Yup.string().required('name required'),
  offers: Yup.array().required('offers required'),
  fee_percentage: Yup.number().typeError('fee percentage invalid'),
  margin: Yup.number().typeError('margin invalid'),
  payquick_prepaid_enabled: Yup.boolean(),
  payquick_vip_enabled: Yup.boolean(),
  static_message: Yup.string(),
  static_message_enabled: Yup.boolean(),
  watson_assistant: Yup.string(),
  watson_enabled: Yup.boolean(),
  processing_fee: Yup.number().typeError('processing fee invalid'),
  active: Yup.boolean(),
});
