import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import MarketplacesTable from 'Components/Organisms/MarketplacesTable';
import MarketplacesForm from 'Components/Organisms/MarketplacesForm';

const Marketplaces: React.FC = () => {
  useEffect(() => {
    document.title = 'Marketplaces - Orion';
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  return (
    <WithDrawer>
      <PageContainer>
        <MarketplacesForm
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setLoadingTable={setLoadingTable}
        />
        <MarketplacesTable
          onOpenForm={onOpen}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
        />
      </PageContainer>
    </WithDrawer>
  );
};

export default Marketplaces;
