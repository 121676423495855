import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Switch from 'Components/Atoms/Switch';
import ISetState from 'Types/Standards/ISetState';
import IMarketplace from 'Types/Entities/IMarketplace';
import ICreateMarketplaceAccountData from 'Types/DTOs/ICreateMarketplaceAccountData';
import {
  IPaginatedMarketplaceAccounts,
  useMarketplaces,
} from 'Hooks/marketplaces';

interface IProps {
  isOpen: boolean;
  marketplace: IMarketplace;
  setLoadingTable: ISetState<boolean>;
  setMarketplaceAccounts: ISetState<IPaginatedMarketplaceAccounts>;
  onOpen(): void;
  onClose(): void;
}

const MarketplaceAccountsForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  marketplace,
  setLoadingTable,
  setMarketplaceAccounts,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);

  const {
    currentMarketplaceAccount,
    setCurrentMarketplaceAccount,
    createMarketplaceAccount,
    indexMarketplaceAccounts,
    updateMarketplaceAccount,
  } = useMarketplaces();

  const isEdit = useMemo(
    () => !!currentMarketplaceAccount,
    [currentMarketplaceAccount],
  );
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} marketplace account`,
    [isEdit],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentMarketplaceAccount(null);
  }, [onClose, setCurrentMarketplaceAccount]);

  const submitForm = useCallback(
    async (data: Omit<ICreateMarketplaceAccountData, 'marketplace_id'>) => {
      setLoadingForm(true);
      if (isEdit && currentMarketplaceAccount) {
        await updateMarketplaceAccount({
          data: {
            id: currentMarketplaceAccount.id,
            marketplace_id: marketplace.id,
            ...data,
          },
          formRef,
          onClose,
        });
      } else {
        await createMarketplaceAccount({
          data: {
            marketplace_id: marketplace.id,
            ...data,
          },
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);

      setLoadingTable(true);
      const accounts = await indexMarketplaceAccounts({
        data: {
          page: 1,
          marketplace_id: marketplace.id,
        },
      });
      setLoadingTable(false);
      if (accounts) {
        setMarketplaceAccounts(accounts);
      }
    },
    [
      isEdit,
      onClose,
      marketplace,
      setLoadingTable,
      indexMarketplaceAccounts,
      createMarketplaceAccount,
      currentMarketplaceAccount,
      updateMarketplaceAccount,
      setMarketplaceAccounts,
    ],
  );

  return (
    <Box mb="20px">
      <Button type="button" onClick={onOpen} isPrimary>
        Create marketplace account
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentMarketplaceAccount || {}}
            >
              <Input
                placeholder="Case_sensitive"
                label="Username"
                name="username"
                type="text"
              />
              {marketplace.name === 'koinzaar' && (
                <Input
                  placeholder="Secret"
                  label="Koinzaar key"
                  name="koinzaar_key"
                  type="text"
                />
              )}
              <Switch label="Enabled" name="enabled" size="md" />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default MarketplaceAccountsForm;
