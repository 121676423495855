import React, { useEffect, useState } from 'react';

import Card from 'Components/Atoms/Card';
import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import Table from 'Components/Molecules/Table';
import { useGiftCards } from 'Hooks/giftCards';
import getCurrencyString from 'Helpers/getCurrencyString';

const PrepaidCards: React.FC = () => {
  const { indexPrepaidCards, prepaidCards } = useGiftCards();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    document.title = 'Prepaid Cards - Orion';
  }, []);

  useEffect(() => {
    setLoading(true);
    indexPrepaidCards({
      page,
    }).finally(() => {
      setLoading(false);
    });
  }, [indexPrepaidCards, page]);

  return (
    <WithDrawer>
      <PageContainer>
        <Card title="Prepaid cards">
          <Table
            isLoading={loading}
            columns={[
              {
                key: 'card_number',
                title: 'card number',
                dataIndex: 'card_number',
              },
              {
                key: 'amount',
                title: 'amount',
                render(row) {
                  return getCurrencyString(row.amount);
                },
              },
              {
                key: 'cvv',
                title: 'cvv',
                dataIndex: 'cvv',
              },
              {
                key: 'exp_month',
                title: 'exp month',
                dataIndex: 'exp_month',
              },
              {
                key: 'exp_year',
                title: 'exp year',
                dataIndex: 'exp_year',
              },
            ]}
            rows={prepaidCards.entities}
            pagination={{
              currentPage: page,
              limit: 25,
              setPage,
              total: prepaidCards.total,
            }}
          />
        </Card>
      </PageContainer>
    </WithDrawer>
  );
};

export default PrepaidCards;
