import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Box,
  Button,
  Image,
  keyframes,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Skeleton,
  useDisclosure,
  usePrefersReducedMotion,
} from '@chakra-ui/react';
import { FiRotateCw } from 'react-icons/fi';
import IAttachment from 'Types/Entities/IAttachment';
import api from 'Services/api';
import { useColor } from 'Hooks/color';

interface IAttachmentProps {
  attachment: IAttachment;
  trade_hash: string;
}

const Attachment: React.FC<IAttachmentProps> = ({ attachment, trade_hash }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const prefersReducedMotion = usePrefersReducedMotion();

  const [content, setContent] = useState<string>();
  const [loading, setLoading] = useState(true);
  const [imageRotation, setImageRotation] = useState(0);

  const color = useColor();

  const loadAttachment = useCallback(async () => {
    setLoading(true);

    const { data, headers } = await api.get(
      `/marketplaces/attachments/${trade_hash}/${attachment.image_hash}`,
      {
        responseType: 'arraybuffer',
      },
    );

    const mimetype = String(headers['content-type']);

    const buffer = Buffer.from(data);
    const base64 = `data:${mimetype};base64,${buffer.toString('base64')}`;
    setContent(base64);

    setLoading(false);
  }, [attachment.image_hash, trade_hash]);

  useEffect(() => {
    loadAttachment();
  }, [loadAttachment]);

  const spin = keyframes`
    from { transform: rotate(${imageRotation}deg); }
    to { transform: rotate(${imageRotation}deg); }
  `;

  const animation = prefersReducedMotion
    ? undefined
    : `${spin} infinite 1s linear`;

  const imageRef = useRef<HTMLImageElement>(null);

  const boxWidth = useMemo(() => {
    return imageRotation % 180 === 0
      ? imageRef.current?.width
      : imageRef.current?.height;
  }, [imageRotation]);

  const boxHeight = useMemo(() => {
    return imageRotation % 180 === 0
      ? imageRef.current?.height
      : imageRef.current?.width;
  }, [imageRotation]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        size="base"
        isCentered
        preserveScrollBarGap
        blockScrollOnMount
        returnFocusOnClose={false}
      >
        <ModalOverlay />
        <ModalContent width="fit-content">
          <ModalBody
            pt={5}
            display="flex"
            justifyContent="center"
            width="fit-content"
          >
            <Box
              minW={boxWidth}
              minH={boxHeight}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Image
                onClick={onOpen}
                borderRadius={3}
                objectFit="fill"
                maxH="80vh"
                maxW="80vh"
                rotate="180"
                src={content}
                alt={attachment.image_hash}
                animation={animation}
                ref={imageRef}
              />
            </Box>
          </ModalBody>

          <ModalFooter pb={5}>
            <Button
              colorScheme={color}
              mr={3}
              onClick={() => setImageRotation(imageRotation + 90)}
              alignItems="center"
              variant="outline"
              leftIcon={<FiRotateCw />}
            >
              Rotate
            </Button>
            <Button colorScheme={color} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Skeleton isLoaded={!loading}>
        <Image
          onClick={onOpen}
          borderRadius={3}
          maxH="285px"
          minH="100px"
          cursor="zoom-in"
          objectFit="fill"
          src={content}
          alt={attachment.image_hash}
        />
      </Skeleton>
    </>
  );
};

export default Attachment;
