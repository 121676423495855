import React, { useCallback, useRef, useState } from 'react';
import { IconType } from 'react-icons';
import { FiMoreVertical } from 'react-icons/fi';
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from '@chakra-ui/react';

interface IConfirmation {
  title: string;
  body: string;
  buttonText: string;
  colorScheme: string;
}

interface IActionsMenuItem {
  text: string;
  icon: IconType;
  loading?: boolean;
  onClick(): void | Promise<void>;
  confirmation?: IConfirmation;
}

interface IProps {
  items: IActionsMenuItem[];
}

const ActionsMenu: React.FC<IProps> = ({ items }) => {
  const [confirmationItem, setConfirmationItem] =
    useState<IActionsMenuItem | null>(null);
  const confirmationCancelRef = useRef();

  const handleOnClick = useCallback((item: IActionsMenuItem) => {
    if (!item.confirmation) {
      item.onClick();
      return;
    }
    setConfirmationItem(item);
  }, []);

  const handleOnClose = useCallback(() => {
    setConfirmationItem(null);
  }, []);

  return (
    <>
      <Menu>
        <MenuButton
          as={IconButton}
          icon={<FiMoreVertical />}
          variant="outline"
          p="0"
          size="sm"
        />
        <MenuList>
          {items.map((item, i) => (
            <MenuItem
              key={i}
              icon={<item.icon />}
              onClick={() => handleOnClick(item)}
              isDisabled={item.loading}
            >
              {item.text}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>

      {confirmationItem && confirmationItem.confirmation && (
        <AlertDialog
          isOpen={!!confirmationItem}
          // @ts-ignore
          leastDestructiveRef={confirmationCancelRef}
          onClose={handleOnClose}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {confirmationItem.confirmation.title}
              </AlertDialogHeader>

              <AlertDialogBody>
                {confirmationItem.confirmation.body}
              </AlertDialogBody>

              <AlertDialogFooter>
                <Button
                  // @ts-ignore
                  ref={confirmationCancelRef}
                  onClick={handleOnClose}
                >
                  Cancel
                </Button>
                <Button
                  colorScheme={confirmationItem.confirmation.colorScheme}
                  onClick={confirmationItem.onClick}
                  ml={3}
                >
                  {confirmationItem.confirmation.buttonText}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default ActionsMenu;
