import React, { useRef, useEffect } from 'react';
import { useField } from '@unform/core';
import { v4 } from 'uuid';
import {
  Textarea as BaseTextarea,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  InputRightElement,
  TextareaProps,
} from '@chakra-ui/react';

import InputError from '../InputError';

interface IProps extends TextareaProps {
  name: string;
  label?: string;
  helper?: string;
  helperColor?: string;
  readonly?: boolean;
}

const Textarea: React.FC<IProps> = ({
  name,
  label,
  helper,
  helperColor,
  ...rest
}) => {
  const { fieldName, error, registerField, defaultValue } = useField(name);

  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: 'value',
    });
  }, [registerField, fieldName, textareaRef]);

  return (
    <FormControl id={v4()}>
      {label && <FormLabel mb="5px">{label}</FormLabel>}
      <InputGroup>
        <BaseTextarea
          variant="outline"
          name={name}
          ref={textareaRef}
          defaultValue={defaultValue}
          {...rest}
        />

        {error && (
          <InputRightElement>
            <InputError error={error} />
          </InputRightElement>
        )}
      </InputGroup>

      {helper && (
        <FormHelperText color={helperColor || 'inherit'}>
          {helper}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default Textarea;
