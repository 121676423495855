import React, { useCallback, useRef, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import { IPredefinedMessage } from 'Types/Entities/IPredefinedMessage';
import { usePredefinedMessages } from 'Hooks/predefinedMessages';

interface IProps {
  onClose(): void;
  isOpen: boolean;
}

const PredefinedMessages: React.FC<IProps> = ({ onClose, isOpen }) => {
  const formRef = useRef<FormHandles>(null);

  const { createPredefinedMessage, getPredefinedMessages } =
    usePredefinedMessages();

  const [handlingPredefinedMessage, setHandlingPredefinedMessage] =
    useState(false);

  const handleCreatePredefinedMessage = useCallback(
    ({ message }: IPredefinedMessage) => {
      setHandlingPredefinedMessage(true);
      createPredefinedMessage({
        formRef,
        message,
      })
        .then(() => setHandlingPredefinedMessage(false))
        .then(() => getPredefinedMessages())
        .finally(() => onClose());
      formRef.current?.clearField('message');
    },
    [createPredefinedMessage, getPredefinedMessages, onClose],
  );

  return (
    <Box mb="20px">
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Predefined Message</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Form
              ref={formRef}
              onSubmit={handleCreatePredefinedMessage}
              spacing="20px"
            >
              <Input
                key="message"
                name="message"
                label="Message"
                type="text"
                isTruncated={false}
              />
            </Form>
          </ModalBody>

          <ModalFooter>
            <ButtonGroup>
              <Button type="button" variant="outline" mr={3} onClick={onClose}>
                Cancel
              </Button>
              <Button
                type="button"
                colorScheme="red"
                onClick={() => formRef.current?.submitForm()}
                isLoading={handlingPredefinedMessage}
                isPrimary
              >
                Create
              </Button>
            </ButtonGroup>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default PredefinedMessages;
