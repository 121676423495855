import React, { useCallback, useEffect, useState } from 'react';
import {
  Box,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
} from '@chakra-ui/react';

import { useSettings } from 'Hooks/settings';
import { useColor } from 'Hooks/color';

const FundsSettingsAPIForm: React.FC = () => {
  const { getSetting, upsertSettings } = useSettings();
  const color = useColor();

  const [fundsAPI, setFundsAPI] = useState('none');
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const handleOnChange = useCallback(
    async (value: string) => {
      setUpdating(true);
      await upsertSettings({
        funds_api: value,
      });
      setFundsAPI(value);
      setUpdating(false);
    },
    [upsertSettings],
  );

  useEffect(() => {
    setLoading(true);
    getSetting({
      data: {
        name: 'funds_api',
      },
    })
      .then(setting => {
        setFundsAPI(setting?.value || 'none');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [getSetting]);

  return (
    <Box mb="20px">
      <Skeleton isLoaded={!loading}>
        <RadioGroup onChange={handleOnChange} value={fundsAPI}>
          <FormLabel>Which API do you want to use?</FormLabel>
          <Stack direction="row">
            <Radio colorScheme={color} value="ftx" isDisabled={updating}>
              FTX
            </Radio>
            <Radio colorScheme={color} value="kraken" isDisabled={updating}>
              Kraken
            </Radio>
            <Radio colorScheme={color} value="none" isDisabled={updating}>
              None
            </Radio>
          </Stack>
        </RadioGroup>
      </Skeleton>
    </Box>
  );
};

export default FundsSettingsAPIForm;
