import React, { useCallback } from 'react';
import { Box, Flex, MenuItem, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { Notification } from '@spiry-capital/modules';
import { useColor } from 'Hooks/color';
import { useNotifications } from 'Hooks/notifications';

interface IProps {
  notification: Notification;
}

const HeaderNotification: React.FC<IProps> = ({ notification }) => {
  const color = useColor();
  const { markNotificationAsRead, indexNotifications } = useNotifications();
  const history = useHistory();

  const handleSelectNotification = useCallback(() => {
    markNotificationAsRead(notification.id.toString()).then(() => {
      indexNotifications({});
    });

    switch (notification.event) {
      case 'trade_started':
        const { content } = notification;
        const beforeHash = content.search('hash');
        const afterHash = content.search('has been started');
        const startIndex = beforeHash + 5;
        const endIndex = afterHash - 1;
        const hash = content.slice(startIndex, endIndex);
        history.push(`/trading/${hash}`);
        break;
      default:
        break;
    }
  }, [markNotificationAsRead, notification, indexNotifications, history]);

  return (
    <MenuItem w="300px" onClick={handleSelectNotification}>
      <Flex alignItems="center">
        <Box
          w="5px"
          h="5px"
          borderRadius="50%"
          backgroundColor={!notification.read_at ? color : 'transparent'}
          mr="5px"
        />
        <Box>
          <Text fontSize="xs">{notification.subject}</Text>
          <Text w="250px" isTruncated fontSize="sm">
            {notification.content}
          </Text>
        </Box>
      </Flex>
    </MenuItem>
  );
};

export default HeaderNotification;
