import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';

import Card from 'Components/Atoms/Card';
import ControlledSwitch from 'Components/Atoms/ControlledSwitch';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import IUserPayrollSetting from 'Types/Entities/IUserPayrollSetting';
import ISetState from 'Types/Standards/ISetState';
import { usePayrolls } from 'Hooks/payrolls';
import NavLink from 'Components/Atoms/NavLink';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpenForm(): void;
}

const UserPayrollSettingsTable: React.FC<IProps> = ({
  onOpenForm,
  loadingTable,
  setLoadingTable,
}) => {
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    userPayrollSettings,
    indexUserPayrollSettings,
    setCurrentUserPayrollSetting,
    deleteUserPayrollSetting,
    updateUserPayrollSetting,
  } = usePayrolls();

  useEffect(() => {
    setLoadingTable(true);
    indexUserPayrollSettings({
      data: {
        page,
      },
    }).finally(() => {
      setLoadingTable(false);
    });
  }, [indexUserPayrollSettings, page, setLoadingTable]);

  return (
    <Card title="User payroll settings">
      <Alert mb="20px" status="info" borderRadius={5}>
        <AlertIcon />
        <AlertDescription>
          You also need to setup{' '}
          <NavLink text="exchange settings" to="/funds-settings" /> as it will
          be used to pay the employees.
        </AlertDescription>
      </Alert>

      <Table
        isLoading={loadingTable}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: userPayrollSettings.total,
        }}
        columns={[
          {
            key: 'active',
            title: 'active',
            render: row => (
              <ControlledSwitch
                defaultValue={row.active}
                onChange={async ({ value, setChecked, setLoading }) => {
                  setLoading(true);
                  await updateUserPayrollSetting({
                    data: {
                      active: value,
                      amount_usd: row.amount_usd,
                      frequency: row.frequency,
                      id: row.id,
                      user_id: row.user_id,
                    },
                    setChecked,
                  });
                  setLoading(false);
                }}
              />
            ),
          },
          {
            key: 'username',
            title: 'username',
            render: row => row.user?.username,
          },
          {
            key: 'frequency',
            title: 'frequency',
            dataIndex: 'frequency',
          },
          {
            key: 'amount_usd',
            title: 'usd amount',
            render: row => Number(row.amount_usd).toLocaleString(),
          },
          {
            key: 'actions',
            title: 'actions',
            render: row => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick() {
                      onOpenForm();
                      setCurrentUserPayrollSetting(row as IUserPayrollSetting);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteUserPayrollSetting({
                        data: {
                          user_id: row.user_id,
                        },
                      });
                      setLoadingDelete(false);
                      setLoadingTable(true);
                      await indexUserPayrollSettings({
                        data: {
                          page: 1,
                        },
                      });
                      setLoadingTable(false);
                    },
                    confirmation: {
                      title: `Delete fixed payroll of ${row.user.username}`,
                      body: 'Are you sure you want to delete this fixed payroll?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        rows={userPayrollSettings.entities}
      />
    </Card>
  );
};

export default UserPayrollSettingsTable;
