import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import GeneralSettingsForm from 'Components/Organisms/GeneralSettingsForm';

const GeneralSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'General Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <GeneralSettingsForm />
      </PageContainer>
    </WithDrawer>
  );
};

export default GeneralSettings;
