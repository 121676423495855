import React, { useEffect } from 'react';

import WithDrawer from 'Components/Templates/WithDrawer';
import PageContainer from 'Components/Atoms/PageContainer';
import FundsSettingsReplenishment from 'Components/Organisms/FundsSettingsReplenishment';
import FundsSettingsExchangeAPIs from 'Components/Organisms/FundsSettingsExchangeAPIs';

const FundsSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'Funds Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <FundsSettingsReplenishment />
        <FundsSettingsExchangeAPIs />
      </PageContainer>
    </WithDrawer>
  );
};

export default FundsSettings;
