import React from 'react';

import IMarketplace from 'Types/Entities/IMarketplace';
import ChargeVendorsSettingsForm from '../ChargeVendorsSettingsForm';

interface IProps {
  marketplace: IMarketplace;
}

const ChargeVendorSettings: React.FC<IProps> = ({ marketplace }) => {
  return (
    <>
      <ChargeVendorsSettingsForm marketplace={marketplace} />
    </>
  );
};

export default ChargeVendorSettings;
