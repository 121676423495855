import React from 'react';
import { Flex } from '@chakra-ui/react';

import DrawerWrapper from 'Components/Organisms/DrawerWrapper';
import Header from '../../Organisms/Header';

const WithDrawer: React.FC = ({ children }) => {
  return (
    <Flex w="100%">
      <DrawerWrapper />
      <Flex direction="column" mt="20px" flex="1" overflowX="auto">
        <Header />
        {children}
      </Flex>
    </Flex>
  );
};

export default WithDrawer;
