import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import Background from 'Components/Atoms/Background';
import LoginForm from 'Components/Organisms/LoginForm';

const Login: React.FC = () => {
  useEffect(() => {
    document.title = 'Login - Orion';
  }, []);

  return (
    <Flex h="100vh">
      <Background />
      <LoginForm />
    </Flex>
  );
};

export default Login;
