import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Button from 'Components/Atoms/Button';
import Switch from 'Components/Atoms/Switch';
import IObject from 'Types/Standards/IObject';
import { useSettings } from 'Hooks/settings';
import Card from 'Components/Atoms/Card';

const GeneralSettingsForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { indexSelectedSettings, upsertVendorSettings } = useSettings();

  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [formData, setFormData] = useState<IObject>({} as IObject);

  const getSettings = useCallback(async () => {
    setLoadingForm(true);
    const data = await indexSelectedSettings<IObject>({
      data: {
        names: ['release_coins_without_processing_card'],
      },
    });
    setFormData(data);
    setLoadingForm(false);
  }, [indexSelectedSettings, setLoadingForm]);

  const handleUpsertSettings = useCallback(
    async (data: IObject) => {
      setLoadingUpsert(true);
      await upsertVendorSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      getSettings();
    },
    [formRef, getSettings, upsertVendorSettings],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Card title="General settings">
      <Skeleton isLoaded={!loadingForm}>
        <Form
          ref={formRef}
          onSubmit={handleUpsertSettings}
          spacing="20px"
          initialData={formData}
        >
          <Switch
            name="release_coins_without_processing_card"
            label="Allow users to release coins without processing card"
          />
          <Box>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingUpsert}
              isPrimary
            >
              Save
            </Button>
          </Box>
        </Form>
      </Skeleton>
    </Card>
  );
};

export default GeneralSettingsForm;
