import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Alert, Box, Button, Center, Flex, Icon, Text } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import { useUsers } from 'Hooks/users';

import IChangeAddress from 'Types/DTOs/IChangeAdress';

import { FiAlertCircle } from 'react-icons/fi';
import { useColor } from 'Hooks/color';

const PersonalSettingsForm: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const formRef = useRef<FormHandles>(null);

  const { user, getUser, changeAddress } = useUsers();
  const color = useColor();

  useEffect(() => {
    setLoading(true);
    getUser().finally(() => {
      setLoading(false);
    });
  }, [getUser]);

  const handleSubmit = useCallback(
    async (data: IChangeAddress) => {
      if (data.address) {
        setLoading(true);
        await changeAddress({ data, formRef });
        setLoading(false);
      }
    },
    [changeAddress],
  );

  return (
    <Form ref={formRef} onSubmit={handleSubmit} spacing="15px">
      <Flex Flex gridGap="25px" justifyContent="space-between">
        <Box flex="1">
          <Input
            label="Username"
            name="username"
            type="text"
            isReadOnly
            value={user.username}
          />
        </Box>
        <Box flex="1">
          <Input
            label="Fullname"
            name="fullname"
            type="text"
            isReadOnly
            value={user.fullname}
          />
        </Box>
      </Flex>
      <Flex gridGap="25px">
        <Box flex="1">
          <Input
            label="Role"
            name="role"
            type="text"
            isReadOnly
            value={user.role?.name}
          />
        </Box>
        <Box flex="1">
          <Input
            label="Company"
            name="company"
            isReadOnly
            type="text"
            value={user.vendor?.name}
          />
        </Box>
      </Flex>
      <Flex gridGap="25px">
        <Box flex="1">
          <Input
            label="Crypto address"
            placeholder="ETH address (0x000...)"
            name="address"
            type="text"
            defaultValue={user.crypto_address}
          />
        </Box>
      </Flex>

      <Center>
        <Alert status="warning" borderRadius={8}>
          <Icon as={FiAlertCircle} w={4} cursor="pointer" />

          <Text ml="5px">Be sure to insert a valid ETH address.</Text>
        </Alert>
      </Center>
      <Flex gridGap="25px">
        <Button type="submit" colorScheme={color} isPrimary isLoading={loading}>
          Save changes
        </Button>
      </Flex>
    </Form>
  );
};

export default PersonalSettingsForm;
