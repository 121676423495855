import React from 'react';
import { Button, useDisclosure } from '@chakra-ui/react';

import Card from 'Components/Atoms/Card';
import PredefinedMessagesTable from '../PredefinedMessagesTable';
import PredefinedMessagesDrawer from '../PredefinedMessagesDrawer';

const PredefinedMessages: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <PredefinedMessagesDrawer isOpen={isOpen} onClose={onClose} />
      <Card title="Actions">
        <Button colorScheme="red" isPrimary onClick={onOpen}>
          Create predefined message
        </Button>
      </Card>
      <Card title="Predefined Messages">
        <PredefinedMessagesTable />
      </Card>
    </>
  );
};

export default PredefinedMessages;
