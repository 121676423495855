import React, { createContext, useCallback, useContext, useState } from 'react';
import { Report } from '@spiry-capital/modules';

import IObject from 'Types/Standards/IObject';
import IFilterReportsData from 'Types/DTOs/IFilterReportsData';
import api from 'Services/api';
import { useErrors } from './errors';

interface IReportsContext {
  reports: Report[];
  indexReports(vendor_id?: string): Promise<void>;
  getReport(data: IFilterReportsData): IObject | null;
}

const ReportsContext = createContext<IReportsContext>({} as IReportsContext);

const ReportsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [reports, setReports] = useState<Report[]>([]);

  const indexReports = useCallback(
    async (vendor_id?: string) => {
      try {
        const response = await api.get('/reports', {
          params: {
            vendor_id,
          },
        });
        setReports(response.data);
      } catch (err) {
        handleErrors('Error when trying to index reports', err);
      }
    },
    [handleErrors],
  );

  const getReport = useCallback(
    ({
      name,
      marketplace_id,
      marketplace_account_id,
      vendor_id,
    }: IFilterReportsData): IObject | null => {
      const report = reports.find(
        r =>
          r.name === name &&
          (!marketplace_account_id ||
            r.marketplace_account_id === marketplace_account_id) &&
          (!vendor_id || r.vendor_id === vendor_id) &&
          r.marketplace_id === marketplace_id,
      );

      if (!report) return null;

      try {
        return JSON.parse(report.value);
      } catch {
        return null;
      }
    },
    [reports],
  );

  return (
    <ReportsContext.Provider value={{ indexReports, getReport, reports }}>
      {children}
    </ReportsContext.Provider>
  );
};

export const useReports = (): IReportsContext => {
  const context = useContext(ReportsContext);
  if (!context) {
    throw new Error('useReports must be used within ReportsProvider');
  }
  return context;
};

export default ReportsProvider;
