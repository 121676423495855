import { useParams } from 'react-router-dom';
import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import PrepaidCardProcessingForm from 'Components/Organisms/PrepaidCardProcessingForm';
import WithDrawer from 'Components/Templates/WithDrawer';
import Card from 'Components/Atoms/Card';

const PrepaidCardProcessing: React.FC = () => {
  useEffect(() => {
    document.title = 'Prepaid Card Processing - Orion';
  }, []);

  const { hash } = useParams<{ hash: string }>();

  return (
    <WithDrawer>
      <PageContainer>
        <Card title="Card processing">
          <PrepaidCardProcessingForm hash={hash} />
        </Card>
      </PageContainer>
    </WithDrawer>
  );
};

export default PrepaidCardProcessing;
