import React, { createContext, useCallback, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import IRecoveryPasswordData from 'Types/DTOs/IRecoveryPasswordData';
import IRequestRecoveryPasswordData from 'Types/DTOs/IRequestRecoveryPasswordData';
import recoveryPasswordSchema from 'Schemas/recoveryPAsswordSchema';
import requestRecoveryPasswordSchema from 'Schemas/requestRecoveryPasswordSchema';
import api from 'Services/api';
import { useErrors } from './errors';

type IRequestRecoveryPassword = IDefaultRequest<IRequestRecoveryPasswordData>;
type IRecoveryPassword = IDefaultRequest<IRecoveryPasswordData>;

interface IPasswordContext {
  requestRecoveryPassword(props: IRequestRecoveryPassword): Promise<void>;
  recoveryPassword(props: IRecoveryPassword): Promise<void>;
}

const PasswordContext = createContext<IPasswordContext>({} as IPasswordContext);

export const PasswordProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();
  const history = useHistory();
  const toast = useToast();

  const requestRecoveryPassword = useCallback(
    async ({ data, formRef }: IRequestRecoveryPassword): Promise<void> => {
      try {
        await requestRecoveryPasswordSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/users/request-recovery-password', data);
        history.push('/recovery-password');
      } catch (err) {
        handleErrors(
          'Error when trying to request recovery password token',
          err,
          formRef,
        );
      }
    },
    [handleErrors, history],
  );

  const recoveryPassword = useCallback(
    async ({ data, formRef }: IRecoveryPassword): Promise<void> => {
      try {
        await recoveryPasswordSchema.validate(data, {
          abortEarly: false,
        });
        delete data.confirm_password;
        await api.post('/users/recovery-password', data);
        toast({
          title: 'Password succesfully changed ',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top-right',
        });
        history.push('/');
      } catch (err) {
        handleErrors('Error when trying to recovery password', err, formRef);
      }
    },
    [handleErrors, history, toast],
  );

  return (
    <PasswordContext.Provider
      value={{ recoveryPassword, requestRecoveryPassword }}
    >
      {children}
    </PasswordContext.Provider>
  );
};

export const usePassword = (): IPasswordContext => {
  const context = useContext(PasswordContext);
  if (!context) {
    throw new Error('usePassword must be used within PasswordProvider');
  }
  return context;
};

export default PasswordProvider;
