import React, { useMemo } from 'react';
import { ButtonProps, useColorModeValue } from '@chakra-ui/react';

import Button from 'Components/Atoms/Button';

interface IProps extends ButtonProps {
  index?: number;
  pages?: number[];
  active?: boolean;
}

const PaginationButton: React.FC<IProps> = ({
  children,
  index,
  pages,
  active,
  ...rest
}) => {
  const unselectedTextColor = useColorModeValue('gray.600', 'gray.100');

  const zIndex = useMemo(() => (active ? '1' : '0'), [active]);
  const position = useMemo(() => (active ? 'absolute' : 'relative'), [active]);
  const textColor = useMemo(
    () => (active ? 'white' : unselectedTextColor),
    [active, unselectedTextColor],
  );
  const getBoundedBorders = useMemo<string>(() => {
    if (!pages || !pages.length || active) return '50%';
    if (index === 0) return '50% 0 0 50%';
    if (index === pages.length - 1) return '0 50% 50% 0';
    return '0';
  }, [pages, index, active]);

  return (
    <Button
      top="0"
      left="0"
      w="40px"
      h="40px"
      colorScheme="gray"
      zIndex={zIndex}
      color={textColor}
      isPrimary={!!active}
      position={position}
      borderRadius={getBoundedBorders}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default PaginationButton;
