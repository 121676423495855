import * as Yup from 'yup';

export default Yup.object().shape({
  amount: Yup.number().typeError('amount invalid').required('amount required'),
  card_number: Yup.string().required('card number required'),
  cvv: Yup.string().required('cvv required'),
  exp_month: Yup.string().required('month required'),
  exp_year: Yup.string().required('year required'),
  first_name: Yup.string().required('first name required'),
  last_name: Yup.string().required('last name required'),
  currency: Yup.string().required('currency required'),
  email: Yup.string().required('email required'),
});
