import React, { useCallback } from 'react';
import {
  Divider,
  Flex,
  Heading,
  Text,
  VStack,
  Box,
  useColorModeValue,
} from '@chakra-ui/react';

import Card from 'Components/Atoms/Card';
import { useColor } from 'Hooks/color';

interface IText {
  text: string;
  color?: 'standard' | 'primary' | 'secondary';
  size?: 'default' | 'large';
}

interface IDashboardCardItem {
  title: IText;
  subtitle?: IText;
}

interface IProps {
  title?: string;
  items: IDashboardCardItem[];
  subItems?: IDashboardCardItem[];
  period: string;
}

const DashboardCard: React.FC<IProps> = ({ items, subItems, period }) => {
  const color = useColor();

  const primaryAccent = useColorModeValue('500', '300');
  const secondaryAccent = useColorModeValue('300', '100');

  const getItemColor = useCallback(
    (itemColor?: string): string => {
      if (itemColor === 'primary') return `${color}.${primaryAccent}`;
      if (itemColor === 'secondary') return `${color}.${secondaryAccent}`;
      return 'inherit';
    },
    [color, primaryAccent, secondaryAccent],
  );

  const getItemSize = useCallback((itemSize?: string): string => {
    if (itemSize === 'large') return '20px';
    return 'inherit';
  }, []);

  return (
    <Card flex="1">
      {period && (
        <Heading mb="20px" size="md" textTransform="capitalize">
          {period}
        </Heading>
      )}
      <VStack mb="20px" spacing="10px" alignItems="flex-start">
        {items.map((item, i) => (
          <Flex key={i} alignItems="center" w="100%">
            <Box w="100%">
              <Text
                fontSize={getItemSize(item.title.size)}
                color={getItemColor(item.title.color)}
                // textAlign="center"
              >
                {item.title.text}
              </Text>
              {item.subtitle && (
                <Text
                  lineHeight="25px"
                  fontSize="25px"
                  color={getItemColor(item.subtitle.color)}
                  fontWeight="bold"
                  display="flex"
                  // textAlign="center"
                >
                  {item.subtitle.text}
                  <Text
                    lineHeight="20px"
                    fontSize="20px"
                    marginLeft="5px"
                    color={getItemColor(item.subtitle.color)}
                    fontWeight="bold"
                    // textAlign="center"
                  >
                    $
                  </Text>
                </Text>
              )}
            </Box>
          </Flex>
        ))}
      </VStack>

      <Divider mb="20px" />

      {subItems && subItems?.length > 0 && (
        <>
          <VStack spacing="10px" alignItems="flex-start">
            {subItems.map((item, i) => (
              <Flex key={i} alignItems="center">
                <Box>
                  <Text
                    fontSize={getItemSize(item.title.size)}
                    color={getItemColor(item.title.color)}
                  >
                    {item.title.text}
                  </Text>
                  {item.subtitle && (
                    <Text
                      lineHeight={getItemSize(item.subtitle.size)}
                      fontSize={getItemSize(item.subtitle.size)}
                      color={getItemColor(item.subtitle.color)}
                    >
                      {item.subtitle.text}
                    </Text>
                  )}
                </Box>
              </Flex>
            ))}
          </VStack>
        </>
      )}
    </Card>
  );
};

export default DashboardCard;
