import React, { useEffect, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  useDisclosure,
} from '@chakra-ui/react';

import { useMarketplaces } from 'Hooks/marketplaces';
import IMarketplace from 'Types/Entities/IMarketplace';
import Button from 'Components/Atoms/Button';
import Table from 'Components/Molecules/Table';
import IPaginated from 'Types/Standards/IPaginated';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import FundsSettingsFormWrapper from '../FundsSettingsFormWrapper';

interface IProps {
  marketplace: IMarketplace;
}

const ReplenishmentSettings: React.FC<IProps> = ({ marketplace }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { indexMarketplaceAccounts } = useMarketplaces();

  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [marketplaceAccounts, setMarketplaceAccounts] = useState(
    {} as IPaginated<IMarketplaceAccount> | null,
  );

  const { setCurrentMarketplaceAccount } = useMarketplaces();
  const [page, setPage] = useState(1);

  useEffect(() => {
    setLoadingAccounts(true);
    indexMarketplaceAccounts({
      data: {
        marketplace_id: marketplace.id,
        page,
      },
    })
      .then(data => {
        setMarketplaceAccounts(data);
      })
      .finally(() => {
        setLoadingAccounts(false);
      });
  }, [indexMarketplaceAccounts, marketplace, page]);

  return (
    <>
      <FundsSettingsFormWrapper isOpen={isOpen} onClose={onClose} />
      <Table
        isLoading={loadingAccounts}
        columns={[
          {
            key: 'username',
            title: 'Username',
            dataIndex: 'username',
          },
          {
            key: 'actions',
            title: 'Actions',
            render: row => (
              <Button
                isPrimary
                onClick={() => {
                  onOpen();
                  setCurrentMarketplaceAccount(row as IMarketplaceAccount);
                }}
              >
                Edit Settings
              </Button>
            ),
          },
        ]}
        rows={marketplaceAccounts?.entities || []}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: marketplaceAccounts?.total || 0,
        }}
      />

      <Alert mt="20px" status="warning" borderRadius={5}>
        <AlertIcon />
        <AlertDescription>
          These settings will be used to replenish marketplace balances.
        </AlertDescription>
      </Alert>
    </>
  );
};

export default ReplenishmentSettings;
