import React, { useCallback, useEffect, useState } from 'react';
import { format } from 'date-fns';
import { FiCheck } from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import Button from 'Components/Atoms/Button';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import { useNotifications } from 'Hooks/notifications';
import { Box, Flex } from '@chakra-ui/react';
import { useColor } from 'Hooks/color';

const NotificationsTable: React.FC = () => {
  const [loadingNotifications, setLoadingNotifications] = useState(false);
  const [loadingMarkAsRead, setLoadingMarkAsRead] = useState(false);
  const [loadingMarkAllAsRead, setLoadingMarkAllAsRead] = useState(false);
  const [page, setPage] = useState(1);

  const color = useColor();
  const {
    notifications,
    indexNotifications,
    markAllNotificationsAsRead,
    markNotificationAsRead,
  } = useNotifications();

  const handleIndexNotifications = useCallback(async () => {
    setLoadingNotifications(true);
    await indexNotifications({
      page,
      limit: 25,
      type: 'internal',
    });
    setLoadingNotifications(false);
  }, [indexNotifications, page]);

  const handleMarkAsRead = useCallback(
    async (id: string) => {
      setLoadingMarkAsRead(true);
      await markNotificationAsRead(id);
      setLoadingMarkAsRead(false);
      handleIndexNotifications();
    },
    [markNotificationAsRead, handleIndexNotifications],
  );

  const handleMarkAllAsRead = useCallback(async () => {
    setLoadingMarkAllAsRead(true);
    await markAllNotificationsAsRead();
    setLoadingMarkAllAsRead(false);
    handleIndexNotifications();
  }, [markAllNotificationsAsRead, handleIndexNotifications]);

  useEffect(() => {
    handleIndexNotifications();
  }, [handleIndexNotifications]);

  return (
    <Card title="Notifications">
      <Button
        isPrimary
        onClick={handleMarkAllAsRead}
        mb="20px"
        isLoading={loadingMarkAllAsRead}
      >
        Mark all as read
      </Button>

      <Table
        isLoading={loadingNotifications}
        columns={[
          {
            key: 'subject',
            title: 'subject',
            render: row => (
              <Flex alignItems="center">
                <Box
                  w="5px"
                  h="5px"
                  borderRadius="50%"
                  backgroundColor={!row.read_at ? color : 'transparent'}
                  mr="5px"
                />
                {row.subject}
              </Flex>
            ),
          },
          {
            key: 'content',
            title: 'content',
            dataIndex: 'content',
          },
          {
            key: 'read_at',
            title: 'read at',
            render: row =>
              row.read_at
                ? format(new Date(row.read_at), 'dd/MM/yyyy - HH:mm')
                : '',
          },
          {
            key: 'actions',
            title: 'actions',
            render: row =>
              row.read_at ? (
                ''
              ) : (
                <ActionsMenu
                  items={[
                    {
                      text: 'Mark as read',
                      icon: FiCheck,
                      loading: loadingMarkAsRead,
                      onClick: () => {
                        handleMarkAsRead(row.id);
                      },
                    },
                  ]}
                />
              ),
          },
        ]}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: notifications.total,
        }}
        rows={notifications.entities}
      />
    </Card>
  );
};

export default NotificationsTable;
