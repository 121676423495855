import React from 'react';
import { Heading, VStack, StackProps } from '@chakra-ui/react';

interface IProps extends StackProps {
  title?: string;
}

const FormGroup: React.FC<IProps> = ({ title, children, ...rest }) => {
  return (
    <VStack alignItems="flex-start" {...rest}>
      <Heading as="h2" size="md">
        {title}
      </Heading>
      {children}
    </VStack>
  );
};

export default FormGroup;
