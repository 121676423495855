import React, { createContext, useCallback, useContext, useState } from 'react';

import api from 'Services/api';
import IPayroll from 'Types/Entities/IPayroll';
import IUserPayrollSetting from 'Types/Entities/IUserPayrollSetting';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import IPaginated from 'Types/Standards/IPaginated';
import IPaginationData from 'Types/Standards/IPaginationData';
import IFormRef from 'Types/Standards/IFormRef';
import ISetState from 'Types/Standards/ISetState';
import IUpdateUserPayrollSettingData from 'Types/DTOs/IUpdateUserPayrollSettingData';
import ICreateUserPayrollSettingData from 'Types/DTOs/ICreateUserPayrollSettingData';
import IDeleteUserPayrollSettingData from 'Types/DTOs/IDeleteUserPayrollSettingData';
import createUserPayrollSettingSchema from 'Schemas/createUserPayrollSetting';
import { useErrors } from './errors';

type IPaginatedPayrolls = IPaginated<IPayroll>;
type IPaginatedUserPayrollSettings = IPaginated<IUserPayrollSetting>;
type IIndexPayrolls = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;
type IIndexUserPayrollSettings = Omit<
  IDefaultRequest<IPaginationData>,
  'formRef'
>;
interface IUpdateUserPayrollSetting
  extends Omit<IDefaultRequest<IUpdateUserPayrollSettingData>, 'formRef'> {
  formRef?: IFormRef;
  setChecked?: ISetState<boolean>;
  onClose?(): void;
}
interface ICreateUserPayrollSetting
  extends IDefaultRequest<ICreateUserPayrollSettingData> {
  onClose(): void;
}
type IDeleteUserPayrollSetting = Omit<
  IDefaultRequest<IDeleteUserPayrollSettingData>,
  'formRef'
>;

interface IPayrollsContext {
  payrolls: IPaginatedPayrolls;
  userPayrollSettings: IPaginatedUserPayrollSettings;
  currentUserPayrollSetting: IUserPayrollSetting | null;
  setCurrentUserPayrollSetting: ISetState<IUserPayrollSetting | null>;
  indexPayrolls(props: IIndexPayrolls): Promise<void>;
  triggerAllActivityPayrolls(): Promise<void>;
  triggerAllCustomPayrolls(): Promise<void>;
  indexUserPayrollSettings(props: IIndexUserPayrollSettings): Promise<void>;
  updateUserPayrollSetting(props: IUpdateUserPayrollSetting): Promise<void>;
  createUserPayrollSetting(props: ICreateUserPayrollSetting): Promise<void>;
  deleteUserPayrollSetting(props: IDeleteUserPayrollSetting): Promise<void>;
}

const PayrollsContext = createContext<IPayrollsContext>({} as IPayrollsContext);

export const PayrollsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [payrolls, setPayrolls] = useState<IPaginatedPayrolls>(
    {} as IPaginatedPayrolls,
  );
  const [userPayrollSettings, setUserPayrollSettings] =
    useState<IPaginatedUserPayrollSettings>(
      {} as IPaginatedUserPayrollSettings,
    );
  const [currentUserPayrollSetting, setCurrentUserPayrollSetting] =
    useState<IUserPayrollSetting | null>(null);

  const indexPayrolls = useCallback(
    async ({ data: { page } }: IIndexPayrolls) => {
      try {
        const response = await api.get('/payrolls', {
          params: {
            limit: 25,
            page,
          },
        });

        setPayrolls({
          entities: response.data.payrolls,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index payrolls', err);
      }
    },
    [handleErrors],
  );

  const triggerAllActivityPayrolls = useCallback(async () => {
    try {
      await api.post('/payrolls/trigger-activity');
    } catch (err) {
      handleErrors('Error when trying to trigger payrolls', err);
    }
  }, [handleErrors]);

  const triggerAllCustomPayrolls = useCallback(async () => {
    try {
      await api.post('/payrolls/trigger-custom');
    } catch (err) {
      handleErrors('Error when trying to trigger payrolls', err);
    }
  }, [handleErrors]);

  const indexUserPayrollSettings = useCallback(
    async ({ data: { page } }: IIndexUserPayrollSettings) => {
      try {
        const response = await api.get('/users/payroll-settings', {
          params: {
            page,
            limit: 25,
          },
        });
        setUserPayrollSettings({
          entities: response.data.user_payroll_settings,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index user payroll settings', err);
      }
    },
    [handleErrors],
  );

  const updateUserPayrollSetting = useCallback(
    async ({
      data: { id, ...rest },
      formRef,
      setChecked,
      onClose,
    }: IUpdateUserPayrollSetting) => {
      try {
        formRef?.current?.setErrors({});

        await createUserPayrollSettingSchema.validate(rest, {
          abortEarly: false,
        });

        await api.put(`/users/payroll-settings/${id}`, rest);

        if (setChecked) setChecked(rest.active);
        if (onClose) onClose();
      } catch (err) {
        handleErrors(
          'Error when trying to update user payroll settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const createUserPayrollSetting = useCallback(
    async ({
      data,
      formRef,
      onClose,
    }: ICreateUserPayrollSetting): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        await createUserPayrollSettingSchema.validate(data, {
          abortEarly: false,
        });

        await api.post('/users/payroll-settings', data);

        onClose();
      } catch (err) {
        handleErrors(
          'Error when trying to create a new user payroll setting',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const deleteUserPayrollSetting = useCallback(
    async ({ data: { user_id } }: IDeleteUserPayrollSetting): Promise<void> => {
      try {
        await api.delete(`/users/payroll-settings/${user_id}`);
      } catch (err) {
        handleErrors('Error when trying to delete user payroll setting', err);
      }
    },
    [handleErrors],
  );

  return (
    <PayrollsContext.Provider
      value={{
        payrolls,
        indexPayrolls,
        triggerAllActivityPayrolls,
        triggerAllCustomPayrolls,
        userPayrollSettings,
        indexUserPayrollSettings,
        updateUserPayrollSetting,
        currentUserPayrollSetting,
        setCurrentUserPayrollSetting,
        createUserPayrollSetting,
        deleteUserPayrollSetting,
      }}
    >
      {children}
    </PayrollsContext.Provider>
  );
};

export const usePayrolls = (): IPayrollsContext => {
  const context = useContext(PayrollsContext);

  if (!context) {
    throw new Error('usePayrolls must be used within PayrollsProvider');
  }

  return context;
};

export default PayrollsProvider;
