import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import ISetState from 'Types/Standards/ISetState';
import IMarketplace from 'Types/Entities/IMarketplace';
import formatDate from 'Helpers/formatDate';
import { useMarketplaces } from 'Hooks/marketplaces';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpenForm(): void;
}

const MarketplacesTable: React.FC<IProps> = ({
  onOpenForm,
  loadingTable,
  setLoadingTable,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    deleteMarketplace,
    indexMarketplaces,
    marketplaces,
    setCurrentMarketplace,
  } = useMarketplaces();

  useEffect(() => {
    setLoadingTable(true);
    indexMarketplaces().finally(() => {
      setLoadingTable(false);
    });
  }, [indexMarketplaces, setLoadingTable]);

  return (
    <Card title="Marketplaces">
      <Table
        isLoading={loadingTable}
        columns={[
          {
            key: 'name',
            title: 'name',
            dataIndex: 'name',
          },
          {
            key: 'created_at',
            title: 'created at',
            render: row => formatDate(row.created_at),
          },
          {
            key: 'updated_at',
            title: 'updated at',
            render: row => formatDate(row.updated_at),
          },
          {
            key: 'actions',
            title: 'actions',
            render: row => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick() {
                      onOpenForm();
                      setCurrentMarketplace(row as IMarketplace);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteMarketplace({
                        data: {
                          id: row.id,
                        },
                      });
                      setLoadingDelete(false);
                      setLoadingTable(true);
                      await indexMarketplaces();
                      setLoadingTable(false);
                    },
                    confirmation: {
                      title: `Delete marketplace ${row.name}`,
                      body: 'Are you sure you want to delete this marketplace and all data attached to it?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        rows={marketplaces}
      />
    </Card>
  );
};

export default MarketplacesTable;
