import React, { useEffect } from 'react';
import { Menu, MenuList } from '@chakra-ui/react';

import { useNotifications } from 'Hooks/notifications';
import HeaderNotificationsBell from 'Components/Molecules/HeaderNotificationsBell';
import HeaderNotification from 'Components/Molecules/HeaderNotification';
import HeaderNotificationsClearAll from 'Components/Molecules/HeaderNotificationsClearAll/inedx';
import HeaderNotificationsViewAll from 'Components/Molecules/HeaderNotificationsViewAll';

const HeaderNotificationsMenu: React.FC = () => {
  const { indexNotifications, notifications } = useNotifications();

  useEffect(() => {
    indexNotifications({});
  }, [indexNotifications]);

  return (
    <Menu>
      <HeaderNotificationsBell />
      {notifications.total > 0 && (
        <MenuList>
          <HeaderNotificationsClearAll />

          {notifications.entities &&
            notifications.entities.map((notification, i) => (
              <HeaderNotification key={i} notification={notification} />
            ))}

          <HeaderNotificationsViewAll />
        </MenuList>
      )}
    </Menu>
  );
};

export default HeaderNotificationsMenu;
