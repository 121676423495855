import React, { useCallback, useState } from 'react';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import { usePayrolls } from 'Hooks/payrolls';

const PayrollSettingsManualTriggering: React.FC = () => {
  const [loadingTrigger, setLoadingTrigger] = useState(false);

  const { triggerAllActivityPayrolls } = usePayrolls();

  const handletriggerAllActivityPayrolls = useCallback(async () => {
    setLoadingTrigger(true);
    await triggerAllActivityPayrolls();
    setLoadingTrigger(false);
  }, [triggerAllActivityPayrolls]);

  return (
    <Card title="Actions">
      <Button
        isPrimary
        isLoading={loadingTrigger}
        onClick={handletriggerAllActivityPayrolls}
        confirmation={{
          body: 'By clicking this button, all registered users on Kimai with pending payments will be paid. Are you sure you want to do this?',
          buttonText: 'Yes. I am sure.',
          colorScheme: 'red',
        }}
      >
        Trigger payrolls
      </Button>
    </Card>
  );
};

export default PayrollSettingsManualTriggering;
