import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ButtonGroup, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import Switch from 'Components/Atoms/Switch';
import IObject from 'Types/Standards/IObject';
import { useMarketplaces } from 'Hooks/marketplaces';
import { useSettings } from 'Hooks/settings';

interface IAvailableSetting {
  name: string;
  label: string;
  type: 'switch' | 'text';
}
interface IProps {
  onClose?(): void;
}

const BotSettingsForm: React.FC<IProps> = ({ onClose }) => {
  const formRef = useRef<FormHandles>(null);

  const { currentMarketplaceAccount, setCurrentMarketplaceAccount } =
    useMarketplaces();
  const { indexSelectedSettings, upsertBotSettings } = useSettings();

  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [formData, setFormData] = useState<IObject>({} as IObject);

  const availableSettings = useMemo<IAvailableSetting[]>(() => {
    if (currentMarketplaceAccount && currentMarketplaceAccount.marketplace) {
      return [
        {
          name: 'sync_offers',
          label: 'Sync offers',
          type: 'switch',
        },
        {
          name: 'sync_open_trades',
          label: 'Sync open trades',
          type: 'switch',
        },
        {
          name: 'sync_completed_trades',
          label: 'Sync completed trades',
          type: 'switch',
        },
        {
          name: 'sync_chats',
          label: 'Sync chats',
          type: 'switch',
        },
        {
          name: 'send_automatic_messages',
          label: 'Send automatic messages',
          type: 'switch',
        },
        {
          name: 'generate_reports',
          label: 'Generate statistics/reports',
          type: 'switch',
        },
      ];
    }
    return [
      {
        name: 'watson_key',
        label: 'Watson key',
        type: 'text',
      },
      {
        name: 'watson_url',
        label: 'Watson URL',
        type: 'text',
      },
      {
        name: 'sync_kimai_timesheets',
        label: 'Sync kimai timesheets',
        type: 'switch',
      },
      {
        name: 'send_automatic_payrolls',
        label: 'Trigger payrolls automatically',
        type: 'switch',
      },
      {
        name: `send_automatic_messages`,
        label: 'Send automatic messages',
        type: 'switch',
      },
    ];
  }, [currentMarketplaceAccount]);

  const inputs = useMemo(
    () =>
      availableSettings.map(setting => {
        if (setting.type === 'switch') {
          return (
            <Switch
              key={setting.name}
              name={setting.name}
              label={setting.label}
            />
          );
        }
        return (
          <Input
            key={setting.name}
            name={setting.name}
            label={setting.label}
            type="text"
          />
        );
      }),
    [availableSettings],
  );

  const getSettings = useCallback(async () => {
    const names = availableSettings.map(setting => setting.name);
    setLoadingForm(true);
    const data = await indexSelectedSettings<IObject>({
      data: {
        names,
        marketplace_account_id: currentMarketplaceAccount?.id,
      },
    });
    setFormData(data);
    setLoadingForm(false);
  }, [
    indexSelectedSettings,
    setLoadingForm,
    currentMarketplaceAccount,
    availableSettings,
  ]);

  const handleCancel = useCallback(() => {
    if (onClose) onClose();
    setCurrentMarketplaceAccount(null);
  }, [onClose, setCurrentMarketplaceAccount]);

  const handleUpsertSettings = useCallback(
    async (data: IObject) => {
      setLoadingUpsert(true);
      Object.assign(data, {
        marketplace_account_id: currentMarketplaceAccount?.id,
      });
      await upsertBotSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      getSettings();
      if (onClose) {
        onClose();
        setCurrentMarketplaceAccount(null);
      }
    },
    [
      upsertBotSettings,
      formRef,
      currentMarketplaceAccount,
      onClose,
      getSettings,
      setCurrentMarketplaceAccount,
    ],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Skeleton isLoaded={!loadingForm}>
      <Form
        ref={formRef}
        onSubmit={handleUpsertSettings}
        spacing="20px"
        initialData={formData}
      >
        {inputs.map(input => input)}
        <ButtonGroup>
          {onClose && (
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
          )}
          <Button
            type="button"
            onClick={handleSubmitForm}
            isLoading={loadingUpsert}
            isPrimary
          >
            Save
          </Button>
        </ButtonGroup>
      </Form>
    </Skeleton>
  );
};

export default BotSettingsForm;
