import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import MarketplaceAccountsContent from 'Components/Organisms/MarketplaceAccountsContent';

const MarketplaceAccounts: React.FC = () => {
  useEffect(() => {
    document.title = 'Marketplace Accounts - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <MarketplaceAccountsContent />
      </PageContainer>
    </WithDrawer>
  );
};

export default MarketplaceAccounts;
