import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import BotSettingsContent from 'Components/Organisms/BotSettingsContent';

const BotSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'Bot Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <BotSettingsContent />
      </PageContainer>
    </WithDrawer>
  );
};

export default BotSettings;
