import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import Background from 'Components/Atoms/Background';
import RequestRecoveryPasswordForm from 'Components/Organisms/RequestRecoveryPasswordForm';

const RequestRecoveryPassword: React.FC = () => {
  useEffect(() => {
    document.title = 'Request Recovery Password - Orion';
  }, []);

  return (
    <Flex h="100vh">
      <Background />
      <RequestRecoveryPasswordForm />
    </Flex>
  );
};

export default RequestRecoveryPassword;
