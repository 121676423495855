import React, { createContext, useCallback, useContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';

import IOffer from 'Types/Entities/IOffer';
import IOfferGroup from 'Types/Entities/IOfferGroup';
import ISetState from 'Types/Standards/ISetState';
import IPaginated from 'Types/Standards/IPaginated';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import IPaginationData from 'Types/Standards/IPaginationData';
import ICreateOfferGroupData from 'Types/DTOs/ICreateOfferGroupData';
import IUpdateOfferGroupData from 'Types/DTOs/IUpdateOfferGroupData';
import IUpdateOfferData from 'Types/DTOs/IUpdateOfferData';
import IDefaultDeleteData from 'Types/DTOs/IDefaultDeleteData';
import ICreateOfferData from 'Types/DTOs/ICreateOfferData';
import createOfferGroupSchema from 'Schemas/createOfferGroup';
import createOfferSchema from 'Schemas/createOffer';
import api from 'Services/api';
import IUpdateOfferGroupStatusData from 'Types/DTOs/IUpdateOfferGroupStatusData';
import IUpdateOfferGroupMarginsData from 'Types/DTOs/IUpdateOfferGroupMarginsData';
import ShapeObject from 'Helpers/shapeObject';
import updateOfferSchema from 'Schemas/updateOffer';
import IOfferSelectField from 'Types/DTOs/IOfferSelectField';
import { useErrors } from './errors';

type IPaginatedOffers = IPaginated<IOffer>;
type IPaginatedOfferGroups = IPaginated<IOfferGroup>;
type IIndexOfferGroups = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;
interface ICreateOfferGroup extends IDefaultRequest<ICreateOfferGroupData> {
  onClose(): void;
  setDefaultOffers: ISetState<string[]>;
}

interface IIndexOffers
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef'> {
  marketplace_id: string;
}
interface ICreateOffer extends IDefaultRequest<ICreateOfferData> {
  onClose(): void;
}
interface IDeleteOffer {
  data: {
    hash: string;
    account_id: string;
  };
}
interface IUpdateOfferStatusData {
  id: string;
  active: boolean;
}
interface IUpdateOfferStatus
  extends Omit<IDefaultRequest<IUpdateOfferStatusData>, 'formRef'> {
  setChecked: ISetState<boolean>;
}

interface IUpdateOfferGroup extends IDefaultRequest<IUpdateOfferGroupData> {
  onClose(): void;
  setDefaultOffers: ISetState<string[]>;
}
interface IUpdateOffer extends IDefaultRequest<IUpdateOfferData> {
  onClose(): void;
}
type IDeleteOfferGroup = Omit<IDefaultRequest<IDefaultDeleteData>, 'formRef'>;
interface IUpdateOfferGroupStatus
  extends Omit<IDefaultRequest<IUpdateOfferGroupStatusData>, 'formRef'> {
  setChecked: ISetState<boolean>;
}
type IUpdateOfferGroupMargins = Omit<
  IDefaultRequest<IUpdateOfferGroupMarginsData>,
  'formRef'
>;

interface IOffersContext {
  offers: IPaginatedOffers;
  allOffers: IOffer[];
  offerGroups: IPaginatedOfferGroups;
  currentOfferGroup: IOfferGroup | null;
  setCurrentOfferGroup: ISetState<IOfferGroup | null>;
  currentOffer: IOffer | null;
  setCurrentOffer: ISetState<IOffer | null>;
  setCurrentOfferAccountId: ISetState<string | null>;
  indexOffers(props: IIndexOffers): Promise<IPaginatedOffers | null>;
  createOffer(props: ICreateOffer): Promise<void>;
  updateOffer(props: IUpdateOffer): Promise<void>;
  updateOfferStatus(props: IUpdateOfferStatus): Promise<void>;
  deleteOffer(props: IDeleteOffer): Promise<void>;
  indexAllOffers(): Promise<void>;
  indexOfferGroups(props: IIndexOfferGroups): Promise<void>;
  createOfferGroup(props: ICreateOfferGroup): Promise<void>;
  updateOfferGroup(props: IUpdateOfferGroup): Promise<void>;
  deleteOfferGroup(props: IDeleteOfferGroup): Promise<void>;
  updateOfferGroupStatus(props: IUpdateOfferGroupStatus): Promise<void>;
  updateOfferGroupMargins(props: IUpdateOfferGroupMargins): Promise<void>;
  indexTags(): Promise<IOfferSelectField[]>;
  indexFiatCurrencies(): Promise<IOfferSelectField[]>;
  indexPaymentMethods(): Promise<IOfferSelectField[]>;
}

const OffersContext = createContext<IOffersContext>({} as IOffersContext);

export const OffersProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const { handleErrors } = useErrors();

  const [offers, setOffers] = useState({} as IPaginatedOffers);
  const [allOffers, setAllOffers] = useState<IOffer[]>([]);
  const [offerGroups, setOfferGroups] = useState({} as IPaginatedOfferGroups);
  const [currentOfferGroup, setCurrentOfferGroup] =
    useState<IOfferGroup | null>(null);
  const [currentOffer, setCurrentOffer] = useState<IOffer | null>(null);
  const [currentOfferAccountId, setCurrentOfferAccountId] = useState<
    string | null
  >(null);

  const indexOffers = useCallback(
    async ({
      data: { page },
      marketplace_id,
    }: IIndexOffers): Promise<IPaginatedOffers | null> => {
      try {
        const response = await api.get('/offers', {
          params: {
            page,
            limit: 25,
            marketplace_id,
          },
        });
        const entities = response.data.offers;
        const { total } = response.data;
        setOffers({
          total,
          entities,
        });
        return {
          entities,
          total,
        };
      } catch (err) {
        handleErrors('Error when trying to index offers', err);
        return null;
      }
    },
    [handleErrors],
  );

  const createOffer = useCallback(
    async ({ data, formRef, onClose }: ICreateOffer): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        // @ts-ignore
        data.predefined_amounts = data.predefined_amounts
          ? data.predefined_amounts.split(/,/g).map(x => x.replace(/^\D+/g, ''))
          : [];
        await createOfferSchema.validate(ShapeObject(data), {
          abortEarly: false,
        });
        await api.post('/offers', ShapeObject(data));
        onClose();
      } catch (err) {
        handleErrors('Error when trying to create offer', err, formRef);
      }
    },
    [handleErrors],
  );

  const deleteOffer = useCallback(
    async ({ data }: IDeleteOffer): Promise<void> => {
      try {
        await api.delete(`offers/${data.hash}`, {
          params: {
            account_id: data.account_id,
          },
        });
      } catch (err) {
        handleErrors('Error when trying to delete offer', err);
      }
    },
    [handleErrors],
  );

  const updateOffer = useCallback(
    async ({
      data: { hash, ...rest },
      formRef,
      onClose,
    }: IUpdateOffer): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        // @ts-ignore
        rest.predefined_amounts = rest.predefined_amounts
          ? rest.predefined_amounts.split(/,/g).map(x => x.replace(/^\D+/g, ''))
          : [];
        await updateOfferSchema.validate(ShapeObject(rest), {
          abortEarly: false,
        });
        await api.put(`offers/${hash}`, ShapeObject(rest));
        onClose();
      } catch (err) {
        handleErrors('Error when trying to update offer', err, formRef);
      }
    },
    [handleErrors],
  );

  const updateOfferStatus = useCallback(
    async ({ data: { id, ...rest }, setChecked }: IUpdateOfferStatus) => {
      try {
        await api.patch(`/offers/status/${id}`, rest);
        setChecked(rest.active);
      } catch (err) {
        handleErrors('Error when trying to update offer status', err);
      }
    },
    [handleErrors],
  );

  const indexOfferGroups = useCallback(
    async ({ data: { page } }: IIndexOfferGroups): Promise<void> => {
      try {
        const response = await api.get('/offers/groups', {
          params: {
            page,
            limit: 25,
          },
        });
        setOfferGroups({
          entities: response.data.offer_groups || [],
          total: response.data.total || 0,
        });
      } catch (err) {
        handleErrors('Error when trying to index offer groups', err);
      }
    },
    [handleErrors],
  );

  const createOfferGroup = useCallback(
    async ({
      data,
      formRef,
      onClose,
      setDefaultOffers,
    }: ICreateOfferGroup): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        await createOfferGroupSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('offers/groups', data);
        setDefaultOffers([]);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to create offer group', err, formRef);
      }
    },
    [handleErrors],
  );

  const updateOfferGroup = useCallback(
    async ({
      data: { id, ...rest },
      formRef,
      onClose,
      setDefaultOffers,
    }: IUpdateOfferGroup): Promise<void> => {
      try {
        formRef.current?.setErrors({});
        await createOfferGroupSchema.validate(rest, {
          abortEarly: false,
        });
        await api.put(`offers/groups/${id}`, rest);
        setDefaultOffers([]);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to update offer group', err, formRef);
      }
    },
    [handleErrors],
  );

  const deleteOfferGroup = useCallback(
    async ({ data: { id } }: IDeleteOfferGroup): Promise<void> => {
      try {
        await api.delete(`offers/groups/${id}`);
      } catch (err) {
        handleErrors('Error when trying to delete offer group', err);
      }
    },
    [handleErrors],
  );

  const updateOfferGroupStatus = useCallback(
    async ({ data: { id, ...rest }, setChecked }: IUpdateOfferGroupStatus) => {
      try {
        await api.patch(`/offers/groups/status/${id}`, rest);
        setChecked(rest.active);
      } catch (err) {
        handleErrors('Error when trying to update offer group status', err);
      }
    },
    [handleErrors],
  );

  const indexAllOffers = useCallback(async () => {
    let page = 1;
    let shouldEnd = false;
    const limit = 25;
    const newOffers = [] as IOffer[];

    while (!shouldEnd) {
      try {
        const response = await api.get('/offers', {
          params: {
            page,
            limit,
          },
        });
        if (!response.data.offers) {
          shouldEnd = true;
        } else {
          newOffers.push(...response.data.offers);
        }
        if (response.data.offers.length < limit) {
          shouldEnd = true;
        }
        page++;
      } catch (err) {
        handleErrors('Error when trying to index all offers', err);
        shouldEnd = true;
      }
    }
    setAllOffers(newOffers);
  }, [handleErrors]);

  const updateOfferGroupMargins = useCallback(
    async ({ data: { id, ...rest } }: IUpdateOfferGroupMargins) => {
      try {
        const response = await api.patch(`/offers/groups/margins/${id}`, rest);
        const { fetched, updated } = response.data;
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top-right',
          status: 'success',
          title: `${updated} of ${fetched} offers were updated successfully`,
        });
      } catch (err) {
        handleErrors('Error when trying to update offer group margins', err);
      }
    },
    [handleErrors, toast],
  );

  const indexTags = useCallback(async (): Promise<IOfferSelectField[]> => {
    try {
      if (!currentOfferAccountId) {
        return [];
      }
      const response = await api.get('/offers/fields/tags', {
        params: {
          marketplace_account_id: currentOfferAccountId,
        },
      });
      return response.data;
    } catch (err) {
      handleErrors('Error when trying to get offer tags', err);
      return [];
    }
  }, [currentOfferAccountId, handleErrors]);

  const indexFiatCurrencies = useCallback(async (): Promise<
    IOfferSelectField[]
  > => {
    try {
      if (!currentOfferAccountId) {
        return [];
      }
      const response = await api.get('/offers/fields/fiat-currencies', {
        params: {
          marketplace_account_id: currentOfferAccountId,
        },
      });
      return response.data;
    } catch (err) {
      handleErrors('Error when trying to get offer fiat currencies', err);
      return [];
    }
  }, [currentOfferAccountId, handleErrors]);

  const indexPaymentMethods = useCallback(async (): Promise<
    IOfferSelectField[]
  > => {
    try {
      if (!currentOfferAccountId) {
        return [];
      }
      const response = await api.get('/offers/fields/payment-methods', {
        params: {
          marketplace_account_id: currentOfferAccountId,
        },
      });
      return response.data;
    } catch (err) {
      handleErrors('Error when trying to get offer payment methods', err);
      return [];
    }
  }, [currentOfferAccountId, handleErrors]);

  return (
    <OffersContext.Provider
      value={{
        offers,
        currentOffer,
        setCurrentOffer,
        indexOffers,
        createOffer,
        updateOffer,
        deleteOffer,
        updateOfferStatus,
        createOfferGroup,
        currentOfferGroup,
        deleteOfferGroup,
        indexOfferGroups,
        offerGroups,
        setCurrentOfferGroup,
        updateOfferGroup,
        updateOfferGroupStatus,
        allOffers,
        indexAllOffers,
        updateOfferGroupMargins,
        indexTags,
        indexFiatCurrencies,
        indexPaymentMethods,
        setCurrentOfferAccountId,
      }}
    >
      {children}
    </OffersContext.Provider>
  );
};

export const useOffers = (): IOffersContext => {
  const context = useContext(OffersContext);
  if (!context) {
    throw new Error('useOffers must be used within OffersProvider');
  }
  return context;
};

export default OffersProvider;
