import React, { useMemo } from 'react';
import { FaCalendar } from 'react-icons/fa';

import DashboardChart from 'Components/Atoms/DashboardChart';
import { useMarketplaces } from 'Hooks/marketplaces';
import Card from 'Components/Atoms/Card';
import Tabs, { ITab } from '../../Molecules/Tabs';

interface IProps {
  marketplaceId: string;
  vendorId?: string;
}

const DashboardMonthlyOverview: React.FC<IProps> = ({
  marketplaceId,
  vendorId,
}) => {
  const { allMarketplaceAccounts } = useMarketplaces();

  const tabs = useMemo<ITab[]>(
    () => [
      {
        title: 'general',
        component: (
          <DashboardChart marketplaceId={marketplaceId} vendorId={vendorId} />
        ),
      },
      ...allMarketplaceAccounts.map<ITab>(account => ({
        title: account.username,
        component: (
          <DashboardChart
            marketplaceId={marketplaceId}
            accountId={account.id}
            vendorId={vendorId}
          />
        ),
      })),
    ],
    [allMarketplaceAccounts, marketplaceId, vendorId],
  );

  return (
    <Card title="Monthly Overview" icon={FaCalendar}>
      <Tabs tabs={tabs} />
    </Card>
  );
};

export default DashboardMonthlyOverview;
