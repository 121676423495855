export default function download(
  filename: string,
  content: string,
  mimetype: string,
): void {
  const element = document.createElement('a');
  element.setAttribute(
    'href',
    `data:${mimetype};charset=utf-8, ${encodeURIComponent(content)}`,
  );
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
}
