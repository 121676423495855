import React, { useCallback, useEffect, useState } from 'react';
import { Switch } from '@chakra-ui/react';

import { useColor } from 'Hooks/color';
import ISetState from 'Types/Standards/ISetState';

export interface IOnChange {
  value: boolean;
  setLoading: ISetState<boolean>;
  setChecked: ISetState<boolean>;
}

interface IProps {
  defaultValue: boolean;
  onChange(data: IOnChange): Promise<void> | void;
}

const ControlledSwitch: React.FC<IProps> = ({ onChange, defaultValue }) => {
  const color = useColor();

  const [loading, setLoading] = useState(false);
  const [checked, setChecked] = useState(false);

  const handleChange = useCallback(
    async (value: boolean) => {
      await onChange({
        value,
        setLoading,
        setChecked,
      });
    },
    [onChange],
  );

  useEffect(() => {
    setChecked(!!defaultValue);
  }, [defaultValue]);

  return (
    <Switch
      size="md"
      colorScheme={color}
      isChecked={checked}
      isDisabled={loading}
      onChange={e => handleChange(e.target.checked)}
    />
  );
};

export default ControlledSwitch;
