import React, { useMemo } from 'react';
import {
  Box,
  Button as BaseButton,
  ButtonProps,
  Placement,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Portal,
} from '@chakra-ui/react';

import { useColor } from 'Hooks/color';

interface IProps extends ButtonProps {
  isPrimary?: boolean;
  isDanger?: boolean;
  colorScheme?: string;
  confirmation?: {
    body: string;
    buttonText: string;
    colorScheme: string;
    placement?: Placement;
    isLoading?: boolean;
  };
}

const Button: React.FC<IProps> = ({
  isPrimary,
  isDanger,
  colorScheme,
  confirmation,
  onClick,
  ...rest
}) => {
  const defaultColor = useColor();

  const color = useMemo(() => {
    if (isPrimary) return defaultColor;
    if (colorScheme) return colorScheme;
    if (isDanger) return 'red';
    return 'white';
  }, [colorScheme, isPrimary, isDanger, defaultColor]);

  return (
    <Popover
      placement={confirmation?.placement ? confirmation.placement : 'bottom'}
    >
      <PopoverTrigger>
        <BaseButton
          variant="solid"
          colorScheme={color}
          loadingText="Loading"
          onClick={confirmation ? () => {} : onClick}
          {...rest}
        />
      </PopoverTrigger>
      {confirmation && (
        <Portal>
          <PopoverContent>
            <PopoverArrow />
            <PopoverCloseButton />
            <PopoverBody>
              <Box mb="5px">{confirmation.body}</Box>
            </PopoverBody>
            <PopoverFooter display="flex" justifyContent="flex-end">
              <BaseButton
                onClick={onClick}
                colorScheme={confirmation.colorScheme}
                isLoading={confirmation.isLoading}
              >
                {confirmation.buttonText}
              </BaseButton>
            </PopoverFooter>
          </PopoverContent>
        </Portal>
      )}
    </Popover>
  );
};

export default Button;
