import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Flex, Icon, useColorModeValue, Text } from '@chakra-ui/react';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import ChatMessage from 'Components/Molecules/ChatMessage';
import { FiSend } from 'react-icons/fi';
import IChat from 'Types/Entities/IChat';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import { useChat } from 'Hooks/chats';
import { usePredefinedMessages } from 'Hooks/predefinedMessages';
import { FormHandles } from '@unform/core';
import { useParams } from 'react-router-dom';

interface IProps {
  loading: boolean;
  chat: IChat;
  allMarketplaceAccounts: IMarketplaceAccount[];
}

interface IMessage {
  content: string;
}

interface IParams {
  hash: string;
}

const TradeChat: React.FC<IProps> = ({ chat, allMarketplaceAccounts }) => {
  const backgroundColor = useColorModeValue('gray.50', 'gray.800');
  const borderColor = useColorModeValue('gray.100', 'gray.400');
  const autocompleteColor = useColorModeValue('gray.300', 'gray.600');

  const { sendMessage, getChat } = useChat();
  const { getPredefinedMessages, predefinedMessages } = usePredefinedMessages();
  const { hash } = useParams<IParams>();

  const chatEnd = useRef<HTMLDivElement>(null);
  const messageRef = useRef<FormHandles>(null);

  const [inputMessageValue, setInputMessageValue] = useState('');
  const [messageSuggestions, setMessageSuggestions] = useState(['']);
  const [sendingMessage, setSendingMessage] = useState(false);

  const scrollDown = useCallback(() => {
    if (chatEnd && chatEnd.current !== null) {
      const target = chatEnd.current;
      target.scroll({
        behavior: 'smooth',
        top: target.scrollHeight,
      });
    }
  }, []);

  const handleSendMessage = useCallback(
    async (data: IMessage) => {
      setSendingMessage(true);
      setInputMessageValue('');
      await sendMessage({
        hash,
        message: data.content,
      }).then(() =>
        getChat({
          hash,
        }).finally(() => setSendingMessage(false)),
      );

      scrollDown();

      messageRef.current?.getFieldRef('content').focus();
    },
    [getChat, hash, scrollDown, sendMessage],
  );

  const lastMessageId = useMemo(() => {
    if (chat?.messages?.length > 0) {
      return chat?.messages[chat.messages.length - 1].id;
    }

    return undefined;
  }, [chat.messages]);

  useEffect(() => {
    if (chatEnd && chatEnd.current !== null) {
      const target = chatEnd.current;

      if (target.scrollHeight - target.clientHeight < target.scrollTop + 200) {
        target.scroll({
          behavior: 'smooth',
          top: target.scrollHeight,
        });
      }
    }
  }, [lastMessageId]);

  useEffect(() => {
    scrollDown();
  }, [scrollDown]);

  useEffect(() => {
    getPredefinedMessages();
  }, [getPredefinedMessages]);

  const handleSuggestionMessage = useCallback((suggestion: string) => {
    setInputMessageValue(suggestion);

    messageRef.current?.getFieldRef('content').focus();
  }, []);

  useEffect(() => {
    if (predefinedMessages.predefined_messages) {
      const suggestions = predefinedMessages.predefined_messages.map(
        predefinedMessage => {
          if (
            predefinedMessage.message
              .toLocaleLowerCase()
              .includes(inputMessageValue.toLocaleLowerCase())
          ) {
            return predefinedMessage.message;
          }

          return '';
        },
      );
      setMessageSuggestions(
        suggestions.filter(suggestion => suggestion.length > 1),
      );
    }
  }, [inputMessageValue, predefinedMessages.predefined_messages]);

  return (
    <Box w="100%" minW="500px" mr={5} pr={5}>
      <Box
        w="100%"
        display="flex"
        flexDir="column"
        bg={backgroundColor}
        p={3}
        overflowY="scroll"
        minHeight="400px"
        maxHeight="600px"
        borderTopRadius="5px"
        ref={chatEnd}
      >
        {chat.messages &&
          chat?.messages.map(message => (
            <ChatMessage
              author={message.author}
              type={message.type}
              marketplaceAccounts={allMarketplaceAccounts}
              time={message.timestamp}
            >
              {message.text}
            </ChatMessage>
          ))}
      </Box>
      <Box
        w="100%"
        display="flex"
        flexDir="column"
        borderTop="2px"
        borderColor={borderColor}
        bg={backgroundColor}
        borderBottomRadius="5px"
        position="relative"
      >
        {inputMessageValue.length > 1
          ? messageSuggestions.length > 0 && (
              <Box
                bg={autocompleteColor}
                borderBottomColor="gray.000"
                borderBottom="2px"
                borderTopRadius="4px"
                position="absolute"
                bottom="100%"
                maxH="150px"
                overflowY="auto"
                w={chatEnd.current?.clientWidth}
              >
                {messageSuggestions.map(messageSuggestion => (
                  <Text
                    p={2}
                    _hover={{ background: 'gray.500' }}
                    cursor="pointer"
                    onClick={() => handleSuggestionMessage(messageSuggestion)}
                  >
                    {messageSuggestion}
                  </Text>
                ))}
              </Box>
            )
          : ''}

        <Form onSubmit={handleSendMessage} ref={messageRef}>
          <Flex p={3}>
            <Input
              placeholder="Insert message here"
              type="text"
              name="content"
              value={inputMessageValue}
              onChange={e => setInputMessageValue(e.target.value)}
              mr={3}
            />
            <Button
              isPrimary
              isLoading={sendingMessage}
              onClick={() => messageRef.current?.submitForm()}
            >
              <Icon as={FiSend} />
            </Button>
          </Flex>
        </Form>
      </Box>
    </Box>
  );
};

export default TradeChat;
