import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import ActiveTradesPage from 'Components/Organisms/ActiveTrades';

const ActiveTrades: React.FC = () => {
  useEffect(() => {
    document.title = 'Active Trades - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <ActiveTradesPage />
      </PageContainer>
    </WithDrawer>
  );
};

export default ActiveTrades;
