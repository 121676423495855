import React, { useMemo } from 'react';
import { Icon } from '@chakra-ui/react';
import { FiCheckCircle, FiXCircle } from 'react-icons/fi';

import { useColor } from 'Hooks/color';

interface IProps {
  isActive: boolean;
}

const ActiveIcon: React.FC<IProps> = ({ isActive }) => {
  const color = useColor();

  const iconComponent = useMemo(
    () => (isActive ? FiCheckCircle : FiXCircle),
    [isActive],
  );
  const iconColor = useMemo(
    () => (isActive ? `${color}.300` : 'red.300'),
    [isActive, color],
  );

  return <Icon as={iconComponent} color={iconColor} size={25} />;
};

export default ActiveIcon;
