import React, { useMemo } from 'react';
import { useColorModeValue, useTheme } from '@chakra-ui/react';
import Chart from 'react-apexcharts';

import { useColor } from 'Hooks/color';
import { useReports } from 'Hooks/reports';

interface IProps {
  accountId?: string;
  marketplaceId: string;
  vendorId?: string;
}

const DashboardChart: React.FC<IProps> = ({
  accountId,
  marketplaceId,
  vendorId,
}) => {
  const color = useColor();
  const theme = useTheme();
  const { getReport } = useReports();

  const primaryAccent = useColorModeValue('500', '300');
  const secondaryAccent = useColorModeValue('300', '100');

  const primaryColor = useMemo(
    () => theme.colors[color][primaryAccent],
    [theme, color, primaryAccent],
  );
  const secondaryColor = useMemo(
    () => theme.colors[color][secondaryAccent],
    [theme, color, secondaryAccent],
  );
  const foreColor = useColorModeValue(theme.colors.black, theme.colors.white);
  const labelColor = useColorModeValue(theme.colors.black, theme.colors.black);

  const monthlyDays = useMemo(
    () =>
      getReport({
        name: 'monthly_days',
        marketplace_account_id: accountId,
        marketplace_id: marketplaceId,
        vendor_id: vendorId,
      }),
    [getReport, accountId, marketplaceId, vendorId],
  );
  const categories = useMemo(
    () => (monthlyDays ? monthlyDays[0] : []),
    [monthlyDays],
  );
  const seriesVolume = useMemo(
    () => (monthlyDays ? monthlyDays[1] : []),
    [monthlyDays],
  );
  const seriesProfit = useMemo(
    () => (monthlyDays ? monthlyDays[2] : []),
    [monthlyDays],
  );

  return (
    <Chart
      options={{
        chart: {
          id: 'basic-bar',
          foreColor,
        },
        xaxis: {
          categories,
          type: 'datetime',
        },
        stroke: {
          curve: 'smooth',
        },
        dataLabels: {
          enabled: false,
        },
        colors: [primaryColor, secondaryColor],
      }}
      series={[
        {
          name: 'Volume (USD)',
          data: seriesVolume,
        },
        {
          name: 'Profit (USD)',
          data: seriesProfit,
        },
      ]}
      type="area"
      height={300}
      style={{
        color: labelColor,
      }}
    />
  );
};

export default DashboardChart;
