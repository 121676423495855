import React, { useMemo } from 'react';
import {
  BoxProps,
  Text,
  AlertDescription,
  Box,
  Icon,
  Flex,
  useColorModeValue,
} from '@chakra-ui/react';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import { FiClock, FiInfo } from 'react-icons/fi';
import moment from 'moment';

import { useColor } from 'Hooks/color';

interface IProps extends BoxProps {
  type: string;
  author: string;
  marketplaceAccounts: IMarketplaceAccount[];
  time: number;
  children: string;
}

const ChatMessage: React.FC<IProps> = ({
  children,
  type,
  author,
  marketplaceAccounts,
  time,
}) => {
  const defaultColor = useColor();

  const isAuthor = useMemo(() => {
    const usernames = marketplaceAccounts.map(
      marketplaceAccount => marketplaceAccount.username,
    );

    return usernames.includes(author);
  }, [author, marketplaceAccounts]);

  const alignment = useMemo(() => {
    if (type !== 'msg') return 'center';

    return isAuthor ? 'flex-end' : 'flex-start';
  }, [isAuthor, type]);

  const variant = useMemo(() => {
    if (type !== 'msg') return 'teal';

    return isAuthor ? `${defaultColor}` : 'purple';
  }, [defaultColor, isAuthor, type]);

  const color = useMemo(() => {
    if (type !== 'msg') return 'teal';

    return isAuthor ? `${defaultColor}` : 'purple';
  }, [defaultColor, isAuthor, type]);

  const colorValue = useColorModeValue(`${color}.100`, `${color}.600`);
  const variantValue = useColorModeValue(`${variant}.200`, `${variant}.800`);

  if (type === 'trade_attach_uploaded') {
    return (
      <Box
        maxWidth="80%"
        p={3}
        mb={5}
        border="1px"
        bg={colorValue}
        borderColor={variantValue}
        alignSelf={alignment}
        justifyContent="center"
        alignItems="center"
        borderRadius="5px"
      >
        <Box display="flex" flexDir="column" w="100%">
          <Flex alignSelf="center" alignItems="center">
            <Icon as={FiInfo} mr={1} />
            <Text m={0}>{author} has attached an image</Text>
          </Flex>
          <Text
            fontSize="sm"
            alignSelf="flex-end"
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Icon as={FiClock} mr={1} />
            {moment(time * 1000).format('HH:mm:ss')}
          </Text>
        </Box>
      </Box>
    );
  }

  if (type === 'system:moderator_available_in_chat') {
    return (
      <Box
        maxWidth="80%"
        p={3}
        mb={5}
        border="1px"
        bg={colorValue}
        borderColor={variantValue}
        alignSelf={alignment}
        justifyContent="center"
        alignItems="center"
        borderRadius="5px"
      >
        <Box display="flex" flexDir="column" w="100%">
          <Flex alignSelf="center" alignItems="center">
            <Icon as={FiInfo} mr={1} />
            <Text m={0}>Moderator is available in chat</Text>
          </Flex>
          <Text
            fontSize="sm"
            alignSelf="flex-end"
            display="flex"
            alignItems="center"
            mt={2}
          >
            <Icon as={FiClock} mr={1} />
            {moment(time * 1000).format('HH:mm:ss')}
          </Text>
        </Box>
      </Box>
    );
  }

  return (
    <Box
      bg={colorValue}
      w="fit-content"
      border="1px"
      borderColor={variantValue}
      maxWidth="80%"
      height="fit-content"
      p={3}
      mb={3}
      alignSelf={alignment}
      borderRadius="5px"
    >
      <Box display="flex" flexDir="column">
        <AlertDescription dangerouslySetInnerHTML={{ __html: children }} />
        <Text
          fontSize="sm"
          alignSelf="flex-end"
          display="flex"
          alignItems="center"
          mt={2}
        >
          <Icon as={FiClock} mr={1} />
          {moment(time * 1000).format('HH:mm:ss')}
        </Text>
      </Box>
    </Box>
  );
};

export default ChatMessage;
