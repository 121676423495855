import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Skeleton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import ISetState from 'Types/Standards/ISetState';
import {
  IPaginatedMarketplaceAccounts,
  useMarketplaces,
} from 'Hooks/marketplaces';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import IMarketplace from 'Types/Entities/IMarketplace';
import { useOffers } from 'Hooks/offers';
import IOffer from 'Types/Entities/IOffer';
import IPaginated from 'Types/Standards/IPaginated';
import ICreateOfferData from 'Types/DTOs/ICreateOfferData';
import Switch from 'Components/Atoms/Switch';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
  setOffers: ISetState<IPaginated<IOffer>>;
  marketplace: IMarketplace;
}

const OffersForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
  marketplace,
  setOffers,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingAccounts, setLoadingAccounts] = useState(false);
  const [marketplaceAccounts, setMarketplaceAccounts] =
    useState<IPaginatedMarketplaceAccounts>(
      {} as IPaginatedMarketplaceAccounts,
    );
  const [tagOptions, setTagOptions] = useState<ISelectOption[]>([]);
  const [paymentMethodOptions, setPaymentMethodOptions] = useState<
    ISelectOption[]
  >([]);
  const [fiatCurrencyOptions, setFiatCurrencyOptions] = useState<
    ISelectOption[]
  >([]);

  const { indexMarketplaces, indexMarketplaceAccounts } = useMarketplaces();

  const {
    createOffer,
    currentOffer,
    setCurrentOffer,
    indexOffers,
    updateOffer,
    indexTags,
    indexPaymentMethods,
    indexFiatCurrencies,
    setCurrentOfferAccountId,
  } = useOffers();

  // const countryOptions = useMemo<ISelectOption[]>(() => [], []);

  const isEdit = useMemo(() => !!currentOffer, [currentOffer]);

  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} offer`,
    [isEdit],
  );

  const methodsOptions = useMemo<ISelectOption[]>(
    () => [
      {
        label: 'Buy',
        value: 'buy',
      },
      {
        label: 'Sell',
        value: 'sell',
      },
    ],
    [],
  );

  const marketplaceAccountsOptions = useMemo<ISelectOption[]>(
    () =>
      marketplaceAccounts &&
      marketplaceAccounts.entities &&
      marketplaceAccounts.entities.map(marketplaceAccount => ({
        label: marketplaceAccount?.username,
        value: marketplaceAccount?.id,
      })),
    [marketplaceAccounts],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentOffer(null);
  }, [onClose, setCurrentOffer]);

  const handleIndexOffers = useCallback(async () => {
    setLoadingTable(true);
    const newOffers = await indexOffers({
      data: {
        page: 1,
      },
      marketplace_id: marketplace.id,
    });
    if (newOffers) {
      setOffers(newOffers);
    }
    setLoadingTable(false);
  }, [setLoadingTable, indexOffers, marketplace.id, setOffers]);

  const handleIndexAccounts = useCallback(async () => {
    setLoadingAccounts(true);
    const newAccounts = await indexMarketplaceAccounts({
      data: {
        page: 1,
        marketplace_id: marketplace.id,
      },
    });
    if (newAccounts) {
      setMarketplaceAccounts(newAccounts);
    }
    setLoadingAccounts(false);
  }, [setLoadingAccounts, indexMarketplaceAccounts, marketplace.id]);

  const submitForm = useCallback(
    async (data: ICreateOfferData) => {
      setLoadingForm(true);
      if (isEdit && currentOffer) {
        await updateOffer({
          data: {
            ...data,
            hash: currentOffer.hash,
            account_id: currentOffer.marketplace_account_id,
          },
          formRef,
          onClose,
        });
      } else {
        await createOffer({
          data,
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);

      setLoadingTable(true);
      await indexMarketplaces();
      setLoadingTable(false);
    },
    [
      isEdit,
      currentOffer,
      setLoadingTable,
      indexMarketplaces,
      updateOffer,
      onClose,
      createOffer,
    ],
  );

  useEffect(() => {
    handleIndexOffers();
  }, [handleIndexOffers]);

  useEffect(() => {
    handleIndexAccounts();
  }, [handleIndexAccounts]);

  useEffect(() => {
    indexTags().then(tags =>
      setTagOptions(
        tags.map(tag => ({
          label: tag.label,
          value: tag.reference,
        })),
      ),
    );
  }, [indexTags]);

  useEffect(() => {
    indexFiatCurrencies().then(currencies =>
      setFiatCurrencyOptions(
        currencies.map(currency => ({
          label: currency.label,
          value: currency.reference,
        })),
      ),
    );
  }, [indexFiatCurrencies]);

  useEffect(() => {
    indexPaymentMethods().then(methods =>
      setPaymentMethodOptions(
        methods.map(method => ({
          label: method.label,
          value: method.reference,
        })),
      ),
    );
  }, [indexPaymentMethods]);

  return (
    <>
      <Button type="button" onClick={onOpen} isPrimary mb={6}>
        Create offer
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody pb={5}>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentOffer || {}}
            >
              {!isEdit && (
                <>
                  <Skeleton isLoaded={!loadingAccounts}>
                    <Select
                      name="account_id"
                      label="Account"
                      options={marketplaceAccountsOptions}
                      onChange={v => {
                        // @ts-ignore
                        setCurrentOfferAccountId(v ? v.value : null);
                      }}
                    />
                  </Skeleton>
                  <Select
                    name="type"
                    label="Type"
                    options={methodsOptions}
                    defaultValue={methodsOptions[0]?.value}
                  />
                </>
              )}
              <Input
                placeholder="Terms"
                label="Terms"
                name="terms"
                type="text"
              />
              <Input
                placeholder="Trade details"
                label="Trade details"
                name="details"
                type="text"
              />
              <Select
                name="tags"
                label="Pick up to 3 tags"
                options={tagOptions}
                isMulti
              />
              <Select
                name="fiat_currency_code"
                label="Fiat currency code"
                options={fiatCurrencyOptions}
              />
              <Input
                placeholder="Margin"
                label="Margin"
                name="margin"
                type="number"
              />
              <Input
                placeholder="0.00"
                label="Min fiat amount"
                name="fiat_amount_range_min"
                type="number"
              />
              <Input
                placeholder="0.00"
                label="Max fiat amount"
                name="fiat_amount_range_max"
                type="number"
              />
              <Input
                placeholder="10,20,30"
                label="Comma separeted predefined amounts"
                name="predefined_amounts"
                type="text"
              />
              <Input
                placeholder="X minutes"
                label="Payment window"
                name="payment_window"
                type="number"
              />
              <Select
                name="payment_method_slug"
                label="Payment method"
                options={paymentMethodOptions}
              />
              <Input
                placeholder="X trades"
                label="Require minimun past trades"
                name="require_min_past_trades"
                type="number"
              />
              <Switch
                name="is_only_for_trusted_users"
                label="Show only to trusted users"
              />
              <Input
                placeholder="0.00"
                label="Fixed price"
                name="fixed_price"
                type="number"
              />
              <Switch name="is_fixed_price" label="Use fixed price" />
              {/* <MultiSelect
                placeholder="Select countries..."
                label="Limitated countries"
                name="limitated_countries"
                options={countryOptions}
              />
              <Switch
                name="is_country_limitated"
                label="Limitate countries above"
              /> */}
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default OffersForm;
