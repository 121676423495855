import React, { useEffect, useState } from 'react';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import { usePayrolls } from 'Hooks/payrolls';
import formatDate from 'Helpers/formatDate';

const PayrollHistoryTable: React.FC = () => {
  const { payrolls, indexPayrolls } = usePayrolls();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    indexPayrolls({
      data: {
        page,
      },
    }).finally(() => {
      setLoading(false);
    });
  }, [page, indexPayrolls]);

  return (
    <Card title="Payroll history">
      <Table
        isLoading={loading}
        columns={[
          {
            key: 'username',
            title: 'username',
            render: row => row.user?.username,
          },
          {
            key: 'date',
            title: 'date',
            render: row => formatDate(row.created_at),
          },
          {
            key: 'amount_usd',
            title: 'usd amount',
            render: row => Number(row.amount).toLocaleString(),
          },
          {
            key: 'amount_crypto',
            title: 'crypto amount',
            dataIndex: 'amount_crypto',
          },
          {
            key: 'crypto',
            title: 'crypto',
            dataIndex: 'crypto',
          },
          {
            key: 'hours',
            title: 'hours',
            render: row => Number(row.hours).toLocaleString(),
          },
        ]}
        rows={payrolls.entities}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: payrolls.total,
        }}
      />
    </Card>
  );
};

export default PayrollHistoryTable;
