import React, { useMemo } from 'react';
import {
  Box,
  Flex,
  HStack,
  IconButton,
  Image,
  useColorModeValue,
} from '@chakra-ui/react';
import {
  FiMenu,
  FiDollarSign,
  FiZap,
  FiShoppingCart,
  FiBookOpen,
  FiUsers,
  FiCreditCard,
  FiSettings,
  FiBarChart,
  FiGrid,
  FiBell,
  FiCode,
  FiUserCheck,
} from 'react-icons/fi';
import { MdLocalOffer } from 'react-icons/md';

import { useDrawer } from 'Hooks/drawer';
import lightLogo from 'Assets/Images/light-logo.svg';
import darkLogo from 'Assets/Images/dark-logo.svg';
import userHasRole from 'Helpers/userHasRole';
import localStorageConfig from 'Config/localStorage';
import { User } from '@spiry-capital/modules';
import IRoleLevels from 'Types/Enums/IRoleLevels';
import NavItem, { INavItemProps } from '../../Atoms/NavItem';

const menu: INavItemProps[] = [
  {
    icon: FiCode,
    title: 'Developer',
    roleLevel: IRoleLevels.developer,
    subItems: [
      {
        title: 'Jobs',
        link: '/jobs',
      },
    ],
  },
  {
    icon: FiBarChart,
    title: 'Dashboard',
    roleLevel: IRoleLevels.employee,
    subItems: [
      {
        title: 'Paxful',
        link: '/dashboard/paxful',
      },
      {
        title: 'Koinzaar',
        link: '/dashboard/koinzaar',
      },
    ],
  },
  {
    icon: FiGrid,
    title: 'Glasswire',
    roleLevel: IRoleLevels.admin,
    subItems: [
      {
        title: 'Paxful',
        link: '/glasswire/paxful',
      },
      {
        title: 'Koinzaar',
        link: '/glasswire/koinzaar',
      },
    ],
  },
  {
    icon: FiZap,
    title: 'Battlestation',
    roleLevel: IRoleLevels.employee,
    subItems: [
      {
        title: 'Trades',
        link: '/trades',
        roleLevel: IRoleLevels.employee,
      },
      {
        title: 'Card processing',
        link: '/prepaid-card-processing',
        roleLevel: IRoleLevels.partner,
      },
      {
        title: 'Prepaid Cards',
        link: '/prepaid-cards',
        roleLevel: IRoleLevels.employee,
      },
    ],
  },
  {
    icon: FiBell,
    title: 'Notifications',
    link: '/notifications',
    roleLevel: IRoleLevels.employee,
  },
  {
    icon: FiUserCheck,
    title: 'Vendors',
    link: '/vendors',
    roleLevel: IRoleLevels.super_admin,
  },
  {
    icon: FiDollarSign,
    title: 'Billing',
    link: '/billing',
    roleLevel: IRoleLevels.admin,
  },
  {
    title: 'Offers',
    icon: MdLocalOffer,
    subItems: [
      {
        title: 'Offers management',
        link: '/offers',
      },
      {
        title: 'Offer groups',
        link: '/offer-groups',
        roleLevel: IRoleLevels.admin,
      },
    ],
    roleLevel: IRoleLevels.employee,
  },
  {
    icon: FiBookOpen,
    title: 'Trades history',
    link: '/trades-history',
    roleLevel: IRoleLevels.employee,
  },
  {
    icon: FiUsers,
    title: 'Users',
    link: '/users',
    roleLevel: IRoleLevels.admin,
  },
  {
    icon: FiShoppingCart,
    title: 'Marketplaces',
    roleLevel: IRoleLevels.admin,
    subItems: [
      {
        title: 'Marketplaces',
        link: '/marketplaces',
        roleLevel: IRoleLevels.super_admin,
      },
      {
        title: 'Accounts',
        link: '/marketplace-accounts',
        roleLevel: IRoleLevels.admin,
      },
    ],
  },
  {
    icon: FiCreditCard,
    title: 'Payrolls',
    roleLevel: IRoleLevels.admin,
    subItems: [
      {
        title: 'History',
        link: '/payroll-history',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Dynamic payrolls',
        link: '/dynamic-payroll-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Fixed payrolls',
        link: '/fixed-payroll-settings',
        roleLevel: IRoleLevels.admin,
      },
    ],
  },
  {
    icon: FiSettings,
    title: 'Settings',
    roleLevel: IRoleLevels.employee,
    subItems: [
      {
        title: 'Vendor',
        link: '/vendor-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'General',
        link: '/general-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Namescan',
        link: '/namescan-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Bots',
        link: '/bot-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Payquick',
        link: '/payquick-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Funds',
        link: '/funds-settings',
        roleLevel: IRoleLevels.admin,
      },
      {
        title: 'Billing',
        link: '/billing-settings',
        roleLevel: IRoleLevels.super_admin,
      },
      {
        title: 'Predefined messages',
        link: '/predefined-messages',
        roleLevel: IRoleLevels.employee,
      },
      {
        title: 'Setup Wizard',
        link: '/initial-setup',
        roleLevel: IRoleLevels.employee,
      },
      {
        title: 'Personal',
        link: '/personal-settings',
        roleLevel: IRoleLevels.employee,
      },
    ],
  },
];

const Drawer: React.FC = () => {
  const MenuBackgroundColor = useColorModeValue('gray.700', 'white');
  const MenuIconColor = useColorModeValue('white', 'gray.700');
  const logo = useColorModeValue(darkLogo, lightLogo);

  const { navSize, isFixed, changeNavSize } = useDrawer();

  const user = useMemo<User | null>(
    () => {
      const localUser = localStorage.getItem(localStorageConfig.userKey);
      if (!localUser) {
        return null;
      }
      return JSON.parse(localUser);
    },
    // eslint-disable-next-line
    [],
  );

  return (
    <Flex
      p={3}
      w="100%"
      as="nav"
      flexDir="column"
      alignItems={navSize === 'small' ? 'center' : 'flex-start'}
      gridGap={2}
    >
      {isFixed && (
        <Box w="35px" h="35px" mb={4}>
          <HStack spacing={9}>
            <IconButton
              aria-label="collapse sidemenu"
              _hover={{ background: 'gray.500' }}
              bg={MenuBackgroundColor}
              color={MenuIconColor}
              alignSelf={navSize === 'small' ? 'center' : 'start'}
              icon={<FiMenu />}
              borderRadius="7"
              onClick={() => {
                if (navSize === 'small') changeNavSize('large');
                else changeNavSize('small');
              }}
            />
            {navSize === 'large' && (
              <Image alt="" height={10} maxWidth="80px" src={logo} />
            )}
          </HStack>
        </Box>
      )}
      {menu.map(
        (item, i) =>
          userHasRole(user, item.roleLevel) && (
            <NavItem
              key={i}
              navSize={navSize}
              setNavSize={changeNavSize}
              icon={item.icon}
              title={item.title}
              subItems={item.subItems}
              link={item.link}
              roleLevel={item.roleLevel}
            />
          ),
      )}
    </Flex>
  );
};

export default Drawer;
