import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import IUpsertFTXSettingsData from 'Types/DTOs/IUpsertFTXSettingsData';
import { useSettings } from 'Hooks/settings';

const FundsSettingsFTXAPI: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { indexSelectedSettings, upsertFTXSettings } = useSettings();

  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [formData, setFormData] = useState<IUpsertFTXSettingsData>(
    {} as IUpsertFTXSettingsData,
  );

  const getSettings = useCallback(async () => {
    const data = await indexSelectedSettings<IUpsertFTXSettingsData>({
      data: {
        names: ['ftx_key', 'ftx_secret', 'ftx_subaccount'],
      },
      setLoading: setLoadingGet,
    });
    if (!data) {
      return;
    }
    setFormData(data);
    formRef.current?.setData(data);
  }, [indexSelectedSettings, setLoadingGet]);

  const handleUpsertSettings = useCallback(
    async (data: IUpsertFTXSettingsData) => {
      setLoadingUpsert(true);
      await upsertFTXSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      await getSettings();
    },
    [upsertFTXSettings, formRef, getSettings],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Skeleton isLoaded={!loadingGet}>
      <Form
        ref={formRef}
        onSubmit={handleUpsertSettings}
        initialData={formData}
        spacing="10px"
      >
        <Input type="text" name="ftx_key" label="FTX key" />
        <Input type="text" name="ftx_secret" label="FTX secret" />
        <Input
          type="text"
          name="ftx_subaccount"
          label="FTX subaccount"
          mb="10px"
        />
        <Box>
          <Button type="submit" isLoading={loadingUpsert} isPrimary>
            Save settings
          </Button>
        </Box>
      </Form>
    </Skeleton>
  );
};

export default FundsSettingsFTXAPI;
