import React from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Route from 'Components/Atoms/Route';
import Login from 'Pages/Login';
import PersonalSettings from 'Pages/PersonalSettings';
import BotSettings from 'Pages/BotSettings';
import ActiveTrades from 'Pages/ActiveTrades';
import TradesHistory from 'Pages/TradesHistory';
import Trading from 'Pages/Trading';
import PayrollHistory from 'Pages/Payrolls/PayrollHistory';
import PayrollSettings from 'Pages/Payrolls/PayrollSettings';
import UserPayrollSettings from 'Pages/Payrolls/UserPayrollSettings';
import Marketplaces from 'Pages/Marketplaces';
import MarketplaceAccounts from 'Pages/MarketplaceAccounts';
import Users from 'Pages/Users';
import Vendors from 'Pages/Vendors';
import Charges from 'Pages/Charges';
import PayQuickSettings from 'Pages/PayQuickSettings';
import Offers from 'Pages/Offers';
import OfferGroups from 'Pages/OfferGroups';
import FundsSettings from 'Pages/FundsSettings';
import ChargeVendorsSettings from 'Pages/ChargeVendorsSettings';
import PrepaidCardProcessing from 'Pages/PrepaidCardProcessing';
import VendorSettings from 'Pages/VendorSettings';
import Dashboard from 'Pages/Dashboard';
import Glasswire from 'Pages/Glasswire';
import PredefinedMessagesTable from 'Pages/PredefinedMessagesTable';
import PrepaidCards from 'Pages/PrepaidCards';
import NamescanSettings from 'Pages/NamescanSettings';
import Notifications from 'Pages/Notifications';
import IRoleLevels from 'Types/Enums/IRoleLevels';
import RequestRecoveryPassword from 'Pages/RequestRecoveryPassword';
import RecoveryPassword from 'Pages/RecoveryPassword';
import GeneralSettings from 'Pages/GeneralSettings';
import Jobs from 'Pages/Jobs';
import SetupWizard from 'Pages/SetupWizard';

const Routes: React.FC = () => {
  return (
    <Switch>
      {/* Login */}
      <Route path="/" component={Login} exact />

      {/* Recovery password */}
      <Route
        path="/request-recovery-password"
        component={RequestRecoveryPassword}
        exact
      />
      <Route path="/recovery-password" component={RecoveryPassword} exact />

      {/* Developer */}
      <Route
        path="/jobs"
        component={Jobs}
        roleLevel={IRoleLevels.developer}
        isPrivate
      />

      {/* Dashboard */}
      <Route
        path="/dashboard/:marketplace"
        component={Dashboard}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      {/* Glasswire */}
      <Route
        path="/glasswire/:marketplace"
        component={Glasswire}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />

      {/* Battlestation */}
      <Route
        path="/trades"
        component={ActiveTrades}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />
      <Route
        path="/trading/:hash"
        component={Trading}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />
      <Route
        path="/prepaid-card-processing"
        component={PrepaidCardProcessing}
        roleLevel={IRoleLevels.partner}
        isPrivate
      />
      <Route
        path="/prepaid-cards"
        component={PrepaidCards}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      {/* Notifications */}
      <Route
        path="/notifications"
        component={Notifications}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      {/* Vendors */}
      <Route
        path="/vendors"
        component={Vendors}
        roleLevel={IRoleLevels.super_admin}
        isPrivate
      />

      {/* Billing */}
      <Route
        path="/billing"
        component={Charges}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />

      {/* Marketplaces */}
      <Route
        path="/marketplaces"
        component={Marketplaces}
        roleLevel={IRoleLevels.super_admin}
        isPrivate
      />
      <Route
        path="/personal-settings"
        roleLevel={IRoleLevels.partner}
        component={PersonalSettings}
        isPrivate
      />
      <Route
        path="/marketplace-accounts"
        component={MarketplaceAccounts}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />

      {/* Offers */}
      <Route
        path="/offer-groups"
        roleLevel={IRoleLevels.admin}
        component={OfferGroups}
        isPrivate
      />
      <Route
        path="/offers"
        component={Offers}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      {/* History */}
      <Route
        path="/trades-history"
        component={TradesHistory}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      {/* Users */}
      <Route
        path="/users"
        component={Users}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />

      {/* Payrolls */}
      <Route
        path="/payroll-history"
        component={PayrollHistory}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />
      <Route
        path="/dynamic-payroll-settings"
        component={PayrollSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/fixed-payroll-settings"
        component={UserPayrollSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />

      {/* Settings */}
      <Route
        path="/bot-settings"
        component={BotSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/payquick-settings"
        component={PayQuickSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/funds-settings"
        component={FundsSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/billing-settings"
        component={ChargeVendorsSettings}
        roleLevel={IRoleLevels.super_admin}
        isPrivate
      />
      <Route
        path="/predefined-messages"
        component={PredefinedMessagesTable}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />
      <Route
        path="/vendor-settings"
        component={VendorSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/namescan-settings"
        component={NamescanSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/general-settings"
        component={GeneralSettings}
        roleLevel={IRoleLevels.admin}
        isPrivate
      />
      <Route
        path="/initial-setup"
        component={SetupWizard}
        roleLevel={IRoleLevels.employee}
        isPrivate
      />

      <Redirect from="*" to="/dashboard/paxful" />
    </Switch>
  );
};

export default Routes;
