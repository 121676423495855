import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import IUpsertKrakenSettingsData from 'Types/DTOs/IUpsertKrakenSettingsData';
import { useSettings } from 'Hooks/settings';

const FundsSettingsKrakenAPI: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { indexSelectedSettings, upsertKrakenSettings } = useSettings();

  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [formData, setFormData] = useState<IUpsertKrakenSettingsData>(
    {} as IUpsertKrakenSettingsData,
  );

  const getSettings = useCallback(async () => {
    const data = await indexSelectedSettings<IUpsertKrakenSettingsData>({
      data: {
        names: ['kraken_public_key', 'kraken_private_key', 'kraken_otp'],
      },
      setLoading: setLoadingGet,
    });
    if (!data) {
      return;
    }
    setFormData(data);
    formRef.current?.setData(data);
  }, [indexSelectedSettings, setLoadingGet]);

  const handleUpsertSettings = useCallback(
    async (data: IUpsertKrakenSettingsData) => {
      setLoadingUpsert(true);
      await upsertKrakenSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      await getSettings();
    },
    [upsertKrakenSettings, formRef, getSettings],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Skeleton isLoaded={!loadingGet}>
      <Form
        ref={formRef}
        onSubmit={handleUpsertSettings}
        initialData={formData}
        spacing="10px"
      >
        <Input type="text" name="kraken_public_key" label="Kraken public key" />
        <Input
          type="text"
          name="kraken_private_key"
          label="Kraken private key"
        />
        <Input
          type="text"
          name="kraken_otp"
          label="Kraken optional otp"
          mb="10px"
        />
        <Box>
          <Button type="submit" isLoading={loadingUpsert} isPrimary>
            Save settings
          </Button>
        </Box>
      </Form>
    </Skeleton>
  );
};

export default FundsSettingsKrakenAPI;
