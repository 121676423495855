import React, { useMemo } from 'react';
import { Box, Flex } from '@chakra-ui/react';

import PaginationButton from 'Components/Atoms/PaginationButton';
import PaginationControlButton from 'Components/Atoms/PaginationControlButton';

export interface IPaginationProps {
  currentPage: number;
  limit: number;
  total: number;
  setPage: (newPage: number) => void;
}

const Pagination: React.FC<IPaginationProps> = ({
  currentPage,
  limit,
  total,
  setPage,
}) => {
  const pages = useMemo(() => {
    const base = Array.from(
      { length: Math.ceil(total / limit) },
      (_v, i) => i + 1,
    );
    if (base.length > 10) {
      return base.slice(currentPage > 3 ? currentPage - 3 : 0, currentPage + 3);
    }
    return base;
  }, [total, limit, currentPage]);

  return (
    <Flex w="100%" mt="20px" justifyContent="flex-end">
      <Flex>
        {currentPage > 1 && (
          <PaginationControlButton
            direction="left"
            onClick={() => setPage(currentPage - 1)}
          />
        )}
        {pages.length > 1 &&
          pages.map((page, i) => (
            <Box key={page} position="relative">
              <PaginationButton
                index={i}
                pages={pages}
                onClick={() => setPage(page)}
              >
                {page}
              </PaginationButton>
              {page === currentPage && (
                <PaginationButton active>{page}</PaginationButton>
              )}
            </Box>
          ))}
        {currentPage < pages.length && (
          <PaginationControlButton
            direction="right"
            onClick={() => setPage(currentPage + 1)}
          />
        )}
      </Flex>
    </Flex>
  );
};

export default Pagination;
