import React from 'react';

import AuthProvider from './auth';
import ErrorsProvider from './errors';
import GiftCardsProvider from './giftCards';
import MarketplacesProvider from './marketplaces';
import OffersProvider from './offers';
import PayrollsProvider from './payrolls';
import SettingsProvider from './settings';
import UsersProvider from './users';
import TradesProvider from './trades';
import VendorsProvider from './vendors';
import ChatsProvider from './chats';
import SocketsProvider from './sockets';
import DrawerProvider from './drawer';
import ReportsProvider from './reports';
import PredefinedMessagesProvider from './predefinedMessages';
import ScannerProvider from './scanner';
import NotificationsProvider from './notifications';
import RolesProvider from './roles';
import PasswordProvider from './password';
import JobsProvider from './jobs';
import charges from './charges';

const providers = [
  ErrorsProvider,
  AuthProvider,
  SocketsProvider,
  DrawerProvider,
  PayrollsProvider,
  SettingsProvider,
  UsersProvider,
  MarketplacesProvider,
  TradesProvider,
  VendorsProvider,
  GiftCardsProvider,
  OffersProvider,
  ChatsProvider,
  ReportsProvider,
  PredefinedMessagesProvider,
  ScannerProvider,
  NotificationsProvider,
  RolesProvider,
  PasswordProvider,
  JobsProvider,
  charges,
];

const CombineProviders: React.FC = ({ children }) => {
  return (
    <>
      {providers.reduceRight((acc, Comp, i) => {
        return <Comp key={i}>{acc}</Comp>;
      }, children)}
    </>
  );
};

export default CombineProviders;
