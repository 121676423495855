import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import TradesHistoryTable from 'Components/Organisms/TradesHistoryTable';

const TradesHistory: React.FC = () => {
  useEffect(() => {
    document.title = 'Trades History - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <TradesHistoryTable />
      </PageContainer>
    </WithDrawer>
  );
};

export default TradesHistory;
