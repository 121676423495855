import React, { useCallback, useEffect, useState } from 'react';
import {
  Flex,
  Icon,
  LinkBox,
  LinkOverlay,
  Text,
  Badge,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Trade } from '@spiry-capital/modules';

import Button from 'Components/Atoms/Button';
import Table from 'Components/Molecules/Table';
import formatDate from 'Helpers/formatDate';
import { FiAlertTriangle } from 'react-icons/fi';
import { useColor } from 'Hooks/color';
import IPaginated from 'Types/Standards/IPaginated';
import { useSockets } from 'Hooks/sockets';
import TradingAttached from '../TradingAttached';

interface IData {
  data: {
    page: number;
  };
}
interface IProps {
  recentModerated: string[];
  activeTrades: IPaginated<Trade>;
  indexActiveTrades(data: IData): Promise<void>;
}

const ActiveTradesTable: React.FC<IProps> = ({
  indexActiveTrades,
  recentModerated,
  activeTrades,
}) => {
  const color = useColor();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);

  const sockets = useSockets();

  const fetchTrades = useCallback(
    (shouldSetLoading = true) => {
      if (shouldSetLoading) {
        setLoading(true);
      }
      indexActiveTrades({
        data: {
          page,
        },
      }).finally(() => {
        if (shouldSetLoading) {
          setLoading(false);
        }
      });
    },
    [indexActiveTrades, page],
  );

  useEffect(() => {
    fetchTrades();
    const interval = setInterval(() => fetchTrades(false), 10000);
    return () => {
      clearInterval(interval);
    };
  }, [fetchTrades]);

  const handleIncomingTrade = useCallback(() => {
    setTimeout(() => fetchTrades(), 2000);
  }, [fetchTrades]);

  useEffect(() => {
    if (sockets.connected) {
      sockets.on('trade.incoming', handleIncomingTrade);
    }
  }, [handleIncomingTrade, sockets]);

  return (
    <Table
      isLoading={loading}
      accordion={(row: Trade) => <TradingAttached tradeHash={row.hash} />}
      accordionKey="id"
      columns={[
        {
          key: 'customer',
          title: 'Customer',
          render: (row: Trade) => {
            return (
              <Flex alignItems="center">
                {Number(row.chat?.unread) > 0 && (
                  <Badge
                    variant="solid"
                    mr="2"
                    colorScheme="green"
                    borderRadius="md"
                    px="2"
                  >
                    {row.chat?.unread}
                  </Badge>
                )}
                {recentModerated.includes(row.hash) && (
                  <Icon as={FiAlertTriangle} color={color} size={20} mr="5px" />
                )}
                <Text>{row.responder_username}</Text>
              </Flex>
            );
          },
        },
        {
          key: 'owner_username',
          title: 'Account',
          render: row => row.owner_username,
        },
        {
          key: 'trade_status',
          title: 'Status',
          render: row => row.status,
        },
        {
          key: 'started_at',
          title: 'Started at',
          render: row => formatDate(row.started_at),
        },
        {
          key: 'trade_hash',
          title: 'Trade hash',
          render: row => row.hash,
        },
        {
          key: 'fiat_amount_requested',
          title: 'Amount',
          render: row => row.fiat_amount_requested,
        },
        {
          key: 'margin',
          title: 'Margin',
          render: row => row.margin,
        },
        {
          key: 'payment_method_name',
          title: 'Offer',
          render: row => row.payment_method_name,
        },
        {
          key: 'actions',
          title: 'Actions',
          render: (row: Trade) => (
            <LinkBox>
              <LinkOverlay
                as={RouterLink}
                to={`/trading/${row.hash}`}
                isExternal
              >
                <Button colorScheme="green" isDanger isPrimary>
                  View details
                </Button>
              </LinkOverlay>
            </LinkBox>
          ),
        },
      ]}
      rows={activeTrades.entities}
      pagination={{
        currentPage: page,
        limit: 25,
        setPage,
        total: activeTrades.total,
      }}
    />
  );
};

export default ActiveTradesTable;
