import React, { useCallback, useRef, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Code,
  Heading,
  HStack,
  Skeleton,
  Table,
  Tbody,
  Td,
  Th,
  Tr,
  useColorModeValue,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { format as formatDate } from 'date-fns';
import { FaCheckCircle, FaExclamationCircle } from 'react-icons/fa';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import FormGroup from 'Components/Molecules/FormGroup';
import InputGroup from 'Components/Molecules/InputGroup';
import Button from 'Components/Atoms/Button';
import { IScan, useScanner } from 'Hooks/scanner';
import { useErrors } from 'Hooks/errors';
import IObject from 'Types/Standards/IObject';
import IPersonData from 'Types/DTOs/IPersonData';

interface IIdentityVerification {
  tradeId: string;
}

const IdentityVerification: React.FC<IIdentityVerification> = ({ tradeId }) => {
  const formRef = useRef<FormHandles>(null);

  const { scanPerson, getScansByTradeId } = useScanner();
  const { handleErrors } = useErrors();

  const accordionHeaderColor = useColorModeValue('gray.200', 'gray.600');
  const accordionBodyColor = useColorModeValue('gray.100', 'gray.500');
  const blockBgColor = useColorModeValue('gray.200', '#8492a5');

  const [isVerifying, setIsVerifying] = useState(false);
  const [results, setResults] = useState<IScan[]>([{} as IScan]);

  const fetchResults = useCallback(async () => {
    const scans = await getScansByTradeId(tradeId);
    setResults(scans);
  }, [getScansByTradeId, tradeId]);

  const handleSubmit = useCallback(
    async (data: IPersonData) => {
      setIsVerifying(true);
      if (data.bod) {
        data.bod = formatDate(new Date(String(data.bod)), 'dd/mm/yyyy');
      } else {
        delete data.bod;
      }
      try {
        await scanPerson({ ...data, trade_id: tradeId });
        await fetchResults();
      } catch (error: any) {
        handleErrors('Failed to scan person', error, formRef);
      } finally {
        setIsVerifying(false);
      }
    },
    [fetchResults, handleErrors, scanPerson, tradeId],
  );

  return (
    <Box>
      <Form ref={formRef} onSubmit={handleSubmit}>
        <FormGroup title="Personal data" mb={5}>
          <InputGroup spacing="10px">
            <Input label="First name" type="text" name="first_name" />
            <Input label="Last name" type="text" name="last_name" />
          </InputGroup>
          <Input type="date" label="Date of birth" name="bod" />
        </FormGroup>
        <Box>
          <Button isPrimary type="submit" isLoading={isVerifying}>
            Submit
          </Button>
        </Box>
      </Form>

      <Accordion defaultIndex={0} mt={5} mb={5}>
        {results.map(({ response }) => (
          <AccordionItem border="0">
            <h2>
              <AccordionButton
                bg={accordionHeaderColor}
                borderTopRadius={5}
                border="0"
                py={2}
              >
                <HStack>
                  {response && response.numberOfMatches === 0 && (
                    <FaCheckCircle
                      color="var(--chakra-colors-green-500)"
                      display="inline"
                    />
                  )}
                  {response && response.numberOfMatches > 0 && (
                    <FaExclamationCircle
                      color="var(--chakra-colors-yellow-500)"
                      display="inline"
                    />
                  )}
                  <Box flex="1" textAlign="left">
                    Response
                  </Box>
                </HStack>
              </AccordionButton>
            </h2>
            <AccordionPanel
              pb={2}
              bg={accordionBodyColor}
              borderBottomRadius={5}
            >
              <Skeleton isLoaded={!isVerifying}>
                {!response && 'Nothing to see here'}
                {response && (
                  <Box>
                    <Heading my={5} size="md">
                      General
                    </Heading>
                    <Table
                      variant="unstyled"
                      borderRadius="md"
                      bg={blockBgColor}
                      maxW="500px"
                    >
                      <Tbody>
                        <Tr>
                          <Th>Scan ID</Th>
                          <Td>{response?.scanId}</Td>
                        </Tr>
                        <Tr>
                          <Th>Date</Th>
                          <Td>
                            {response &&
                              formatDate(new Date(response?.date), 'Pp')}
                          </Td>
                        </Tr>
                        <Tr>
                          <Th>Number of matches</Th>
                          <Td>{response?.numberOfMatches}</Td>
                        </Tr>
                        <Tr>
                          <Th>Number of PEP matches</Th>
                          <Td>{response?.numberOfPepMatches}</Td>
                        </Tr>
                        <Tr>
                          <Th>Number of SIP matches</Th>
                          <Td>{response?.numberOfSipMatches}</Td>
                        </Tr>
                      </Tbody>
                    </Table>
                    {response?.persons && (
                      <Box>
                        <Heading my={5} size="md">
                          Persons found
                        </Heading>
                        <Accordion
                          borderTopRadius={5}
                          overflow="hidden"
                          allowMultiple
                          allowToggle
                          mt={5}
                          mb={5}
                        >
                          {response.persons.map((person: IObject) => (
                            <>
                              <AccordionItem border="0">
                                <h2>
                                  <AccordionButton
                                    bg={accordionHeaderColor}
                                    py={2}
                                  >
                                    <FaExclamationCircle
                                      color="var(--chakra-colors-yellow-500)"
                                      display="inline"
                                    />
                                    <Box ml={2} flex="1" textAlign="left">
                                      {person.matchRate}% - {person.name}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                </h2>
                                <AccordionPanel
                                  pb={2}
                                  bg={accordionBodyColor}
                                  borderBottomRadius={5}
                                >
                                  <Code
                                    colorScheme="black"
                                    borderRadius="md"
                                    bg={blockBgColor}
                                    p={5}
                                  >
                                    <pre>{JSON.stringify(person, null, 2)}</pre>
                                  </Code>
                                </AccordionPanel>
                              </AccordionItem>
                            </>
                          ))}
                        </Accordion>
                      </Box>
                    )}
                  </Box>
                )}
              </Skeleton>
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default IdentityVerification;
