import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Switch from 'Components/Atoms/Switch';
import Textarea from 'Components/Atoms/Textarea';
import MultiSelect, { IMultiSelectOption } from 'Components/Atoms/MultiSelect';
import ISetState from 'Types/Standards/ISetState';
import ICreateOfferGroupData from 'Types/DTOs/ICreateOfferGroupData';
import { useOffers } from 'Hooks/offers';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
}

const OfferGroupsForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingOffers, setLoadingOffers] = useState(false);
  const [loadingMargins, setLoadingMargins] = useState(false);
  const [defaultOffers, setDefaultOffers] = useState<string[]>([]);

  const {
    createOfferGroup,
    currentOfferGroup,
    indexOfferGroups,
    setCurrentOfferGroup,
    updateOfferGroup,
    indexAllOffers,
    allOffers,
    updateOfferGroupMargins,
  } = useOffers();

  const isEdit = useMemo(() => !!currentOfferGroup, [currentOfferGroup]);
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} offer group`,
    [isEdit],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);
  const offersOptions = useMemo<IMultiSelectOption[]>(
    () => allOffers.map(offer => ({ label: offer.hash, value: offer.hash })),
    [allOffers],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setDefaultOffers([]);
    setCurrentOfferGroup(null);
  }, [onClose, setCurrentOfferGroup]);

  const submitForm = useCallback(
    async (data: ICreateOfferGroupData) => {
      setLoadingForm(true);
      if (isEdit && currentOfferGroup) {
        await updateOfferGroup({
          data: {
            id: currentOfferGroup.id,
            ...data,
          },
          formRef,
          onClose,
          setDefaultOffers,
        });
      } else {
        await createOfferGroup({
          data,
          onClose,
          formRef,
          setDefaultOffers,
        });
      }
      setLoadingForm(false);
      setLoadingTable(true);
      await indexOfferGroups({
        data: {
          page: 1,
        },
      });
      setLoadingTable(false);
    },
    [
      isEdit,
      onClose,
      setLoadingTable,
      createOfferGroup,
      currentOfferGroup,
      indexOfferGroups,
      updateOfferGroup,
    ],
  );

  const handleUpdateMargins = useCallback(async () => {
    if (!currentOfferGroup) {
      return;
    }
    setLoadingMargins(true);
    await updateOfferGroupMargins({
      data: {
        id: currentOfferGroup.id,
        margin: formRef.current?.getFieldValue('margin'),
      },
    });
    setLoadingMargins(false);
  }, [updateOfferGroupMargins, currentOfferGroup]);

  useEffect(() => {
    setLoadingOffers(true);
    indexAllOffers().finally(() => {
      setLoadingOffers(false);
    });
  }, [indexAllOffers]);

  useEffect(() => {
    if (!currentOfferGroup || !currentOfferGroup.has_offers) {
      return;
    }

    const availableHashes = allOffers.map(offer => offer.hash);

    const offersHashes = currentOfferGroup.has_offers
      .filter(relation => {
        if (relation.offer && availableHashes.includes(relation.offer.hash)) {
          return true;
        }
        return false;
      })
      .map(relation => relation.offer?.hash);

    if (!loadingOffers) {
      setDefaultOffers(offersHashes as string[]);
    }
  }, [allOffers, loadingOffers, currentOfferGroup]);

  return (
    <Card title="Actions">
      <Button type="button" onClick={onOpen} isPrimary>
        Create offer group
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentOfferGroup || {}}
            >
              <Input
                placeholder="Offer group"
                label="Name"
                name="name"
                type="text"
              />
              <Input
                placeholder="00.00%"
                label="Processing fee"
                name="processing_fee"
                type="number"
              />
              <Input
                placeholder="00.00%"
                label="Margin"
                name="margin"
                type="number"
              />
              {isEdit && (
                <Button
                  isLoading={loadingMargins}
                  onClick={handleUpdateMargins}
                  isPrimary
                >
                  Update all offers margin
                </Button>
              )}
              <MultiSelect
                placeholder="Select offers"
                label="Offers"
                name="offers"
                defaultValue={defaultOffers}
                options={offersOptions}
                isLoading={loadingOffers}
              />
              <Switch label="Watson enabled" name="watson_enabled" />
              <Input
                placeholder="Secret"
                label="Watson assistant ID"
                name="watson_assistant"
                type="text"
              />
              <Switch
                label="Static message enabled"
                name="static_message_enabled"
              />
              <Textarea
                placeholder="Hello!"
                label="Marketplace chat static message"
                name="static_message"
              />
              <Switch
                label="PayQuick prepaid processing enabled"
                name="payquick_prepaid_enabled"
              />
              <Switch
                label="PayQuick VIP payments enabled"
                name="payquick_vip_enabled"
              />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default OfferGroupsForm;
