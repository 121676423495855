import React, { useMemo } from 'react';
import { useHistory, Link as RouterLink } from 'react-router-dom';
import {
  Center,
  Flex,
  Icon,
  Spacer,
  Text,
  Box,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  Popover,
  Link,
  useColorMode,
  useColorModeValue,
  Avatar,
} from '@chakra-ui/react';
import {
  FiHome,
  FiSun,
  FiMoon,
  FiSettings,
  FiLogOut,
  FiMenu,
} from 'react-icons/fi';

import { useAuth } from 'Hooks/auth';
import { useColor } from 'Hooks/color';
import localStorageConfig from 'Config/localStorage';
import { useDrawer } from 'Hooks/drawer';
import { User } from '@spiry-capital/modules';
import IRoleLevels from 'Types/Enums/IRoleLevels';
import HeaderNotificationsMenu from '../HeaderNotificationsMenu';

const Header: React.FC = () => {
  const color = useColor();
  const history = useHistory();
  const { user: storeUser, logout } = useAuth();
  const { isFixed, onOpen } = useDrawer();
  const { colorMode, toggleColorMode } = useColorMode();
  // const [isSearching, setIsSearching] = useState(false);

  const backgroundColor = useColorModeValue('white', 'gray.700');
  const iconColor = useColorModeValue('gray.600', 'white');

  const user = useMemo<User | null>(
    () => {
      const localUser = localStorage.getItem(localStorageConfig.userKey);
      if (!localUser) {
        return null;
      }
      return JSON.parse(localUser);
    },
    // eslint-disable-next-line
    [storeUser],
  );

  const role = useMemo(() => {
    if (!user || !user.role) {
      return '';
    }
    return user.role.name.replace('_', ' ');
  }, [user]);

  const showNotifications = useMemo(
    () => user && user.role && user.role.level >= IRoleLevels.employee,
    [user],
  );

  return (
    <Flex
      borderRadius="7"
      px="15"
      mx="25px"
      h="60px"
      boxShadow="lg"
      bg={backgroundColor}
    >
      <Center w="100%" gridGap="3">
        {!isFixed && (
          <Icon
            as={FiMenu}
            color={iconColor}
            w={5}
            h={5}
            cursor="pointer"
            onClick={() => onOpen()}
          />
        )}
        <Icon
          as={FiHome}
          color={iconColor}
          w={5}
          h={5}
          cursor="pointer"
          onClick={() => history.push('/dashboard/paxful')}
        />
        <Spacer />

        {/* {isSearching ? (
          <InputGroup maxWidth="auto">
            <InputLeftElement pointerEvents="none">
              <Icon as={FiSearch} color={iconColor} w={5} h={5} />
            </InputLeftElement>
            <Input type="te" placeholder="Search" />
            <InputRightElement>
              <Icon
                cursor="pointer"
                as={FiX}
                color={iconColor}
                onClick={() => setIsSearching(false)}
                w={5}
                h={5}
              />
            </InputRightElement>
          </InputGroup>
        ) : (
          <Icon
            cursor="pointer"
            as={FiSearch}
            color={iconColor}
            onClick={() => setIsSearching(!isSearching)}
            w={5}
            h={5}
          />
        )} */}
        {colorMode === 'light' ? (
          <Icon
            onClick={toggleColorMode}
            cursor="pointer"
            as={FiMoon}
            color="gray.600"
            w={5}
            h={5}
          />
        ) : (
          <Icon
            onClick={toggleColorMode}
            cursor="pointer"
            as={FiSun}
            color="white"
            w={5}
            h={5}
          />
        )}

        {showNotifications && <HeaderNotificationsMenu />}

        <Box whiteSpace="nowrap">
          <Text textTransform="capitalize" fontSize="sm" align="right">
            {user?.fullname}
          </Text>
          <Text textTransform="capitalize" fontSize="xs" align="right">
            {role}
          </Text>
        </Box>
        <Popover placement="bottom-end" closeOnBlur trigger="hover">
          <PopoverTrigger>
            <Avatar
              cursor="pointer"
              borderRadius="full"
              boxSize="40px"
              name={user?.fullname}
              backgroundColor={`${color}.300`}
            />
          </PopoverTrigger>
          <PopoverContent width="160px">
            <PopoverBody>
              <Flex direction="column" align="flex-start" gridGap="2">
                {/* <Link href="/" isExternal>
                  <Center gridGap="3">
                    <Icon as={FiHelpCircle} color={iconColor} w={4} h={4} />
                    <Text>FAQ</Text>
                  </Center>
                </Link>
                <Divider /> */}
                <Link as={RouterLink} to="/personal-settings">
                  <Center gridGap="3">
                    <Icon as={FiSettings} color={iconColor} w={4} h={4} />
                    <Text>Settings</Text>
                  </Center>
                </Link>
                <Center
                  onClick={() => logout()}
                  gridGap="3"
                  cursor="pointer"
                  _hover={{
                    textDecoration: 'underline',
                  }}
                >
                  <Icon as={FiLogOut} color={iconColor} w={4} h={4} />
                  <Text>Logout</Text>
                </Center>
              </Flex>
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Center>
    </Flex>
  );
};

export default Header;
