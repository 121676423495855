import React, { useEffect, useState } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import ChargesTable from 'Components/Organisms/ChargesTable';

const Charges: React.FC = () => {
  useEffect(() => {
    document.title = 'Billing - Orion';
  }, []);

  const [loadingTable, setLoadingTable] = useState(true);

  return (
    <WithDrawer>
      <PageContainer>
        <ChargesTable
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
        />
      </PageContainer>
    </WithDrawer>
  );
};

export default Charges;
