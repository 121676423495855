interface ICardLinks {
  [key: string]: string;
}

export const fourDigitsCardsLinks: ICardLinks = {
  '4852': 'https://www.walmartgift.com/wmgift',
  '4786': 'https://www.walmartgift.com/wmgift',
  '4718': 'https://www.mybalancenow.com',
  '4250': 'https://www.mybalancenow.com',
  '3779': 'https://balance.amexgiftcard.com',
  '3743': 'https://balance.amexgiftcard.com',
  '3790': 'https://balance.amexgiftcard.com',
  '4941': 'https://www.onevanilla.com',
  '5432': 'https://www.onevanilla.com',
  '4142': 'https://balance.vanillagift.com',
  '4847': 'https://balance.vanillagift.com',
  '5164': 'https://balance.vanillagift.com',
  '4358': 'https://www.giftcardmall.com/mygift',
  '4034': 'https://www.giftcardmall.com/mygift',
  '4912': 'https://www.giftcardmall.com/mygift',
  '4030': 'https://www.prepaidgiftbalance.com',
  '5165': 'https://www.prepaidgiftbalance.com',
  '5113': 'https://mcgift.giftcardmall.com',
  '4113': 'https://getmybalance.com/Account/Login',
  '4086': 'https://cardholder.theperfectgift.ca',
};

export const sixDigitsCardsLinks: ICardLinks = {
  '516612': 'https://www.mybalancenow.com',
  '516629': 'https://www.prepaidgiftbalance.com',
};
