import PageContainer from 'Components/Atoms/PageContainer';
import NamescanSettingsForm from 'Components/Organisms/NamescanSettingsForm';
import WithDrawer from 'Components/Templates/WithDrawer';
import React, { useEffect } from 'react';

const NamescanSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'Namescan Settings - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <NamescanSettingsForm />
      </PageContainer>
    </WithDrawer>
  );
};

export default NamescanSettings;
