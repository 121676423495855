import React from 'react';
import {
  Box,
  Heading,
  Text,
  useColorModeValue,
  BoxProps,
  Icon,
  Flex,
} from '@chakra-ui/react';
import { IconType } from 'react-icons';

import { Container } from './styles';

interface IProps extends BoxProps {
  title?: string;
  description?: string;
  icon?: IconType;
}

const Card: React.FC<IProps> = ({
  title,
  description,
  children,
  icon,
  ...rest
}) => {
  const cardColor = useColorModeValue('white', 'gray.700');

  return (
    <Container
      p="20px"
      backgroundColor={cardColor}
      boxShadow="0 4px 24px 0 rgb(34 41 47 / 10%)"
      borderRadius="7px"
      {...rest}
    >
      <Box mb={children && (title || description) ? '20px' : '0px'}>
        <Flex alignItems="center">
          {icon && <Icon as={icon} boxSize="20px" mr="10px" />}
          {title && (
            <Heading as="h1" size="md">
              {title}
            </Heading>
          )}
        </Flex>
        {description && <Text>{description}</Text>}
      </Box>
      {children}
    </Container>
  );
};

export default Card;
