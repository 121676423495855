import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import ISetState from 'Types/Standards/ISetState';
import IVendor from 'Types/Entities/IVendor';
import formatDate from 'Helpers/formatDate';
import { useVendors } from 'Hooks/vendors';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpenForm(): void;
}

const VendorsTable: React.FC<IProps> = ({
  onOpenForm,
  loadingTable,
  setLoadingTable,
}) => {
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const { deleteVendor, indexVendors, vendors, setCurrentVendor } =
    useVendors();

  useEffect(() => {
    setLoadingTable(true);
    indexVendors({
      data: {
        page,
      },
    }).finally(() => {
      setLoadingTable(false);
    });
  }, [indexVendors, setLoadingTable, page]);

  return (
    <Card title="Vendors">
      <Table
        isLoading={loadingTable}
        columns={[
          {
            key: 'name',
            title: 'name',
            dataIndex: 'name',
          },
          {
            key: 'created_at',
            title: 'created at',
            render: row => formatDate(row.created_at),
          },
          {
            key: 'updated_at',
            title: 'updated at',
            render: row => formatDate(row.updated_at),
          },
          {
            key: 'actions',
            title: 'actions',
            render: row => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick() {
                      onOpenForm();
                      setCurrentVendor(row as IVendor);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteVendor({
                        data: {
                          id: row.id,
                        },
                      });
                      setLoadingDelete(false);
                      setLoadingTable(true);
                      await indexVendors({
                        data: {
                          page,
                        },
                      });
                      setLoadingTable(false);
                    },
                    confirmation: {
                      title: `Delete vendor ${row.name}`,
                      body: 'Are you sure you want to delete this vendor and all data attached to it?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: vendors.total,
        }}
        rows={vendors.entities}
      />
    </Card>
  );
};

export default VendorsTable;
