import React, { createContext, useCallback, useContext, useState } from 'react';
import api from 'Services/api';

import IChat from 'Types/Entities/IChat';
import IPaginationData from 'Types/Standards/IPaginationData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import { useErrors } from './errors';

interface IGetChat
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
}

interface IPostAttachments
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
  imageUrl: string;
}

interface IPostMessage
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
  message: string;
}

interface IChatContext {
  chat: IChat;
  getChat(props: IGetChat): Promise<void>;
  sendMessage(props: IPostMessage): Promise<void>;
  sendAttachments(props: IPostAttachments): Promise<void>;
}

const ChatContext = createContext<IChatContext>({} as IChatContext);

export const ChatsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [chat, setChat] = useState({} as IChat);

  const getChat = useCallback(
    async ({ hash }: IGetChat) => {
      try {
        const response = await api.get<IChat>(`/trades/chats/${hash}`);
        setChat({
          ...response.data,
          messages: response.data.messages.sort((a, b) =>
            a.created_at > b.created_at ? 1 : -1,
          ),
        });
      } catch (err: any) {
        handleErrors('Error when trying to get the trade', err);
      }
    },
    [handleErrors],
  );

  const sendAttachments = useCallback(
    async ({ imageUrl, hash }: IPostAttachments) => {
      try {
        await api.post(`/trades/chats/attachments`, {
          trade_hash: hash,
          file: imageUrl,
        });
      } catch (err) {
        handleErrors('Error when trying to upload image', err);
      }
    },
    [handleErrors],
  );

  const sendMessage = useCallback(
    async ({ message, hash }: IPostMessage) => {
      try {
        await api.post(`/trades/chats/messages`, {
          trade_hash: hash,
          message,
        });
      } catch (err) {
        handleErrors('Error when trying to send message', err);
      }
    },
    [handleErrors],
  );

  return (
    <ChatContext.Provider
      value={{
        getChat,
        sendMessage,
        sendAttachments,
        chat,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = (): IChatContext => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error('useChat must be used within ChatProvider');
  }
  return context;
};

export default ChatsProvider;
