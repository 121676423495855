import React, { useEffect } from 'react';
import { Flex } from '@chakra-ui/react';

import Background from 'Components/Atoms/Background';
import RecoveryPasswordForm from 'Components/Organisms/RecoveryPasswordForm';

const RecoveryPassword: React.FC = () => {
  useEffect(() => {
    document.title = 'Recovery Password - Orion';
  }, []);

  return (
    <Flex h="100vh">
      <Background />
      <RecoveryPasswordForm />
    </Flex>
  );
};

export default RecoveryPassword;
