import React, { useEffect, useState } from 'react';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Flex,
  Skeleton,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';

import Attachment from 'Components/Atoms/Attachment';
import { useChat } from 'Hooks/chats';
import { useTrades } from 'Hooks/trades';

interface IProps {
  hash: string;
}

const AttachmentsModal: React.FC<IProps> = ({ hash }) => {
  const { trade } = useTrades();
  const { chat } = useChat();
  const { attachments } = chat;

  const [loading, setLoading] = useState(true);

  const attachmentsHeaderColor = useColorModeValue(`gray.200`, `gray.600`);
  const attachmentsBodyColor = useColorModeValue(`gray.100`, `gray.500`);

  useEffect(() => {
    if (trade && chat) {
      setLoading(false);
    }
  }, [chat, trade]);

  return (
    <Accordion allowMultiple mb={5}>
      <AccordionItem border="0">
        <h2>
          <AccordionButton
            bg={attachmentsHeaderColor}
            borderTopRadius={5}
            border="0"
            py={2}
          >
            <AccordionIcon />
            <Flex flex="1" textAlign="left" alignItems="center">
              Attachments ({attachments && attachments.length})
            </Flex>
          </AccordionButton>
        </h2>
        <Skeleton isLoaded={!loading}>
          <AccordionPanel
            pb={2}
            bg={attachmentsBodyColor}
            borderBottomRadius={5}
          >
            <Skeleton isLoaded={!loading} maxH="300px">
              <Flex py={3} gridGap={5} maxH="300px" overflowX="auto">
                {attachments && attachments.length > 0 ? (
                  attachments.map(attachment => (
                    <Attachment attachment={attachment} trade_hash={hash} />
                  ))
                ) : (
                  <Text>Nothing to see here</Text>
                )}
              </Flex>
            </Skeleton>
          </AccordionPanel>
        </Skeleton>
      </AccordionItem>
    </Accordion>
  );
};

export default AttachmentsModal;
