import React from 'react';

import Card from 'Components/Atoms/Card';
import FundsSettingsExchangeAPIAlert from 'Components/Molecules/FundsSettingsExchangeAPIAlert';
import FundsSettingsAPIForm from 'Components/Molecules/FundsSettingsAPIForm';
import Tabs from '../../Molecules/Tabs';
import FundsSettingsFTXAPI from '../FundsSettingsFTXAPI';
import FundsSettingsKrakenAPI from '../FundsSettingsKrakenAPI';

const FundsSettingsAPIs: React.FC = () => {
  return (
    <Card title="Exchange APIs">
      <FundsSettingsAPIForm />
      <Tabs
        tabs={[
          {
            title: 'FTX',
            component: <FundsSettingsFTXAPI />,
          },
          {
            title: 'Kraken',
            component: <FundsSettingsKrakenAPI />,
          },
        ]}
      />
      <FundsSettingsExchangeAPIAlert />
    </Card>
  );
};

export default FundsSettingsAPIs;
