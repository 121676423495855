import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import ISetState from 'Types/Standards/ISetState';
import ICreateVendorData from 'Types/DTOs/ICreateVendorData';
import { useVendors } from 'Hooks/vendors';
import { useAuth } from 'Hooks/auth';
import Select from 'Components/Atoms/Select';
import { useColor } from 'Hooks/color';
import Switch from 'Components/Atoms/Switch';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
}

const VendorsForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);

  const color = useColor();

  const {
    createVendor,
    currentVendor,
    indexVendors,
    setCurrentVendor,
    updateVendor,
  } = useVendors();
  const { user, updateUser } = useAuth();

  const isEdit = useMemo(() => !!currentVendor, [currentVendor]);
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} vendor`,
    [isEdit],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentVendor(null);
  }, [onClose, setCurrentVendor]);

  const submitForm = useCallback(
    async (data: ICreateVendorData) => {
      setLoadingForm(true);
      if (isEdit && currentVendor) {
        await updateVendor({
          data: {
            id: currentVendor.id,
            ...data,
          },
          formRef,
          onClose,
        });
        if (user?.vendor_id === currentVendor.id) {
          updateUser();
        }
      } else {
        await createVendor({
          data,
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);
      setLoadingTable(true);
      await indexVendors({
        data: {
          page: 1,
        },
      });
      setLoadingTable(false);
    },
    [
      isEdit,
      onClose,
      setLoadingTable,
      createVendor,
      currentVendor,
      indexVendors,
      updateVendor,
      user,
      updateUser,
    ],
  );

  const options = [
    {
      label: 'Gray',
      value: 'gray',
    },
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Green',
      value: 'green',
    },
    {
      label: 'Blue',
      value: 'blue',
    },
    {
      label: 'Teal',
      value: 'teal',
    },
    {
      label: 'Cyan',
      value: 'cyan',
    },
    {
      label: 'Purple',
      value: 'purple',
    },
    {
      label: 'Pink',
      value: 'pink',
    },
  ];

  return (
    <Card title="Actions">
      <Button type="button" onClick={onOpen} isPrimary>
        Create vendor
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentVendor || {}}
            >
              <Switch label="Active" name="active" />
              <Switch label="Chargeable" name="chargeable" />
              <Input
                placeholder="companny"
                label="Name"
                name="name"
                type="text"
              />
              <Input
                placeholder="companny@example.com"
                label="Email"
                name="email"
                type="text"
              />
              <Select
                name="theme"
                label="Theme"
                placeholder="Select a color"
                options={options}
                defaultValue={color}
              />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default VendorsForm;
