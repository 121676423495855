import { useAuth } from './auth';

export function useColor(): string {
  const { user } = useAuth();

  if (user && user.vendor && user.vendor.theme) {
    return user.vendor.theme;
  }

  const userTemp = localStorage.getItem('@SPIRY:USER');

  if (userTemp) {
    const optional = JSON.parse(userTemp);
    return optional.vendor.theme;
  }

  return 'green';
}
