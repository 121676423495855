import { User } from '@spiry-capital/modules';
import IRoleLevels from 'Types/Enums/IRoleLevels';

export default function userHasRole(
  user: User | null,
  roleLevel?: IRoleLevels,
): boolean {
  if (!roleLevel) return true;
  if (!user || !user.role) return false;
  return user.role.level >= roleLevel;
}
