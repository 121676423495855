import React, { useCallback } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useHistory } from 'react-router-dom';

import { useColor } from 'Hooks/color';

const HeaderNotificationsViewAll: React.FC = () => {
  const color = useColor();
  const history = useHistory();

  const handleViewAll = useCallback(async () => {
    history.push('/notifications');
  }, [history]);

  return (
    <Flex justifyContent="center" pr="10px" pt="5px">
      <Text
        textDecoration="underline"
        color={`${color}.300`}
        cursor="pointer"
        fontSize="sm"
        onClick={handleViewAll}
      >
        View All
      </Text>
    </Flex>
  );
};

export default HeaderNotificationsViewAll;
