import React, { createContext, useCallback, useContext } from 'react';

import { Role } from '@spiry-capital/modules';

import api from 'Services/api';
import { useErrors } from './errors';

interface IRolesContext {
  indexRoles(): Promise<Role[] | null>;
}

const RolesContext = createContext<IRolesContext>({} as IRolesContext);

export const RolesProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const indexRoles = useCallback(async (): Promise<Role[] | null> => {
    try {
      const response = await api.get('/roles');
      return response.data as Role[];
    } catch (err) {
      handleErrors('Error when trying to index roles', err);
      return null;
    }
  }, [handleErrors]);

  return (
    <RolesContext.Provider value={{ indexRoles }}>
      {children}
    </RolesContext.Provider>
  );
};

export const useRoles = (): IRolesContext => {
  const context = useContext(RolesContext);
  if (!context) {
    throw new Error('useRoles must be used within RolesProvider');
  }
  return context;
};

export default RolesProvider;
