import React, { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import { IPaginatedMarketplaceAccounts } from 'Hooks/marketplaces';
import IMarketplace from 'Types/Entities/IMarketplace';
import BotSettingsForm from '../BotSettingsForm';
import BotSettingsFormWrapper from '../BotSettingsFormWrapper';
import MarketplaceAccountsBotSettingsTable from '../MarketplaceAccountsBotSettingsTable';

interface IProps {
  marketplace?: IMarketplace;
}

const BotSettingsTab: React.FC<IProps> = ({ marketplace }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  const [marketplaceAccounts, setMarketplaceAccounts] =
    useState<IPaginatedMarketplaceAccounts>(
      {} as IPaginatedMarketplaceAccounts,
    );

  return (
    <>
      {marketplace ? (
        <>
          <BotSettingsFormWrapper isOpen={isOpen} onClose={onClose} />
          <MarketplaceAccountsBotSettingsTable
            key={marketplace.id}
            onOpenForm={onOpen}
            marketplace={marketplace}
            loadingTable={loadingTable}
            setLoadingTable={setLoadingTable}
            marketplaceAccounts={marketplaceAccounts}
            setMarketplaceAccounts={setMarketplaceAccounts}
          />
        </>
      ) : (
        <BotSettingsForm />
      )}
    </>
  );
};

export default BotSettingsTab;
