import { Box, Flex, useColorModeValue, BoxProps } from '@chakra-ui/react';
import * as React from 'react';
import { SkewBox } from 'Components/Molecules/SkewBox';
import { StepContent } from 'Components/Molecules/StepContent';
import ISetState from 'Types/Standards/ISetState';

interface StepProps extends BoxProps {
  isCurrent?: boolean;
  children: React.ReactNode;
  step: number;
  setStep: ISetState<number>;
}

export const Step: React.FC<StepProps> = (props: StepProps) => {
  const { children, isCurrent, step, setStep } = props;
  const color = useColorModeValue('white', 'gray.900');
  return (
    <Box as="li" flex="1" onClick={() => setStep(step)}>
      <Box as="button" outline={0} className="group" width="full">
        <Flex
          align="center"
          height="12"
          justify="center"
          position="relative"
          css={{ '--arrow-skew': '20deg' }}
        >
          <SkewBox isCurrent={isCurrent} placement="top" />
          <StepContent color={isCurrent ? color : 'inherit'}>
            {children}
          </StepContent>
          <SkewBox isCurrent={isCurrent} placement="bottom" />
        </Flex>
      </Box>
    </Box>
  );
};
