import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  Alert,
  AlertIcon,
  Box,
  Divider,
  Heading,
  Stack,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import FormGroup from 'Components/Molecules/FormGroup';
import InputGroup from 'Components/Molecules/InputGroup';
import { useGiftCards } from 'Hooks/giftCards';
import { useChat } from 'Hooks/chats';
import AttachmentsModal from '../AttachmentsModal';

interface IProps {
  hash: string;
}

interface IResponse {
  status: 'success' | 'error';
  message: string;
}

const PrepaidCardProcessingForm: React.FC<IProps> = ({ hash }) => {
  const formRef = useRef<FormHandles>(null);
  const { processCard, checkBalance, findDuplicate } = useGiftCards();
  const { chat, getChat } = useChat();

  const { messages } = chat;

  const [loadingProcess, setLoadingProcess] = useState(false);
  const [hasDuplicate, setHasDuplicate] = useState(false);
  const [response, setResponse] = useState<IResponse>();

  const currencyOptions = useMemo<ISelectOption[]>(
    () => [
      {
        label: 'US Dollar (USD)',
        value: 'usd',
      },
      {
        label: 'Canadian Dollar (CAD)',
        value: 'cad',
      },
    ],
    [],
  );

  const handleCheckBalance = useCallback(() => {
    const cardNumber = formRef.current?.getFieldValue('card_number');
    if (!cardNumber) {
      return;
    }
    checkBalance(cardNumber);
  }, [checkBalance]);

  const handleProcessCard = useCallback(
    async data => {
      setLoadingProcess(true);
      try {
        await processCard({
          data,
          formRef,
          ignoreToast: true,
        });
        setResponse({ status: 'success', message: 'Successfully charged' });
      } catch (error: any) {
        const message = error.response?.data?.error || error.message;
        setResponse({ status: 'error', message });
      }
      setLoadingProcess(false);
    },
    [processCard],
  );

  const handleCheckDuplicateCard = useCallback(
    async (cardNumber: string) => {
      setHasDuplicate(false);
      if (!cardNumber || cardNumber.length !== 16) {
        return;
      }

      const card = await findDuplicate(cardNumber);
      if (card) {
        setHasDuplicate(true);
      }
    },
    [findDuplicate],
  );

  useEffect(() => {
    if (hash)
      (async () => {
        await Promise.all([
          getChat({
            hash,
          }),
        ]);
      })();
  }, [getChat, hash]);

  const handleChatMessagesChanges = useCallback(() => {
    if (!messages) {
      return;
    }

    const currentData = formRef.current?.getData() || {};
    const suggestions: { [key: string]: string } = {};

    const cardNumberRegex =
      /([0-9]{16}|[0-9]{4}\s[0-9]{4}\s[0-9]{4}\s[0-9]{4})/g;
    const expMonthRegex = /([0-9]{2})\/([0-9]{2})/g;
    const cvvRegex = /(?<![0-9])([0-9]{3,4})(?![0-9])/g;

    for (const message of messages.filter(x => x.type === 'msg')) {
      const cardNumberResult = cardNumberRegex.exec(message.text);
      if (cardNumberResult) {
        [, suggestions.card_number] = cardNumberResult;
      }

      const expMonthResult = expMonthRegex.exec(message.text);
      if (expMonthResult) {
        [, suggestions.exp_month, suggestions.exp_year] = expMonthResult;
      }

      const cvvResult = cvvRegex.exec(message.text);
      if (cvvResult) {
        [, suggestions.cvv] = cvvResult;
      }
    }

    if (Object.keys(suggestions).length > 0) {
      for (const key in suggestions) {
        if (Object.prototype.hasOwnProperty.call(suggestions, key)) {
          if (!currentData[key]) {
            currentData[key] = suggestions[key];
          }
        }
      }
      formRef.current?.setData(currentData);
    }
  }, [messages]);

  useEffect(() => {
    handleChatMessagesChanges();
  }, [handleChatMessagesChanges]);

  return (
    <Box>
      {hash && <AttachmentsModal hash={hash} />}
      <Form ref={formRef} onSubmit={handleProcessCard} spacing="40px">
        <FormGroup spacing="10px" title="Card details">
          <InputGroup spacing="10px">
            <Input
              type="text"
              name="card_number"
              label="Card number"
              placeholder="XXXX XXXX XXXX XXXX"
              onChange={e => handleCheckDuplicateCard(e.target.value)}
              helper={
                hasDuplicate ? 'This card has already been processed!' : ''
              }
              helperColor="red.500"
            />
            <Button onClick={handleCheckBalance} isPrimary>
              Check balance
            </Button>
          </InputGroup>
          <InputGroup spacing="10px">
            <Input
              type="number"
              name="exp_month"
              label="Expiration date"
              placeholder="Month"
            />
            <Input type="number" name="exp_year" placeholder="Year" />
          </InputGroup>
          <Input type="text" name="cvv" label="CVV" placeholder="XXX" />
        </FormGroup>

        <FormGroup spacing="10px" title="Customer details">
          <Input
            type="text"
            name="first_name"
            label="Fist name"
            placeholder="John"
          />
          <Input
            type="text"
            name="last_name"
            label="Last name"
            placeholder="Doe"
          />
          <Input
            type="email"
            name="email"
            label="Email"
            placeholder="john_doe@example.com"
          />
        </FormGroup>

        <FormGroup spacing="10px" title="Trade details">
          <Select
            name="currency"
            label="Currency"
            placeholder="Select a currency"
            options={currencyOptions}
          />
          <Input
            type="number"
            name="amount"
            label="Amount"
            placeholder="00.00"
          />
        </FormGroup>

        <Stack spacing={5}>
          <Box>
            <Button type="submit" isLoading={loadingProcess} isPrimary>
              Process
            </Button>
          </Box>
          <Divider />
          <Box>
            <Heading mb={5} size="md">
              Response
            </Heading>
            {response ? (
              <Alert status={response.status}>
                <AlertIcon />
                {response.message}
              </Alert>
            ) : (
              <Alert status="info">
                <AlertIcon />
                Nothing to see here
              </Alert>
            )}
          </Box>
        </Stack>
      </Form>
    </Box>
  );
};

export default PrepaidCardProcessingForm;
