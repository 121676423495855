import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import {
  ButtonGroup,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Switch from 'Components/Atoms/Switch';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import ISetState from 'Types/Standards/ISetState';
import ICreateUserPayrollSettingData from 'Types/DTOs/ICreateUserPayrollSettingData';
import { useUsers } from 'Hooks/users';
import { usePayrolls } from 'Hooks/payrolls';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
}

const UserPayrollSettingsForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingUsers, setLoadingUsers] = useState(true);
  const [loadingForm, setLoadingForm] = useState(false);
  const [loadingTrigger, setLoadingTrigger] = useState(false);

  const { allUsers, indexAllUsers } = useUsers();
  const { triggerAllCustomPayrolls } = usePayrolls();

  const {
    currentUserPayrollSetting,
    setCurrentUserPayrollSetting,
    createUserPayrollSetting,
    indexUserPayrollSettings,
    updateUserPayrollSetting,
  } = usePayrolls();

  const isEdit = useMemo(
    () => !!currentUserPayrollSetting,
    [currentUserPayrollSetting],
  );
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} user payroll setting`,
    [isEdit],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);
  const userOptions = useMemo<ISelectOption[]>(
    () =>
      allUsers.map(user => ({
        label: user.username,
        value: user.id,
      })),
    [allUsers],
  );
  const frequencyOptions = useMemo<ISelectOption[]>(
    () => [
      {
        label: 'Monthly',
        value: 'monthly',
      },
      {
        label: 'Weekly',
        value: 'weekly',
      },
    ],
    [],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentUserPayrollSetting(null);
  }, [onClose, setCurrentUserPayrollSetting]);

  const submitForm = useCallback(
    async (data: ICreateUserPayrollSettingData) => {
      setLoadingForm(true);
      if (isEdit && currentUserPayrollSetting) {
        await updateUserPayrollSetting({
          data: {
            id: currentUserPayrollSetting.id,
            ...data,
          },
          formRef,
          onClose,
        });
      } else {
        await createUserPayrollSetting({
          data,
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);

      setLoadingTable(true);
      await indexUserPayrollSettings({
        data: {
          page: 1,
        },
      });
      setLoadingTable(false);
    },
    [
      isEdit,
      onClose,
      setLoadingTable,
      indexUserPayrollSettings,
      createUserPayrollSetting,
      currentUserPayrollSetting,
      updateUserPayrollSetting,
    ],
  );

  const handletriggerAllCustomPayrolls = useCallback(async () => {
    setLoadingTrigger(true);
    await triggerAllCustomPayrolls();
    setLoadingTrigger(false);
  }, [triggerAllCustomPayrolls]);

  useEffect(() => {
    setLoadingUsers(true);
    indexAllUsers().finally(() => {
      setLoadingUsers(false);
    });
  }, [indexAllUsers]);

  return (
    <Card title="Actions">
      <ButtonGroup>
        <Button type="button" onClick={onOpen} isPrimary>
          Create fixed user payroll setting
        </Button>
        <Button
          isPrimary
          isLoading={loadingTrigger}
          onClick={handletriggerAllCustomPayrolls}
          confirmation={{
            body: 'By clicking this button, all users below with active status will be paid. Are you sure you want to do this?',
            buttonText: 'Yes. I am sure.',
            colorScheme: 'red',
          }}
        >
          Trigger payrolls
        </Button>
      </ButtonGroup>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentUserPayrollSetting || {}}
            >
              <Select
                name="user_id"
                label="User"
                placeholder="Select a user"
                isLoading={loadingUsers}
                options={userOptions}
              />
              <Select
                name="frequency"
                label="Frequency"
                placeholder="Select a frequency"
                options={frequencyOptions}
              />
              <Input
                placeholder="0.00"
                label="USD Amount"
                name="amount_usd"
                type="number"
              />
              <Switch label="Active" name="active" size="md" />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default UserPayrollSettingsForm;
