import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

import background from 'Assets/Images/background.png';

export const Container = styled(Box)`
  background-image: url(${background});
  background-size: cover;
  background-repeat: repeat;
  background-position: center center;
  height: 100%;
  flex: 1;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
