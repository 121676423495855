import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { useChat } from 'Hooks/chats';
import { useTrades } from 'Hooks/trades';

import Button from 'Components/Atoms/Button';
import Input from 'Components/Atoms/Input';
import Form from 'Components/Atoms/Form';
import { FormHandles } from '@unform/core';
import IStartDispute from 'Types/DTOs/IStartDispute';
import IUploadAttachments from 'Types/DTOs/IUploadAttachments';
import Select, { ISelectOption } from 'Components/Atoms/Select';

interface IProps {
  hash: string;
  whichModal: string;
  isOpen: boolean;
  onClose: () => void;
}

const TradeActionsModal: React.FC<IProps> = ({
  hash,
  whichModal,
  isOpen,
  onClose,
}) => {
  const [loading, setLoading] = useState(false);

  const uploadFormRef = useRef<FormHandles>(null);
  const disputeFormRef = useRef<FormHandles>(null);

  const reasonsOptions = useMemo<ISelectOption[]>(
    () => [
      { label: 'Coin locker', value: 'vendor_coinlocker' },
      { label: 'Payment issue', value: 'vendor_payment_issue' },
      { label: 'Other', value: 'vendor_other' },
    ],
    [],
  );

  const { sendAttachments } = useChat();
  const { startDispute, releaseBTC } = useTrades();

  const handleUpload = useCallback(
    async (data: IUploadAttachments) => {
      setLoading(true);
      await sendAttachments({
        hash,
        imageUrl: data.image_url,
      });
      setLoading(false);
    },
    [hash, sendAttachments],
  );

  const handleDispute = useCallback(
    async (data: IStartDispute) => {
      setLoading(true);
      await startDispute({
        hash,
        reason: data.reason,
        reasonType: data.reason_type,
      });
      setLoading(false);
    },
    [hash, startDispute],
  );

  const handleRelease = useCallback(async () => {
    setLoading(true);
    await releaseBTC({
      hash,
    });
    setLoading(false);
  }, [hash, releaseBTC]);

  const title = useMemo(() => {
    switch (whichModal) {
      case 'upload':
        return 'Upload Image';
      case 'dispute':
        return 'Start dispute';
      case 'release':
        return 'Release Bitcoin';
      default:
        return '';
    }
  }, [whichModal]);

  const body = useMemo(() => {
    switch (whichModal) {
      case 'upload':
        return (
          <Form onSubmit={handleUpload} ref={uploadFormRef}>
            <Input name="image_url" type="text" label="Image URL:" />
          </Form>
        );
      case 'dispute':
        return (
          <Form onSubmit={handleDispute} ref={disputeFormRef}>
            <Select
              placeholder="Select the reason"
              label="Reason"
              name="reason_type"
              options={reasonsOptions}
            />
            <Input name="reason" type="text" label="Description" />
          </Form>
        );
      case 'release':
        return <Box>Are you sure you want to release bitcoins? </Box>;
      default:
        return '';
    }
  }, [handleDispute, handleUpload, reasonsOptions, whichModal]);

  const footer = useMemo(() => {
    switch (whichModal) {
      case 'upload':
        return (
          <>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              variant="outline"
            >
              Close
            </Button>
            <Button
              isPrimary
              onClick={() => uploadFormRef.current?.submitForm()}
              isLoading={loading}
            >
              Upload
            </Button>
          </>
        );
      case 'dispute':
        return (
          <>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              variant="outline"
            >
              Close
            </Button>
            <Button
              onClick={() => disputeFormRef.current?.submitForm()}
              isLoading={loading}
              colorScheme="red"
            >
              Start dispute
            </Button>
          </>
        );
      case 'release':
        return (
          <>
            <Button
              colorScheme="blue"
              mr={3}
              onClick={onClose}
              variant="outline"
            >
              Close
            </Button>
            <Button isPrimary onClick={handleRelease} isLoading={loading}>
              Release
            </Button>
          </>
        );
      default:
        return '';
    }
  }, [handleRelease, onClose, loading, whichModal]);

  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{title}</ModalHeader>
          <ModalCloseButton mr={2} mt={2} />

          <ModalBody>{body}</ModalBody>

          <ModalFooter>{footer}</ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TradeActionsModal;
