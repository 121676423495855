import React, { useCallback } from 'react';
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';

import { useMarketplaces } from 'Hooks/marketplaces';
import BotSettingsForm from '../BotSettingsForm';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const BotSettingsFormWrapper: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { currentMarketplaceAccount, setCurrentMarketplaceAccount } =
    useMarketplaces();

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentMarketplaceAccount(null);
  }, [onClose, setCurrentMarketplaceAccount]);

  return (
    <Box mb="20px">
      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">
            {currentMarketplaceAccount?.username} Settings
          </DrawerHeader>

          <DrawerBody>
            <BotSettingsForm onClose={onClose} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Box>
  );
};

export default BotSettingsFormWrapper;
