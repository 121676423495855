import React from 'react';
import { FaFire } from 'react-icons/fa';

import Card from 'Components/Atoms/Card';
import DashboardHeatmap from 'Components/Atoms/DashboardHeatmap';

interface IProps {
  marketplaceId: string;
  vendorId?: string;
}

const DashboardActivityHeatmap: React.FC<IProps> = ({
  marketplaceId,
  vendorId,
}) => {
  return (
    <Card title="Activity heatmap" icon={FaFire}>
      <DashboardHeatmap marketplaceId={marketplaceId} vendorId={vendorId} />
    </Card>
  );
};

export default DashboardActivityHeatmap;
