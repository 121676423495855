import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ButtonGroup, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import IObject from 'Types/Standards/IObject';
import { useSettings } from 'Hooks/settings';
import IMarketplace from 'Types/Entities/IMarketplace';

interface IAvailableSetting {
  name: string;
  label: string;
  type: 'text' | 'number';
}
interface IProps {
  marketplace: IMarketplace;
}

const ChargeVendorsSettingsForm: React.FC<IProps> = ({ marketplace }) => {
  const formRef = useRef<FormHandles>(null);

  const { indexSelectedGlobalSettings, upsertGlobalSettings } = useSettings();

  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [formData, setFormData] = useState<IObject>({} as IObject);

  const availableSettings = useMemo<IAvailableSetting[]>(() => {
    if (marketplace && marketplace.name === 'paxful') {
      return [
        {
          name: 'charge_pax_min_usd',
          label: 'Min USD amount to charge',
          type: 'number',
        },
        {
          name: 'charge_pax_percentage',
          label: 'Volume percentage to charge',
          type: 'number',
        },
        {
          name: 'charge_pax_orion_address',
          label: 'Address to receive billings',
          type: 'text',
        },
      ];
    }

    return [
      {
        name: 'charge_knz_min_usd',
        label: 'Min USD amount to charge',
        type: 'number',
      },
      {
        name: 'charge_knz_percentage',
        label: 'Volume percentage to charge',
        type: 'number',
      },
      {
        name: 'charge_knz_orion_address',
        label: 'Address to receive billings',
        type: 'text',
      },
    ];
  }, [marketplace]);

  const inputs = useMemo(
    () =>
      availableSettings.map(setting => {
        return (
          <Input
            key={setting.name}
            name={setting.name}
            label={setting.label}
            type="text"
            placeholder={setting.type === 'number' ? '00.00' : ''}
          />
        );
      }),
    [availableSettings],
  );

  const getSettings = useCallback(async () => {
    const names = availableSettings.map(setting => setting.name);
    setLoadingForm(true);
    const data = await indexSelectedGlobalSettings<IObject>({
      data: {
        names,
        marketplace_account_id: marketplace?.id,
      },
    });
    setFormData(data);
    setLoadingForm(false);
  }, [
    indexSelectedGlobalSettings,
    setLoadingForm,
    marketplace,
    availableSettings,
  ]);

  const handleUpsertSettings = useCallback(
    async (data: IObject) => {
      setLoadingUpsert(true);
      Object.assign(data, {
        marketplace_account_id: marketplace?.id,
      });
      await upsertGlobalSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      getSettings();
    },
    [upsertGlobalSettings, formRef, marketplace, getSettings],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Skeleton isLoaded={!loadingForm}>
      <Form
        ref={formRef}
        onSubmit={handleUpsertSettings}
        spacing="20px"
        initialData={formData}
      >
        {inputs.map(input => input)}
        <ButtonGroup>
          <Button
            type="button"
            onClick={handleSubmitForm}
            isLoading={loadingUpsert}
            isPrimary
          >
            Save
          </Button>
        </ButtonGroup>
      </Form>
    </Skeleton>
  );
};

export default ChargeVendorsSettingsForm;
