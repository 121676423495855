import React, { useMemo } from 'react';
import {
  RouteProps as ReactDOMRouteProps,
  Route as ReactDOMRoute,
  Redirect,
} from 'react-router-dom';

import { useAuth } from 'Hooks/auth';
import localStorageConfig from 'Config/localStorage';
import userHasRole from 'Helpers/userHasRole';
import { User } from '@spiry-capital/modules';
import IRoleLevels from 'Types/Enums/IRoleLevels';

interface RouteProps extends ReactDOMRouteProps {
  isPrivate?: boolean;
  component: React.ComponentType;
  roleLevel?: IRoleLevels;
}

const Route: React.FC<RouteProps> = ({
  isPrivate = false,
  component: Component,
  roleLevel,
  ...rest
}) => {
  const { user: storeUser } = useAuth();

  const user = useMemo<User | null>(
    () => {
      const localUser = localStorage.getItem(localStorageConfig.userKey);
      if (!localUser) {
        return null;
      }
      return JSON.parse(localUser);
    },
    // eslint-disable-next-line
    [storeUser],
  );

  const hasAccess = useMemo(
    () => isPrivate === !!user && userHasRole(user, roleLevel),
    [isPrivate, user, roleLevel],
  );

  const redirectRoute = useMemo(() => {
    switch (user?.role?.level) {
      case 1:
        return '/prepaid-card-processing';
      case 4:
        return '/dashboard/paxful';
      case 5:
        return '/jobs';
      default:
        return '/trades';
    }
  }, [user?.role?.level]);

  return (
    <ReactDOMRoute
      {...rest}
      render={({ location }) => {
        return hasAccess ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: isPrivate ? '/' : redirectRoute,
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

export default Route;
