import React from 'react';
import {
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as ChakraTabs,
} from '@chakra-ui/react';

import { useColor } from 'Hooks/color';

export interface ITab {
  title: string;
  component: JSX.Element;
}

interface IProps {
  tabs: ITab[];
}

const Tabs: React.FC<IProps> = ({ tabs }) => {
  const color = useColor();

  return (
    <ChakraTabs variant="enclosed" colorScheme={color}>
      <TabList mb="1em">
        {tabs.map((tab, i) => (
          <Tab textTransform="capitalize" key={i}>
            {tab.title}
          </Tab>
        ))}
      </TabList>
      <TabPanels>
        {tabs.map((tab, i) => (
          <TabPanel key={i}>{tab.component}</TabPanel>
        ))}
      </TabPanels>
    </ChakraTabs>
  );
};

export default Tabs;
