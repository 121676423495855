import React, { useEffect, useMemo } from 'react';

import Card from 'Components/Atoms/Card';
import Tabs, { ITab } from 'Components/Molecules/Tabs';
import { useMarketplaces } from 'Hooks/marketplaces';
import ReplenishmentSettings from '../ReplenishmentSettings';

const FundsSettingsReplenishment: React.FC = () => {
  const { indexMarketplaces, marketplaces } = useMarketplaces();

  useEffect(() => {
    indexMarketplaces();
  }, [indexMarketplaces]);

  const tabs = useMemo<ITab[]>(() => {
    return marketplaces.map(marketplace => ({
      title: marketplace.name,
      component: <ReplenishmentSettings marketplace={marketplace} />,
    }));
  }, [marketplaces]);

  return (
    <Card title="Replenishment settings">
      <Tabs tabs={tabs} />
    </Card>
  );
};

export default FundsSettingsReplenishment;
