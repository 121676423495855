import React, { useMemo } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Stack,
  StackDirection,
  useBreakpointValue,
} from '@chakra-ui/react';

import GlasswireCard from 'Components/Molecules/GlasswireCard';
import { useReports } from 'Hooks/reports';

interface ICard {
  payment_method_slug: string;
  payment_method: string;
  minimum: number;
  maximum: number;
  average: number;
}

interface IProps {
  marketplaceId: string;
}

const GlasswireCards: React.FC<IProps> = ({ marketplaceId }) => {
  const { getReport } = useReports();

  const direction = useBreakpointValue<StackDirection>({
    base: 'column',
    md: 'row',
  });

  const stats = useMemo(
    () => getReport({ name: 'public_reports', marketplace_id: marketplaceId }),
    [getReport, marketplaceId],
  );

  const cards = useMemo<ICard[]>(() => {
    if (!stats) return [];

    return stats.map((stat: ICard) => stat);
  }, [stats]);

  return (
    <Stack
      direction={direction}
      mb={cards.length > 0 ? '25px' : '0px'}
      display="flex"
      wrap="wrap"
      gridGap={5}
      spacing={0}
    >
      {cards.length > 0 ? (
        cards.map((card, i) => (
          <GlasswireCard
            key={i}
            title={card.payment_method}
            avgMargin={card.average}
            maxMargin={card.maximum}
          />
        ))
      ) : (
        <Alert>
          <AlertIcon />
          <AlertDescription>No data found!</AlertDescription>
        </Alert>
      )}
    </Stack>
  );
};

export default GlasswireCards;
