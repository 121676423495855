import * as Yup from 'yup';

const updateOfferSchema = Yup.object().shape({
  margin: Yup.number()
    .typeError('margin must be number')
    .required('margin is required'),
  fiat_amount_range_min: Yup.number()
    .typeError('min fiat amount must be number')
    .required('min fiat amount is required'),
  fiat_amount_range_max: Yup.number()
    .typeError('max fiat amount must be number')
    .required('max fiat amount is required'),
  terms: Yup.string().required('terms is required'),
  payment_method_name: Yup.string(),
  payment_method_slug: Yup.string().required('payment method slug is required'),
  fiat_currency_code: Yup.string().required('fiat currency code is required'),
  details: Yup.string().required('trade details is required'),
  fixed_price: Yup.number().typeError('fixed price must be number'),
  is_fixed_price: Yup.boolean().typeError('is_fixed_price must be boolean'),
  require_min_past_trades: Yup.number().typeError(
    'min trades required must be number',
  ),
  is_only_for_trusted_user: Yup.boolean().typeError(
    'is_only_for_trusted_user must be boolean',
  ),
  is_country_limitated: Yup.boolean().typeError(
    'is_country_limitated must be boolean',
  ),
  limitated_countries: Yup.array(),
  tags: Yup.array().required('tags is required'),
  payment_window: Yup.number()
    .typeError('payment window must be number')
    .required('payment window is required'),
  payment_method_group: Yup.string(),
  payment_method_country_name: Yup.string(),
  predefined_amounts: Yup.array(),
});

export default updateOfferSchema;
