import React, { useCallback, useMemo, useRef, useState } from 'react';
import {
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import ISetState from 'Types/Standards/ISetState';
import ICreateMarketplaceData from 'Types/DTOs/ICreateMarketplaceData';
import { useMarketplaces } from 'Hooks/marketplaces';

interface IProps {
  isOpen: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpen(): void;
  onClose(): void;
}

const MarketplacesForm: React.FC<IProps> = ({
  isOpen,
  onClose,
  onOpen,
  setLoadingTable,
}) => {
  const formRef = useRef<FormHandles>(null);

  const [loadingForm, setLoadingForm] = useState(false);

  const {
    createMarketplace,
    currentMarketplace,
    indexMarketplaces,
    setCurrentMarketplace,
    updateMarketplace,
  } = useMarketplaces();

  const isEdit = useMemo(() => !!currentMarketplace, [currentMarketplace]);
  const title = useMemo(
    () => `${isEdit ? 'Edit existing' : 'Create new'} marketplace`,
    [isEdit],
  );
  const buttonText = useMemo(() => (isEdit ? 'Save' : 'Create'), [isEdit]);

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, [formRef]);

  const handleCancel = useCallback(() => {
    onClose();
    setCurrentMarketplace(null);
  }, [onClose, setCurrentMarketplace]);

  const submitForm = useCallback(
    async (data: ICreateMarketplaceData) => {
      setLoadingForm(true);
      if (isEdit && currentMarketplace) {
        await updateMarketplace({
          data: {
            id: currentMarketplace.id,
            ...data,
          },
          formRef,
          onClose,
        });
      } else {
        await createMarketplace({
          data,
          onClose,
          formRef,
        });
      }
      setLoadingForm(false);

      setLoadingTable(true);
      await indexMarketplaces();
      setLoadingTable(false);
    },
    [
      isEdit,
      onClose,
      setLoadingTable,
      createMarketplace,
      currentMarketplace,
      indexMarketplaces,
      updateMarketplace,
    ],
  );

  return (
    <Card title="Actions">
      <Button type="button" onClick={onOpen} isPrimary>
        Create marketplace
      </Button>

      <Drawer isOpen={isOpen} placement="right" onClose={handleCancel}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="1px">{title}</DrawerHeader>

          <DrawerBody>
            <Form
              ref={formRef}
              onSubmit={submitForm}
              spacing="10px"
              initialData={currentMarketplace || {}}
            >
              <Input
                placeholder="Marketplace"
                label="Name"
                name="name"
                type="text"
              />
            </Form>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button
              type="button"
              variant="outline"
              mr={3}
              onClick={handleCancel}
            >
              Cancel
            </Button>
            <Button
              type="button"
              onClick={handleSubmitForm}
              isLoading={loadingForm}
              isPrimary
            >
              {buttonText}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </Card>
  );
};

export default MarketplacesForm;
