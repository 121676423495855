import React, { useMemo } from 'react';

import Card from 'Components/Atoms/Card';
import { useTrades } from 'Hooks/trades';
import Tabs from 'Components/Molecules/Tabs';
import ActiveTradesTable from '../ActiveTradesTable';

const ActiveTrades: React.FC = () => {
  const {
    activeTrades,
    disputedTrades,
    indexActiveTrades,
    indexDisputedTrades,
    recentModerated,
  } = useTrades();

  const tabs = useMemo(
    () => [
      {
        component: (
          <ActiveTradesTable
            indexActiveTrades={indexActiveTrades}
            activeTrades={activeTrades}
            recentModerated={recentModerated}
          />
        ),
        title: 'Active',
      },
      {
        component: (
          <ActiveTradesTable
            indexActiveTrades={indexDisputedTrades}
            activeTrades={disputedTrades}
            recentModerated={recentModerated}
          />
        ),
        title: 'Dispute',
      },
    ],
    [
      activeTrades,
      disputedTrades,
      indexActiveTrades,
      indexDisputedTrades,
      recentModerated,
    ],
  );

  return (
    <Card title="Active Trades">
      <Tabs tabs={tabs} />
    </Card>
  );
};

export default ActiveTrades;
