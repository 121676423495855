import React, { useMemo } from 'react';
import { ButtonProps, Icon } from '@chakra-ui/react';
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import PaginationButton from '../PaginationButton';

interface IProps extends ButtonProps {
  direction: 'right' | 'left';
}

const PaginationControlButton: React.FC<IProps> = ({ direction, ...rest }) => {
  const icon = useMemo(
    () => (direction === 'right' ? FiChevronRight : FiChevronLeft),
    [direction],
  );
  const marginRight = useMemo(
    () => (direction === 'right' ? '0' : '10px'),
    [direction],
  );
  const marginLeft = useMemo(
    () => (direction === 'right' ? '10px' : '0'),
    [direction],
  );

  return (
    <PaginationButton mr={marginRight} ml={marginLeft} {...rest}>
      <Icon as={icon} />
    </PaginationButton>
  );
};

export default PaginationControlButton;
