import React, { useEffect, useMemo, useRef } from 'react';
import { useField } from '@unform/core';
import ReactSelect, {
  Props as SelectProps,
  Styles,
  GroupTypeBase,
  OptionsType,
} from 'react-select';
import {
  Box,
  FormControl,
  FormLabel,
  InputGroup,
  InputRightElement,
  Skeleton,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';

import { useColor } from 'Hooks/color';
import InputError from '../InputError';

export interface ISelectOption {
  label: string;
  value: string;
}

type IStyles = Partial<
  Styles<ISelectOption, false, GroupTypeBase<ISelectOption>>
>;

interface IProps extends Omit<SelectProps, 'defaultValue'> {
  name: string;
  options: ISelectOption[];
  label?: string;
  isLoading?: boolean;
  defaultValue?: string | string[];
  width?: string;
  isClearable?: boolean;
  placeholder?: string;
  isMulti?: boolean;
  onChange?: (v: ISelectOption | OptionsType<ISelectOption> | null) => void;
}

const Select: React.FC<IProps> = ({
  name,
  label,
  isLoading,
  defaultValue: directDefaultValue,
  width,
  isClearable,
  placeholder,
  onChange,
  ...rest
}) => {
  const {
    defaultValue: unformDefaultValue,
    error,
    fieldName,
    registerField,
  } = useField(name);

  const theme = useTheme();
  const color = useColor();
  const backgroundColor = useColorModeValue('white', '#2D3748');
  const multiBackgroundColor = useColorModeValue('#edf2f7', '#4e5765');
  const textColor = useColorModeValue('black', 'white');

  const selectRef = useRef(null);

  const defaultValue = useMemo<ISelectOption | ISelectOption[] | null>(() => {
    if (rest.isMulti && directDefaultValue?.length) {
      return rest.options.filter(o => directDefaultValue.includes(o.value));
    }
    if (rest.isMulti && unformDefaultValue?.length) {
      return rest.options.filter(o => unformDefaultValue.includes(o.value));
    }

    let option = rest.options?.find(
      (o: ISelectOption) => o.value === directDefaultValue,
    );
    if (!option) {
      option = rest.options?.find(
        (o: ISelectOption) => o.value === unformDefaultValue,
      );
    }
    return option || null;
  }, [directDefaultValue, unformDefaultValue, rest.options, rest.isMulti]);

  const styles = useMemo<IStyles>(
    () => ({
      control: props => ({
        ...props,
        backgroundColor: 'inherit',
        color: textColor,
        minHeight: '40px',
        border: '1px solid #4e5765',
        borderRadius: '0.375rem',
        width,
      }),
      menu: props => ({ ...props, backgroundColor, zIndex: 100 }),
      option: (props, { isFocused }) => ({
        ...props,
        backgroundColor: isFocused ? theme.colors[color]['300'] : 'inherit',
        color: textColor,
      }),
      input: props => ({ ...props, color: textColor }),
      singleValue: props => ({ ...props, color: textColor }),
      multiValue: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
      }),
      multiValueLabel: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
        color: textColor,
      }),
      multiValueRemove: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
        color: textColor,
      }),
    }),
    [
      textColor,
      width,
      backgroundColor,
      theme.colors,
      color,
      multiBackgroundColor,
    ],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref: any) => {
        if (!ref.state.value) {
          return rest.isMulti ? [] : '';
        }
        return rest.isMulti
          ? ref.state.value.map((option: ISelectOption) => option.value)
          : ref.state.value.value;
      },
    });
  }, [registerField, fieldName, rest.options, rest.isMulti]);

  return (
    <Skeleton isLoaded={!isLoading} w="100%">
      <FormControl>
        {label && <FormLabel mb="5px">{label}</FormLabel>}

        <InputGroup>
          <Box w="100%">
            <ReactSelect
              name={name}
              ref={selectRef}
              colorScheme={color}
              classNamePrefix="react-select"
              styles={styles}
              defaultValue={defaultValue}
              isClearable={isClearable}
              placeholder={placeholder}
              isSearchable
              onChange={v =>
                onChange ? setTimeout(() => onChange(v), 100) : null
              }
              {...rest}
            />
          </Box>

          {error && (
            <InputRightElement mr="25px">
              <InputError error={error} />
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    </Skeleton>
  );
};

export default Select;
