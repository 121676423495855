import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import ILogin from 'Types/DTOs/ILogin';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import api from 'Services/api';
import loginSchema from 'Schemas/login';
import localStorageConfig from 'Config/localStorage';
import { User } from '@spiry-capital/modules';
import { useErrors } from './errors';

interface IAuthContext {
  user: User | null;
  login(data: IDefaultRequest<ILogin>): Promise<void>;
  logout(): Promise<void>;
  updateUser(): Promise<void>;
}

const AuthContext = createContext<IAuthContext>({} as IAuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  const [user, setUser] = useState<User | null>(null);
  const { handleErrors } = useErrors();
  const history = useHistory();

  const login = useCallback(
    async ({ data, formRef }: IDefaultRequest<ILogin>) => {
      try {
        formRef.current?.setErrors({});
        await loginSchema.validate(data, {
          abortEarly: false,
        });

        const response = await api.post('/users/login', data);

        localStorage.setItem(
          localStorageConfig.userKey,
          JSON.stringify(response.data.user),
        );
        localStorage.setItem(localStorageConfig.tokenKey, response.data.token);
        setUser(response.data);

        history.push('/');
      } catch (err) {
        handleErrors('Error when trying to login', err, formRef);
      }
    },
    [handleErrors, history],
  );

  const logout = useCallback(async () => {
    try {
      await api.delete('/users/logoutall');
    } catch {}
    localStorage.removeItem(localStorageConfig.userKey);
    localStorage.removeItem(localStorageConfig.tokenKey);
    setUser(null);
    history.replace('/');
  }, [history]);

  const updateUser = useCallback(async () => {
    try {
      const response = await api.get('/users/me');
      setUser(response.data);
    } catch {}
  }, []);

  useEffect(() => {
    const savedUser = localStorage.getItem(localStorageConfig.userKey);
    if (savedUser) {
      setUser(JSON.parse(savedUser));
    }
    api.get('/users/me').catch(() => {
      logout();
    });
  }, [logout]);

  return (
    <AuthContext.Provider value={{ updateUser, user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }
  return context;
};

export default AuthProvider;
