import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Grid, LinkBox, LinkOverlay, VStack } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { Trade } from '@spiry-capital/modules';
import { FaDownload, FaSearch } from 'react-icons/fa';
import { FormHandles } from '@unform/core';
import { Form } from '@unform/web';

import Card from 'Components/Atoms/Card';
import Table from 'Components/Molecules/Table';
import formatDate from 'Helpers/formatDate';
import Button from 'Components/Atoms/Button';
import { useTrades } from 'Hooks/trades';
import { useColor } from 'Hooks/color';
import Input from 'Components/Atoms/Input';
import Select from 'Components/Atoms/Select';
import { IMultiSelectOption } from 'Components/Atoms/MultiSelect';
import ITrade from 'Types/Entities/ITrade';

import TradeHistoryExportModal from '../TradeHistoryExportModal';
import TradingAttached from '../TradingAttached';

interface ISearchData {
  search?: string;
  status?: string[];
  ended?: string | boolean;
}

const TradesHistoryTable: React.FC = () => {
  const { trades, indexTrades } = useTrades();

  const filterFormRef = useRef<FormHandles>(null);

  const color = useColor();

  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [exportModalActive, setExportModalActive] = useState(false);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
  const [searchData, setSearchData] = useState<ISearchData>({});

  const statusOptions = useMemo<IMultiSelectOption[]>(
    () => [
      {
        label: 'Active',
        value: 'Active',
      },
      {
        label: 'Cancelled',
        value: 'Cancelled',
      },
      {
        label: 'Paid',
        value: 'Paid',
      },
      {
        label: 'Released',
        value: 'Released',
      },
      {
        label: 'Dispute open',
        value: 'Dispute open',
      },
    ],
    [],
  );

  const endedOptions = useMemo<IMultiSelectOption[]>(
    () => [
      { label: 'Ended only', value: 'true' },
      { label: 'Active only', value: 'false' },
    ],
    [],
  );

  const handleSetSearchData = useCallback(() => {
    const data = filterFormRef.current?.getData();

    const filter: ISearchData = {};

    if (data) {
      if (data.status) {
        filter.status = data.status;
      }
      if (data.search) {
        filter.search = data.search;
      }
      if (
        typeof data.ended !== 'undefined' &&
        data.ended !== null &&
        data.ended !== ''
      ) {
        filter.ended = data.ended;
      }
    } else {
      filter.ended = true;
    }

    setSearchData(filter);
  }, []);

  const loadTrades = useCallback(
    async (data: ISearchData, pageNumber: number) => {
      setLoading(true);
      try {
        await indexTrades({
          data: {
            page: pageNumber,
            ...data,
          },
        });
      } finally {
        setLoading(false);
      }
    },
    [indexTrades],
  );

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        loadTrades(searchData, page);
      }, 500),
    );

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTrades, searchData]);

  useEffect(() => {
    loadTrades(searchData, page);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadTrades, page]);

  return (
    <Box>
      <TradeHistoryExportModal
        isOpen={exportModalActive}
        onClose={() => setExportModalActive(false)}
        filterFormRef={filterFormRef}
      />
      <Card title="Actions">
        <Button
          onClick={() => setExportModalActive(true)}
          colorScheme={color}
          as={Button}
          leftIcon={<FaDownload />}
        >
          Export
        </Button>
      </Card>
      <Card title="Trades History">
        <Box mb={5}>
          <Form
            ref={filterFormRef}
            onChange={handleSetSearchData}
            onSubmit={handleSetSearchData}
          >
            <VStack>
              <Input
                type="text"
                name="search"
                placeholder="Search"
                rightAddon={<FaSearch />}
              />
              <Grid w="100%" templateColumns="1fr 1fr" gap={2}>
                <Select
                  name="status"
                  options={statusOptions}
                  placeholder="Filter by status"
                  isMulti
                  isClearable
                  onChange={handleSetSearchData}
                />
                <Select
                  name="ended"
                  options={endedOptions}
                  defaultValue="true"
                  placeholder="Filter by ended"
                  isClearable
                  onChange={handleSetSearchData}
                />
              </Grid>
            </VStack>
          </Form>
        </Box>
        <Table
          isLoading={loading}
          accordion={(row: ITrade) => <TradingAttached tradeHash={row.hash} />}
          accordionKey="id"
          columns={[
            {
              key: 'responder_username',
              title: 'Marketplace',
              render: row => row.marketplace_account.marketplace.name,
            },
            {
              key: 'owner_username',
              title: 'Marketplace account',
              render: row =>
                row.owner_username ? row.owner_username : row.seller,
            },
            {
              key: 'trade_status',
              title: 'Status',
              render: row => row.status,
            },
            {
              key: 'started_at',
              title: 'Started at',
              render: row => formatDate(row.started_at, 'MM/dd/yyyy - HH:mm'),
            },
            {
              key: 'trade_hash',
              title: 'Trade hash',
              render: row => row.hash,
            },
            {
              key: 'fiat_amount_requested',
              title: 'Amount requested',
              render: row => Number(row.fiat_amount_requested).toFixed(2),
            },
            {
              key: 'margin',
              title: 'Margin',
              render: row => row.margin,
            },
            {
              key: 'payment_method_name',
              title: 'Offer',
              render: row => row.payment_method_name,
            },
            {
              key: 'fee_percentage',
              title: 'Fee percentage',
              render: row => row.fee_percentage,
            },
            {
              key: 'actions',
              title: 'Actions',
              render: (row: Trade) => (
                <LinkBox>
                  <LinkOverlay
                    as={RouterLink}
                    to={`/trading/${row.hash}`}
                    isExternal
                  >
                    <Button colorScheme="green" isDanger isPrimary>
                      View details
                    </Button>
                  </LinkOverlay>
                </LinkBox>
              ),
            },
          ]}
          rows={trades.entities}
          pagination={{
            currentPage: page,
            limit: 25,
            setPage,
            total: trades.total,
          }}
        />
      </Card>
    </Box>
  );
};

export default TradesHistoryTable;
