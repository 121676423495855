import React, { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import IMarketplace from 'Types/Entities/IMarketplace';
import { IPaginatedMarketplaceAccounts } from 'Hooks/marketplaces';
import MarketplaceAccountsForm from '../MarketplaceAccountsForm';
import MarketplaceAccountsTable from '../MarketplaceAccountsTable';

interface IProps {
  marketplace: IMarketplace;
}

const MarketplaceAccountsTab: React.FC<IProps> = ({ marketplace }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  const [marketplaceAccounts, setMarketplaceAccounts] =
    useState<IPaginatedMarketplaceAccounts>(
      {} as IPaginatedMarketplaceAccounts,
    );

  return (
    <>
      <MarketplaceAccountsForm
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={onClose}
        marketplace={marketplace}
        setLoadingTable={setLoadingTable}
        setMarketplaceAccounts={setMarketplaceAccounts}
      />
      <MarketplaceAccountsTable
        key={marketplace.id}
        onOpenForm={onOpen}
        marketplace={marketplace}
        loadingTable={loadingTable}
        setLoadingTable={setLoadingTable}
        marketplaceAccounts={marketplaceAccounts}
        setMarketplaceAccounts={setMarketplaceAccounts}
      />
    </>
  );
};

export default MarketplaceAccountsTab;
