import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Socket } from 'socket.io-client';

import setupSockets from 'Services/socketSetup';

interface ISocketsContext {
  on: (event: string, callback: (payload: any) => void) => void;
  off: (event: string) => void;
  connected: boolean;
}

const SocketsContext = createContext<ISocketsContext>({} as ISocketsContext);

export const SocketProvider: React.FC = ({ children }) => {
  const [socket, setSocket] = useState<Socket>();
  const [connected, setConnected] = useState(false);

  useEffect(() => {
    const newSocket = setupSockets();
    newSocket.connect();
    newSocket.on('connection', () => {
      setConnected(true);
    });

    setSocket(newSocket);
  }, []);

  const on = useCallback(
    (event: string, callback: (payload: any) => void) => {
      socket?.on(event, callback);
    },
    [socket],
  );

  const off = useCallback(
    (event: string) => {
      socket?.off(event);
    },
    [socket],
  );

  return (
    <SocketsContext.Provider value={{ on, off, connected }}>
      {children}
    </SocketsContext.Provider>
  );
};

export const useSockets = (): ISocketsContext => {
  const context = useContext(SocketsContext);
  if (!context) {
    throw new Error('useSockets must be used within SocketProvider');
  }
  return context;
};

export default SocketProvider;
