import React from 'react';
import Card from 'Components/Atoms/Card';
import { Text, Flex, Image, Box, Heading } from '@chakra-ui/react';
import Logo from 'Assets/Images/vertical-dark.svg';

const GettingStartedContent: React.FC = () => {
  return (
    <Card boxShadow="none" p={0} height="100%">
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        justifyContent="space-between"
        height="100%"
      >
        <Box p="20px">
          <Heading as="h1" size="md" mb={8}>
            Getting Started
          </Heading>
          <Text>
            Use the next steps to setup the minimum settings that you need to
            start trading in our platform.
          </Text>
        </Box>
        <Flex
          bgColor="white"
          height={{ base: '30%', md: '100%' }}
          p={8}
          borderRightRadius={{ base: '0px', md: '8px' }}
          borderBottomRadius={{ base: '8px', md: '0px' }}
          minWidth="30%"
          justifyContent="center"
          alignItems="center"
        >
          <Image
            src={Logo}
            w={{ base: '110px', md: '250px' }}
            alt="Orion Logo"
          />
        </Flex>
      </Flex>
    </Card>
  );
};

export default GettingStartedContent;
