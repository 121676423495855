import React from 'react';
import {
  Box,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { useMarketplaces } from 'Hooks/marketplaces';
import FundsSettingsForm from '../FundsSettingsForm';

interface IProps {
  isOpen: boolean;
  onClose(): void;
}

const FundsSettingsFormWrapper: React.FC<IProps> = ({ isOpen, onClose }) => {
  const { currentMarketplaceAccount } = useMarketplaces();

  return (
    <Box mb="20px">
      <Modal motionPreset="slideInBottom" onClose={onClose} isOpen={isOpen}>
        <ModalOverlay />
        <ModalContent pb={5}>
          <ModalHeader>
            {currentMarketplaceAccount?.username}&apos;s Replenishment Settings
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FundsSettingsForm onClose={onClose} />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default FundsSettingsFormWrapper;
