import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import UsersForm from 'Components/Organisms/UsersForm';
import UsersTable from 'Components/Organisms/UsersTable';
import WithDrawer from 'Components/Templates/WithDrawer';

const Users: React.FC = () => {
  useEffect(() => {
    document.title = 'Users - Orion';
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  return (
    <WithDrawer>
      <PageContainer>
        <UsersForm
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setLoadingTable={setLoadingTable}
        />
        <UsersTable
          onOpenForm={onOpen}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
        />
      </PageContainer>
    </WithDrawer>
  );
};

export default Users;
