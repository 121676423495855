import React, { createContext, useCallback, useContext, useState } from 'react';
import api from 'Services/api';

import IPaginated from 'Types/Standards/IPaginated';
import IPaginationData from 'Types/Standards/IPaginationData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import ISetState from 'Types/Standards/ISetState';
import IUpdateUserData from 'Types/DTOs/IUpdateUserData';
import IFormRef from 'Types/Standards/IFormRef';
import ICreateUserData from 'Types/DTOs/ICreateUserData';
import createUserSchema from 'Schemas/createUser';
import editUserSchema from 'Schemas/editUser';
import { User } from '@spiry-capital/modules';
import { useErrors } from './errors';

type IPaginatedUsers = IPaginated<User>;
type IIndexUsers = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;
type IChangeAddress = IDefaultRequest<IChangeAddressData>;
type IDeleteUser = Omit<IDefaultRequest<IDeleteUserData>, 'formRef'>;
interface IEditUser extends Omit<IDefaultRequest<IUpdateUserData>, 'formRef'> {
  formRef?: IFormRef;
  onClose?(): void;
}
interface ICreateUser
  extends Omit<IDefaultRequest<ICreateUserData>, 'formRef'> {
  formRef?: IFormRef;
  onClose?(): void;
}

interface IChangeAddressData {
  address: string;
}

interface IDeleteUserData {
  user_id: string;
}

interface IUsersContext {
  users: IPaginatedUsers;
  allUsers: User[];
  user: User;
  currentUser: User | null;
  setCurrentUser: ISetState<User | null>;
  getUser(): Promise<void>;
  changeAddress({ data, formRef }: IChangeAddress): Promise<void>;
  indexUsers(props: IIndexUsers): Promise<void>;
  indexAllUsers(): Promise<void>;
  deleteUser(props: IDeleteUser): Promise<void>;
  createUser(props: ICreateUser): Promise<void>;
  editUser(props: IEditUser): Promise<void>;
}

const UsersContext = createContext<IUsersContext>({} as IUsersContext);

export const UsersProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [users, setUsers] = useState({} as IPaginatedUsers);
  const [allUsers, setAllUsers] = useState([] as User[]);
  const [user, setUser] = useState({} as User);

  const [currentUser, setCurrentUser] = useState<User | null>(null);

  const indexAllUsers = useCallback(async () => {
    let page = 1;
    let shouldEnd = false;
    const limit = 25;
    const newUsers = [] as User[];

    while (!shouldEnd) {
      try {
        const response = await api.get('/users', {
          params: {
            page,
            limit,
          },
        });
        if (!response.data.users) {
          shouldEnd = true;
        } else {
          newUsers.push(...response.data.users);
        }
        if (response.data.users.length < limit) {
          shouldEnd = true;
        }
        page++;
      } catch (err) {
        handleErrors('Error when trying to index all users', err);
        shouldEnd = true;
      }
    }
    setAllUsers(newUsers);
  }, [handleErrors]);

  const indexUsers = useCallback(
    async ({ data: { page } }: IIndexUsers) => {
      try {
        const response = await api.get('/users', {
          params: {
            page,
            limit: 25,
          },
        });
        setUsers({
          entities: response.data.users,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index users', err);
      }
    },
    [handleErrors],
  );

  const getUser = useCallback(async () => {
    try {
      const response = await api.get<User>('/users/me');
      setUser(response.data);
    } catch (err) {
      handleErrors('Error when trying to login', err);
    }
  }, [handleErrors]);

  const createUser = useCallback(
    async ({ data, setLoading, formRef, onClose }) => {
      try {
        if (setLoading) setLoading(true);
        formRef?.current?.setErrors({});
        await createUserSchema.validate(data, {
          abortEarly: false,
        });
        await api.post(`/users/`, data);

        if (onClose) onClose();
      } catch (err) {
        handleErrors('Error when trying to update user', err, formRef);
      } finally {
        if (setLoading) setLoading(false);
      }
    },
    [handleErrors],
  );

  const editUser = useCallback(
    async ({ data: { id, ...rest }, formRef, onClose }: IEditUser) => {
      try {
        formRef?.current?.setErrors({});
        await editUserSchema.validate(
          { id, ...rest },
          {
            abortEarly: false,
          },
        );

        await api.put(`/users/${id}`, {
          fullname: rest.fullname,
          password: rest.password,
          role_id: rest.role_id,
          email: rest.email,
        });

        if (onClose) onClose();
      } catch (err) {
        handleErrors('Error when trying to update user', err, formRef);
      }
    },
    [handleErrors],
  );

  const deleteUser = useCallback(
    async ({ data: { user_id } }: IDeleteUser): Promise<void> => {
      try {
        await api.delete(`/users/${user_id}`);
      } catch (err) {
        handleErrors('Error when trying to delete user', err);
      }
    },
    [handleErrors],
  );

  const changeAddress = useCallback(
    async ({ data, formRef }: IChangeAddress) => {
      try {
        formRef.current?.setErrors({});

        await api.put<User>('users/crypto-address', {
          address: data?.address,
        });
      } catch (err) {
        handleErrors(
          'Error when trying to change crypto address',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  return (
    <UsersContext.Provider
      value={{
        allUsers,
        indexAllUsers,
        indexUsers,
        users,
        changeAddress,
        getUser,
        createUser,
        deleteUser,
        editUser,
        user,
        setCurrentUser,
        currentUser,
      }}
    >
      {children}
    </UsersContext.Provider>
  );
};

export const useUsers = (): IUsersContext => {
  const context = useContext(UsersContext);
  if (!context) {
    throw new Error('useUsers must be used within UsersProvider');
  }
  return context;
};

export default UsersProvider;
