import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import NavLink from 'Components/Atoms/NavLink';
import IRequestRecoveryPasswordData from 'Types/DTOs/IRequestRecoveryPasswordData';
import { usePassword } from 'Hooks/password';

const RequestRecoveryPasswordForm: React.FC = () => {
  const { requestRecoveryPassword } = usePassword();

  const formRef = useRef<FormHandles>(null);

  const [loading, setLoading] = useState(false);

  const containerWidth = useBreakpointValue({
    base: '100%',
    lg: 'initial',
  });
  const formWidth = useBreakpointValue({
    base: '100%',
    md: '300px',
  });

  const handleRequestRecoveryPassword = useCallback(
    async (data: IRequestRecoveryPasswordData) => {
      setLoading(true);
      await requestRecoveryPassword({ data, formRef });
      setLoading(false);
    },
    [requestRecoveryPassword, formRef],
  );

  return (
    <Flex w={containerWidth} p="55px" justify="center" align="center">
      <Stack mx="20px" w={formWidth} spacing="40px">
        <Box>
          <Heading as="h1" size="lg" mb="15px">
            Recovery password
          </Heading>
          <Text>You&apos;ll receive an email with your recovery token!</Text>
        </Box>

        <Form
          ref={formRef}
          onSubmit={handleRequestRecoveryPassword}
          spacing="10px"
        >
          <Input
            placeholder="john_doe"
            label="Username"
            name="username"
            type="text"
          />
          <Input
            placeholder="joh_doe@example.com"
            label="Email"
            name="email"
            type="email"
            mb="10px"
          />
          <Button isLoading={loading} type="submit" isPrimary>
            Get token
          </Button>
        </Form>

        <Flex justifyContent="center">
          <NavLink text="Back to sign in" to="/" />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default RequestRecoveryPasswordForm;
