import React, { createContext, useCallback, useContext, useState } from 'react';

import api from 'Services/api';
import IMarketplace from 'Types/Entities/IMarketplace';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import ISetState from 'Types/Standards/ISetState';
import IPaginated from 'Types/Standards/IPaginated';
import ICreateMarketplaceData from 'Types/DTOs/ICreateMarketplaceData';
import IUpdateMarketplaceData from 'Types/DTOs/IUpdateMarketplaceData';
import ICreateMarketplaceAccountData from 'Types/DTOs/ICreateMarketplaceAccountData';
import IUpdateMarketplaceAccountData from 'Types/DTOs/IUpdateMarketplaceAccountData';
import IDefaultDeleteData from 'Types/DTOs/IDefaultDeleteData';
import IIndexMarketplaceAccountsData from 'Types/DTOs/IIndexMarketplaceAccountsData';
import IIndexAllMarketplaceAccountsData from 'Types/DTOs/IIndexAllMarketplaceAccountsData';
import createMarketplaceSchema from 'Schemas/createMarketplace';
import createMarketplaceAccountSchema from 'Schemas/createMarketplaceAccount';
import { useErrors } from './errors';

export type IPaginatedMarketplaceAccounts = IPaginated<IMarketplaceAccount>;
interface ICreateMarketplace extends IDefaultRequest<ICreateMarketplaceData> {
  onClose(): void;
}
interface IUpdateMarketplace extends IDefaultRequest<IUpdateMarketplaceData> {
  onClose(): void;
}
type IDeleteMarketplace = Omit<IDefaultRequest<IDefaultDeleteData>, 'formRef'>;
type IIndexMarketplaceAccounts = Omit<
  IDefaultRequest<IIndexMarketplaceAccountsData>,
  'formRef'
>;
type IIndexAllMarketplaceAccounts = Omit<
  IDefaultRequest<IIndexAllMarketplaceAccountsData>,
  'formRef'
>;
interface ICreateMarketplaceAccount
  extends IDefaultRequest<ICreateMarketplaceAccountData> {
  onClose(): void;
}
interface IUpdateMarketplaceAccount
  extends IDefaultRequest<IUpdateMarketplaceAccountData> {
  onClose(): void;
}
interface IUpdateMarketplaceAccountStatus
  extends Omit<IDefaultRequest<{ id: string; enabled: boolean }>, 'formRef'> {
  setChecked: ISetState<boolean>;
}
type IDeleteMarketplaceAccount = Omit<
  IDefaultRequest<IDefaultDeleteData>,
  'formRef'
>;

interface IMarkeplacesContext {
  marketplaces: IMarketplace[];
  currentMarketplace: IMarketplace | null;
  setCurrentMarketplace: ISetState<IMarketplace | null>;
  marketplaceAccounts: IPaginatedMarketplaceAccounts;
  allMarketplaceAccounts: IMarketplaceAccount[];
  currentMarketplaceAccount: IMarketplaceAccount | null;
  setCurrentMarketplaceAccount: ISetState<IMarketplaceAccount | null>;
  indexMarketplaces(): Promise<void>;
  createMarketplace(props: ICreateMarketplace): Promise<void>;
  updateMarketplace(props: IUpdateMarketplace): Promise<void>;
  deleteMarketplace(props: IDeleteMarketplace): Promise<void>;
  indexMarketplaceAccounts(
    props: IIndexMarketplaceAccounts,
  ): Promise<IPaginatedMarketplaceAccounts | null>;
  indexAllMarketplaceAccounts(
    props: IIndexAllMarketplaceAccounts,
  ): Promise<void>;
  createMarketplaceAccount(props: ICreateMarketplaceAccount): Promise<void>;
  updateMarketplaceAccount(props: IUpdateMarketplaceAccount): Promise<void>;
  updateMarketplaceAccountStatus(
    props: IUpdateMarketplaceAccountStatus,
  ): Promise<void>;
  deleteMarketplaceAccount(props: IDeleteMarketplaceAccount): Promise<void>;
}

const MarketplacesContext = createContext<IMarkeplacesContext>(
  {} as IMarkeplacesContext,
);

export const MarketplacesProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [marketplaces, setMarketplaces] = useState<IMarketplace[]>([]);
  const [currentMarketplace, setCurrentMarketplace] =
    useState<IMarketplace | null>(null);
  const [marketplaceAccounts, setMarketplaceAccounts] =
    useState<IPaginatedMarketplaceAccounts>(
      {} as IPaginatedMarketplaceAccounts,
    );
  const [currentMarketplaceAccount, setCurrentMarketplaceAccount] =
    useState<IMarketplaceAccount | null>(null);
  const [allMarketplaceAccounts, setAllMarketplaceAccounts] = useState(
    [] as IMarketplaceAccount[],
  );

  const indexMarketplaces = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get('/marketplaces');
      setMarketplaces(response.data);
    } catch (err) {
      handleErrors('Error when trying to index marketplaces', err);
    }
  }, [handleErrors]);

  const createMarketplace = useCallback(
    async ({ data, formRef, onClose }: ICreateMarketplace): Promise<void> => {
      try {
        await createMarketplaceSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/marketplaces', data);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to create marketplace', err, formRef);
      }
    },
    [handleErrors],
  );

  const updateMarketplace = useCallback(
    async ({
      data: { id, ...rest },
      formRef,
      onClose,
    }: IUpdateMarketplace): Promise<void> => {
      try {
        await createMarketplaceSchema.validate(rest, {
          abortEarly: false,
        });
        await api.put(`/marketplaces/${id}`, rest);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to update marketplace', err, formRef);
      }
    },
    [handleErrors],
  );

  const deleteMarketplace = useCallback(
    async ({ data: { id } }: IDeleteMarketplace): Promise<void> => {
      try {
        await api.delete(`/marketplaces/${id}`);
      } catch (err) {
        handleErrors('Error when trying to delete marketplace', err);
      }
    },
    [handleErrors],
  );

  const indexMarketplaceAccounts = useCallback(
    async ({
      data,
    }: IIndexMarketplaceAccounts): Promise<IPaginatedMarketplaceAccounts | null> => {
      try {
        const response = await api.get('/marketplaces/accounts', {
          params: {
            limit: 25,
            ...data,
          },
        });
        const entities = response.data.marketplace_accounts;
        const { total } = response.data;
        setMarketplaceAccounts({
          entities,
          total,
        });
        return {
          entities,
          total,
        };
      } catch (err) {
        handleErrors('Error when trying to index marketplace accounts', err);
        return null;
      }
    },
    [handleErrors],
  );

  const indexAllMarketplaceAccounts = useCallback(
    async ({ data }: IIndexAllMarketplaceAccounts): Promise<void> => {
      let page = 1;
      let shouldEnd = false;
      const limit = 25;
      const newMarketplaceAccounts = [] as IMarketplaceAccount[];

      while (!shouldEnd) {
        try {
          const response = await api.get('/marketplaces/accounts', {
            params: {
              ...data,
              page,
              limit,
            },
          });
          if (!response.data.marketplace_accounts) {
            shouldEnd = true;
          } else {
            newMarketplaceAccounts.push(...response.data.marketplace_accounts);
          }
          if (response.data.marketplace_accounts.length < limit) {
            shouldEnd = true;
          }
          page++;
        } catch (err) {
          handleErrors(
            'Error when trying to index all marketplace accounts',
            err,
          );
          shouldEnd = true;
        }
      }

      setAllMarketplaceAccounts(newMarketplaceAccounts);
    },
    [handleErrors],
  );

  const createMarketplaceAccount = useCallback(
    async ({
      data: { marketplace_id, ...rest },
      formRef,
      onClose,
    }: ICreateMarketplaceAccount): Promise<void> => {
      try {
        await createMarketplaceAccountSchema.validate(rest, {
          abortEarly: false,
        });
        await api.post('/marketplaces/accounts', {
          marketplace_id,
          ...rest,
        });
        onClose();
      } catch (err) {
        handleErrors(
          'Error when trying to create marketplace account',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const updateMarketplaceAccount = useCallback(
    async ({
      data: { id, ...rest },
      formRef,
      onClose,
    }: IUpdateMarketplaceAccount): Promise<void> => {
      try {
        await createMarketplaceAccountSchema.validate(rest, {
          abortEarly: false,
        });
        await api.put(`/marketplaces/accounts/${id}`, rest);
        onClose();
      } catch (err) {
        handleErrors(
          'Error when trying to update marketplace account',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const updateMarketplaceAccountStatus = useCallback(
    async ({
      data: { id, enabled },
      setChecked,
    }: IUpdateMarketplaceAccountStatus): Promise<void> => {
      try {
        await api.patch(`/marketplaces/accounts/status/${id}`, { enabled });
        setChecked(enabled);
      } catch (err) {
        handleErrors(
          'Error when trying to update marketplace account status',
          err,
        );
      }
    },
    [handleErrors],
  );

  const deleteMarketplaceAccount = useCallback(
    async ({ data: { id } }: IDeleteMarketplaceAccount): Promise<void> => {
      try {
        await api.delete(`/marketplaces/accounts/${id}`);
      } catch (err) {
        handleErrors('Error when trying to delete marketplace account', err);
      }
    },
    [handleErrors],
  );

  return (
    <MarketplacesContext.Provider
      value={{
        marketplaces,
        indexMarketplaces,
        createMarketplace,
        deleteMarketplace,
        updateMarketplace,
        currentMarketplace,
        setCurrentMarketplace,
        createMarketplaceAccount,
        currentMarketplaceAccount,
        deleteMarketplaceAccount,
        indexMarketplaceAccounts,
        marketplaceAccounts,
        setCurrentMarketplaceAccount,
        updateMarketplaceAccount,
        indexAllMarketplaceAccounts,
        allMarketplaceAccounts,
        updateMarketplaceAccountStatus,
      }}
    >
      {children}
    </MarketplacesContext.Provider>
  );
};

export const useMarketplaces = (): IMarkeplacesContext => {
  const context = useContext(MarketplacesContext);
  if (!context) {
    throw new Error('useMarketplaces must be used within MarketplacesProvider');
  }
  return context;
};

export default MarketplacesProvider;
