import axios from 'axios';

import localStorageConfig from 'Config/localStorage';

const getAuthToken = (): string | null => {
  const user = localStorage.getItem(localStorageConfig.userKey);
  const token = localStorage.getItem(localStorageConfig.tokenKey);
  if (!user || !token) return null;
  return token;
};

const isDevelopment =
  process.env.REACT_APP_IS_DEV || process.env.NODE_ENV === 'development';
export const backendURL =
  process.env.REACT_APP_API_URL ||
  (!isDevelopment ? 'https://api.spiry.ro' : 'https://api-beta.spiry.ro');

const api = axios.create({
  baseURL: backendURL,
});

api.interceptors.request.use(config => {
  try {
    const token = getAuthToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
  } catch {}
  return config;
});

export default api;
