import React, { useCallback, useRef } from 'react';
import {
  Button,
  ButtonGroup,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';
import { FaDownload } from 'react-icons/fa';

import { useTrades } from 'Hooks/trades';
import Input from 'Components/Atoms/Input';
import { useColor } from 'Hooks/color';
import Form from 'Components/Atoms/Form';
import { useErrors } from 'Hooks/errors';

interface ITradeHistoryExportModal {
  isOpen: boolean;
  onClose: () => void;
  filterFormRef: React.RefObject<FormHandles>;
}

const TradeHistoryExportModal: React.FC<ITradeHistoryExportModal> = ({
  isOpen,
  onClose,
  filterFormRef,
}) => {
  const { downloadTradesAsCSV } = useTrades();
  const { handleErrors } = useErrors();

  const formRef = useRef<FormHandles>(null);

  const color = useColor();

  const handleExport = useCallback(async () => {
    try {
      const data = formRef.current?.getData();

      if (!data) {
        throw new Error('Please fill all values');
      }

      const filterData = filterFormRef.current?.getData();

      const filter: any = {
        from: data.from,
        to: data.to,
        limit: data.limit,
      };

      if (filterData) {
        if (filterData.status) {
          filter.status = filterData.status;
        }
        if (filterData.search) {
          filter.search = filterData.search;
        }
        if (
          typeof filterData.ended !== 'undefined' &&
          filterData.ended !== null &&
          filterData.ended !== ''
        ) {
          filter.ended = filterData.ended;
        }
      } else {
        filter.ended = true;
      }

      downloadTradesAsCSV(filter);
    } catch (error) {
      handleErrors(error.message, error, formRef);
    }
  }, [downloadTradesAsCSV, filterFormRef, handleErrors]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          Export trades
          <ModalCloseButton mr={2} mt={2} />
        </ModalHeader>
        <ModalBody>
          <Form ref={formRef} onSubmit={handleExport} spacing="10px">
            <Input
              defaultValue={100}
              max={1000}
              type="number"
              name="limit"
              label="Maximum of results"
            />
            <Input type="date" name="from" label="From" />
            <Input type="date" name="to" label="To" />
          </Form>
        </ModalBody>
        <ModalFooter>
          <ButtonGroup>
            <Button variant="outline" colorScheme={color} onClick={onClose}>
              Close
            </Button>
            <Button
              leftIcon={<FaDownload />}
              colorScheme={color}
              onClick={handleExport}
            >
              Download
            </Button>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TradeHistoryExportModal;
