import React, { createContext, useCallback, useContext, useState } from 'react';

import {
  IFilterNotification,
  IFilterNotifications,
  Notification,
} from '@spiry-capital/modules';
import IPaginated from 'Types/Standards/IPaginated';
import api from 'Services/api';
import { useErrors } from './errors';

interface IFilterNotificationsData extends Omit<IFilterNotifications, 'type'> {
  type?: string;
}

interface IFilterNotificationData extends Omit<IFilterNotification, 'type'> {
  type?: string;
}

interface INotificationsContext {
  unreadCount: number;
  notifications: IPaginated<Notification>;
  indexNotifications(data: IFilterNotificationsData): Promise<void>;
  countUnreadNotifications(data: IFilterNotificationData): Promise<void>;
  markNotificationAsRead(id: string): Promise<void>;
  markAllNotificationsAsRead(): Promise<void>;
}

const NotificationsContext = createContext<INotificationsContext>(
  {} as INotificationsContext,
);

export const NotificationsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [notifications, setNotifications] = useState<IPaginated<Notification>>(
    {} as IPaginated<Notification>,
  );
  const [unreadCount, setUnreadCount] = useState<number>(0);

  const indexNotifications = useCallback(
    async (data: IFilterNotificationsData) => {
      try {
        const response = await api.get('/notifications', {
          params: {
            page: 1,
            limit: 5,
            type: 'internal',
            ...data,
          },
        });
        setNotifications({
          entities: response.data.notifications,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index notifications', err);
      }
    },
    [handleErrors],
  );

  const countUnreadNotifications = useCallback(
    async (data: IFilterNotificationData) => {
      try {
        const response = await api.get('/notifications/unread/count', {
          params: data,
        });
        setUnreadCount(response.data.count);
      } catch {}
    },
    [],
  );

  const markNotificationAsRead = useCallback(
    async (id: string) => {
      try {
        await api.patch(`/notifications/${id}`);
      } catch (err) {
        handleErrors(
          'Error when trying to count mark notification as read',
          err,
        );
      }
    },
    [handleErrors],
  );

  const markAllNotificationsAsRead = useCallback(async () => {
    try {
      await api.patch('/notifications/all');
    } catch (err) {
      handleErrors('Error when trying to count mark notification as read', err);
    }
  }, [handleErrors]);

  return (
    <NotificationsContext.Provider
      value={{
        countUnreadNotifications,
        unreadCount,
        indexNotifications,
        notifications,
        markNotificationAsRead,
        markAllNotificationsAsRead,
      }}
    >
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotifications = (): INotificationsContext => {
  const context = useContext(NotificationsContext);
  if (!context) {
    throw new Error(
      'useNotifications must be used within NotificationsProvider',
    );
  }
  return context;
};

export default NotificationsProvider;
