import React, { useCallback, useEffect } from 'react';
import { FiLink } from 'react-icons/fi';
import {
  Icon,
  useDisclosure,
  Modal,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalOverlay,
  ModalFooter,
} from '@chakra-ui/react';
import { useColor } from 'Hooks/color';
import { useGiftCards } from 'Hooks/giftCards';
import { useTrades } from 'Hooks/trades';

import Button from 'Components/Atoms/Button';

interface IProps {
  hash: string;
}

const VipPayment: React.FC<IProps> = ({ hash }) => {
  const color = useColor();
  const { trade, getTrade } = useTrades();

  const { rslPayment } = useGiftCards();
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getTrade({ hash });
  }, [getTrade, hash]);

  const handleRslPayment = useCallback(
    async () =>
      rslPayment({
        data: {
          amount: trade.fiat_amount_requested,
        },
      }),
    [rslPayment, trade],
  );

  return (
    <>
      <Button isPrimary colorScheme={color} onClick={onOpen}>
        <Icon as={FiLink} mr={1} w={4} h={4} /> Send Payment link
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Are you sure you want to send the payment link?
          </ModalHeader>
          <ModalCloseButton mr={2} mt={2} />

          <ModalFooter>
            <Button
              colorScheme="blue"
              variant="outline"
              mr={3}
              onClick={onClose}
            >
              Close
            </Button>
            <Button colorScheme={color} onClick={() => handleRslPayment()}>
              Send link
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VipPayment;
