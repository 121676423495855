import { AbsoluteCenter, Center, CenterProps } from '@chakra-ui/react';
import * as React from 'react';

export const StepContent: React.FC<CenterProps> = (props: CenterProps) => (
  <AbsoluteCenter
    height="full"
    width="full"
    position="absolute"
    zIndex={1}
    p={10}
  >
    <Center height="full" fontSize="sm" fontWeight="semibold" {...props} />
  </AbsoluteCenter>
);
