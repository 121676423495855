import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Skeleton, VStack } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import IUpsertNamescanSettingsData from 'Types/DTOs/IUpsertNamescanSettingsData';
import { useSettings } from 'Hooks/settings';
import Select, { ISelectOption } from 'Components/Atoms/Select';

const NamescanSettingsForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { getSetting, upsertNamescanSettings } = useSettings();

  const [namescanApiKey, setNamescanApiKey] = useState('');
  const [namescanMethod, setNamescanMethod] = useState('');

  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);

  const getSettings = useCallback(async () => {
    setLoadingGet(true);

    const [apiKey, method] = await Promise.all([
      getSetting({
        data: {
          name: 'namescan_api_key',
        },
      }),
      getSetting({
        data: {
          name: 'namescan_method',
        },
      }),
    ]);

    setNamescanApiKey(apiKey?.value || '');
    setNamescanMethod(method?.value || '');

    setLoadingGet(false);

    formRef.current?.setFieldValue('namescan_api_key', apiKey?.value || '');
    formRef.current?.setFieldValue('namescan_method', method?.value || '');
  }, [getSetting]);

  const handleUpsertSetting = useCallback(
    async (data: IUpsertNamescanSettingsData) => {
      setLoadingUpsert(true);
      await upsertNamescanSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      await getSettings();
    },
    [upsertNamescanSettings, formRef, getSettings],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  const methodsOptions = useMemo<ISelectOption[]>(
    () => [
      {
        label: 'Sapphire',
        value: 'sapphire',
      },
      {
        label: 'Emerald',
        value: 'emerald',
      },
    ],
    [],
  );

  return (
    <Card title="Namescan settings" description="">
      <Skeleton isLoaded={!loadingGet}>
        <Form ref={formRef} onSubmit={handleUpsertSetting} spacing="20px">
          <VStack spacing="10px" alignItems="flex-start">
            <Input
              type="text"
              name="namescan_api_key"
              label="API key"
              defaultValue={namescanApiKey}
            />
            <Select
              name="namescan_method"
              label="Method"
              options={methodsOptions}
              defaultValue={namescanMethod}
            />
          </VStack>

          <Box>
            <Button type="submit" isLoading={loadingUpsert} isPrimary>
              Save settings
            </Button>
          </Box>
        </Form>
      </Skeleton>
    </Card>
  );
};

export default NamescanSettingsForm;
