import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import GlasswireCards from 'Components/Organisms/GlasswireCards';
import WithDrawer from 'Components/Templates/WithDrawer';
import { useReports } from 'Hooks/reports';
import { useMarketplaces } from 'Hooks/marketplaces';

interface IParams {
  marketplace: string;
}

const Glasswire: React.FC = () => {
  const { indexReports } = useReports();
  const { indexAllMarketplaceAccounts, marketplaces, indexMarketplaces } =
    useMarketplaces();

  const { marketplace } = useParams<IParams>();
  const [marketplaceId, setMarketplaceId] = useState('');
  const [loadingReports, setLoadingReports] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);

  useEffect(() => {
    setLoadingReports(true);
    indexReports().finally(() => {
      setLoadingReports(false);
    });
  }, [indexReports]);

  useEffect(() => {
    indexMarketplaces();
  }, [indexMarketplaces]);

  useEffect(() => {
    setLoadingAccounts(true);

    const marketplaceObj = marketplaces.find(m => m.name === marketplace);
    if (!marketplaceObj) return;
    setMarketplaceId(marketplaceObj.id);

    indexAllMarketplaceAccounts({
      data: {
        marketplace_id: marketplaceObj.id,
      },
    }).finally(() => {
      setLoadingAccounts(false);
    });
  }, [indexAllMarketplaceAccounts, marketplaces, marketplace]);

  return (
    <WithDrawer>
      <PageContainer>
        <Skeleton isLoaded={!loadingReports && !loadingAccounts}>
          <GlasswireCards marketplaceId={marketplaceId} />
        </Skeleton>
      </PageContainer>
    </WithDrawer>
  );
};

export default Glasswire;
