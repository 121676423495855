import * as Yup from 'yup';

const recoveryPasswordSchema = Yup.object().shape({
  username: Yup.string().required('username required'),
  password: Yup.string().required('password required'),
  confirm_password: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'passwords must match',
  ),
  token: Yup.string().required('token required'),
});

export default recoveryPasswordSchema;
