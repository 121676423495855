import React from 'react';
import { Flex } from '@chakra-ui/react';

import Header from '../../Organisms/Header';

const WithoutDrawer: React.FC = ({ children }) => {
  return (
    <Flex w="100%">
      <Flex direction="column" mt="20px" flex="1" overflowX="auto">
        <Header />
        {children}
      </Flex>
    </Flex>
  );
};

export default WithoutDrawer;
