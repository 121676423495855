import React from 'react';
import { Alert, AlertDescription, AlertIcon } from '@chakra-ui/react';

const FundsSettingsExchangeAPIAlert: React.FC = () => {
  return (
    <Alert mt="20px" status="warning" borderRadius={5}>
      <AlertIcon />
      <AlertDescription>
        These settings will be used to replenish marketplace balances and pay
        the employees.
      </AlertDescription>
    </Alert>
  );
};

export default FundsSettingsExchangeAPIAlert;
