import React, { useMemo } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';

import Card from 'Components/Atoms/Card';
import IdentityVerification from 'Components/Organisms/IdentityVerification';
import { useColor } from 'Hooks/color';
import TradeActions from '../TradeActions';
import PrepaidCardProcessingForm from '../PrepaidCardProcessingForm';
import VipPayment from '../VipPayment';

interface IProps {
  tradeHash: string;
}

const TradingAttached: React.FC<IProps> = ({ tradeHash }) => {
  const color = useColor();

  const tabs = useMemo(
    () => [
      <TradeActions hash={tradeHash} />,
      <PrepaidCardProcessingForm hash={tradeHash} />,
      <VipPayment hash={tradeHash} />,
      <IdentityVerification tradeId={tradeHash} />,
    ],
    [tradeHash],
  );

  const pages = useMemo(
    () => [
      {
        id: 'trade_actions',
        name: 'Trade actions',
      },
      {
        id: 'prepaid_payment',
        name: 'Prepaid payment',
      },
      {
        id: 'vip_payment',
        name: 'Payment page',
      },
      {
        id: 'identity_verification',
        name: 'Identity verification',
      },
    ],
    [],
  );

  return (
    <Card>
      <Tabs variant="enclosed" colorScheme={color}>
        <TabList mb="1em">
          {pages.map(page => (
            <Tab textTransform="capitalize" key={page.id}>
              {page.name}
            </Tab>
          ))}
        </TabList>
        <TabPanels>
          {tabs.map((tab, i) => (
            <TabPanel key={i}>{tab}</TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Card>
  );
};

export default TradingAttached;
