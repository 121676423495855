import React from 'react';
import { Button, Flex, Heading, Icon } from '@chakra-ui/react';
import {
  // FiAlertCircle,
  FiUser,
  // FiBell
} from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import { useColor } from 'Hooks/color';
import PersonalSettingsForm from '../PersonalSettingsForm';

const PersonalSettingsMenu: React.FC = () => {
  const color = useColor();

  return (
    <Card>
      <Heading as="h1" size="md">
        Personal settings
      </Heading>
      <Flex justifyContent="space-between" py={25} gridGap={25}>
        <Flex flexDirection="column" flex="2" gridGap="2px">
          <Button
            colorScheme={color}
            variant="solid"
            d="flex"
            justifyContent="start"
            alignItems="center"
            gridGap="5px"
          >
            <Icon as={FiUser} w={5} h={5} cursor="pointer" />
            General
          </Button>
          {/* <Button
            d="flex"
            colorScheme="gray"
            variant="ghost"
            justifyContent="start"
            alignItems="center"
            gridGap="5px"
          >
            <Icon as={FiAlertCircle} w={5} h={5} cursor="pointer" />
            Information
          </Button>
          <Button
            colorScheme="gray"
            variant="ghost"
            d="flex"
            justifyContent="start"
            alignItems="center"
            gridGap="5px"
          >
            <Icon as={FiBell} w={5} h={5} cursor="pointer" />
            Notification
          </Button> */}
        </Flex>
        <Card flex="6">
          <Flex flexDirection="column">
            {/* <Flex>
              <Center Flex gridGap="25px">
                <Box boxSize="80px">
                  <Avatar
                    borderRadius={8}
                    name={user?.fullname}
                    backgroundColor={`${color}.300`}
                  />
                </Box>
                <Flex flexDirection="column" gridGap="10px">
                  <Button
                    colorScheme="blue"
                    size="xs"
                    w="fit-content"
                    px={5}
                    py={3}
                  >
                    Upload
                  </Button>
                  <Text fontSize="sm" color={textColor}>
                    Allowed JPG, GIF or PNG. Max size of 800kB
                  </Text>
                </Flex>
              </Center>
            </Flex> */}
            <PersonalSettingsForm />
          </Flex>
        </Card>
      </Flex>
    </Card>
  );
};

export default PersonalSettingsMenu;
