import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Skeleton } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import DashboardCompleteTradeCards from 'Components/Organisms/DashboardCompleteTradeCards';
import DashboardMonthlyOverview from 'Components/Organisms/DashboardMonthlyOverview';
import DashboardAverageCards from 'Components/Organisms/DashboardAverageCards';
import DashboardActivityHeatmap from 'Components/Organisms/DashboardActivityHeatmap';
import WithDrawer from 'Components/Templates/WithDrawer';
import { useReports } from 'Hooks/reports';
import { useMarketplaces } from 'Hooks/marketplaces';
import DashboardVendorsForm from 'Components/Organisms/DashboardVendorsForm';
import { useAuth } from 'Hooks/auth';
import IRoleLevels from 'Types/Enums/IRoleLevels';

interface IParams {
  marketplace: string;
}

const Dashboard: React.FC = () => {
  useEffect(() => {
    document.title = 'Dashboard - Orion';
  }, []);

  const { indexReports } = useReports();
  const { user } = useAuth();
  const { indexAllMarketplaceAccounts, marketplaces, indexMarketplaces } =
    useMarketplaces();

  const { marketplace } = useParams<IParams>();
  const [marketplaceId, setMarketplaceId] = useState('');
  const [loadingReports, setLoadingReports] = useState(true);
  const [loadingAccounts, setLoadingAccounts] = useState(true);
  const [vendorId, setVendorId] = useState<string | undefined>(undefined);

  const showForm = useMemo(() => {
    if (!user || !user.role) return false;
    return user.role.level >= IRoleLevels.super_admin;
  }, [user]);

  useEffect(() => {
    setLoadingReports(true);
    indexReports(vendorId).finally(() => {
      setLoadingReports(false);
    });
  }, [indexReports, vendorId]);

  useEffect(() => {
    indexMarketplaces();
  }, [indexMarketplaces]);

  useEffect(() => {
    setLoadingAccounts(true);

    const marketplaceObj = marketplaces.find(m => m.name === marketplace);
    if (!marketplaceObj) return;
    setMarketplaceId(marketplaceObj.id);

    indexAllMarketplaceAccounts({
      data: {
        marketplace_id: marketplaceObj.id,
      },
    }).finally(() => {
      setLoadingAccounts(false);
    });
  }, [indexAllMarketplaceAccounts, marketplaces, marketplace]);

  return (
    <WithDrawer>
      <PageContainer>
        <Skeleton isLoaded={!loadingReports && !loadingAccounts}>
          {showForm && (
            <DashboardVendorsForm
              setVendorId={setVendorId}
              vendorId={vendorId}
            />
          )}
          <DashboardCompleteTradeCards
            marketplaceId={marketplaceId}
            vendorId={vendorId}
          />
          <DashboardMonthlyOverview
            marketplaceId={marketplaceId}
            vendorId={vendorId}
          />
          <DashboardAverageCards
            marketplaceId={marketplaceId}
            vendorId={vendorId}
          />
          <DashboardActivityHeatmap
            marketplaceId={marketplaceId}
            vendorId={vendorId}
          />
        </Skeleton>
      </PageContainer>
    </WithDrawer>
  );
};

export default Dashboard;
