import { Scan } from '@spiry-capital/modules';
import React, { createContext, useCallback, useContext } from 'react';

import api from 'Services/api';
import IPersonData from 'Types/DTOs/IPersonData';
import IScanResponse from 'Types/DTOs/IScanResponse';

export interface IScan extends Omit<Scan, 'response'> {
  response: IScanResponse;
}

interface IScannerContext {
  scanPerson(data: IPersonData): Promise<IScanResponse>;
  getScansByTradeId(tradeId: string): Promise<IScan[]>;
}

const ScannerContext = createContext<IScannerContext>({} as IScannerContext);

export const ScannerProvider: React.FC = ({ children }) => {
  const scanPerson = useCallback(async (data: IPersonData) => {
    const response = await api.post<IScanResponse>('/scans', data);
    return response.data;
  }, []);

  const getScansByTradeId = useCallback(async (tradeId: string) => {
    const response = await api.get<IScan[]>(`/scans/trades/${tradeId}`);
    return response.data;
  }, []);

  return (
    <ScannerContext.Provider value={{ scanPerson, getScansByTradeId }}>
      {children}
    </ScannerContext.Provider>
  );
};

export const useScanner = (): IScannerContext => {
  const context = useContext(ScannerContext);
  if (!context) {
    throw new Error('useScanner must be used within ScannerProvider');
  }
  return context;
};

export default ScannerProvider;
