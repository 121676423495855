import styled from 'styled-components';
import { Box } from '@chakra-ui/react';

export const Container = styled(Box)`
  & + div {
    margin-top: 25px;
  }
`;

export default Container;
