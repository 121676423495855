import React from 'react';
import {
  Box,
  Drawer as ChakraDrawer,
  DrawerOverlay,
  DrawerCloseButton,
  DrawerBody,
  DrawerContent,
} from '@chakra-ui/react';

import { useDrawer } from 'Hooks/drawer';
import Drawer from '../Drawer';

const DrawerWrapper: React.FC = () => {
  const { backgroundColor, navSize, isOpen, onClose, isFixed } = useDrawer();

  return (
    <>
      {isFixed ? (
        <Box
          h="100vh"
          position="sticky"
          top="0"
          left="0"
          overflowY="auto"
          bg={backgroundColor}
          w={navSize === 'small' ? '75px' : '250px'}
          boxShadow="0 4px 12px 0 rgba(0, 0, 0, 0.05)"
        >
          <Drawer />
        </Box>
      ) : (
        <ChakraDrawer placement="left" isOpen={isOpen} onClose={onClose}>
          <DrawerOverlay />
          <DrawerContent maxW="250px">
            <DrawerCloseButton />
            <DrawerBody p={0} mt="25px">
              <Drawer />
            </DrawerBody>
          </DrawerContent>
        </ChakraDrawer>
      )}
    </>
  );
};

export default DrawerWrapper;
