import { extendTheme, withDefaultProps } from '@chakra-ui/react';

const theme = extendTheme(
  {
    config: {
      initialColorMode: 'dark',
      useSystemColorMode: false,
    },
    styles: {
      global: (props: any) => ({
        '*': {
          '::-webkit-scrollbar': {
            width: '10px',
          },
          '::-webkit-scrollbar-thumb': {
            background: props.colorMode === 'dark' ? '#b4b7bd' : '#999',
            borderRadius: '10px',
            border: 'none',
          },
          '::-webkit-scrollbar-track': {
            background: props.colorMode === 'dark' ? '#3b4253' : '#eee',
            boxShadow: 'none',
          },
        },
        'html, body': {
          bg: props.colorMode === 'dark' ? 'Gray 900' : '#f4f4f4',
          minHeight: '100% ',
        },
        button: {
          _focus: {
            boxShadow: 'none !important',
          },
        },
      }),
    },
    components: {
      Heading: {
        baseStyle: (props: any) => ({
          color: props.colorMode === 'dark' ? 'gray.300' : 'gray.600',
        }),
      },
    },
  },
  // @ts-ignore
  withDefaultProps({
    defaultProps: {
      mt: 2,
      mr: 2,
    },
    components: ['ModalCloseButton'],
  }),
);

export default theme;
