import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import PredefinedMessages from 'Components/Organisms/PredefinedMessages';

const PredefinedMessagesTable: React.FC = () => {
  useEffect(() => {
    document.title = 'Predefined Messages - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <PredefinedMessages />
      </PageContainer>
    </WithDrawer>
  );
};

export default PredefinedMessagesTable;
