import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Link as ChakraLink } from '@chakra-ui/react';

import { useColor } from 'Hooks/color';

interface IProps {
  to: string;
  text: string;
}

const NavLink: React.FC<IProps> = ({ text, to }) => {
  const color = useColor();

  return (
    <ChakraLink color={`${color}.300`} as={RouterLink} to={to}>
      {text}
    </ChakraLink>
  );
};

export default NavLink;
