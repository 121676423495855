import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import {
  FormControl,
  FormLabel,
  Switch as BaseSwitch,
  SwitchProps,
} from '@chakra-ui/react';

import { useColor } from 'Hooks/color';

interface IProps extends Omit<SwitchProps, 'onChange'> {
  label?: string;
  name: string;
  onChange?(checked: boolean): void;
}

const Switch: React.FC<IProps> = ({ label, name, onChange, ...rest }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { defaultValue, fieldName, registerField } = useField(name);

  const color = useColor();

  const [checked, setChecked] = useState(!!defaultValue);

  const handleOnChange = useCallback(
    e => {
      setChecked(e.target.checked);
      if (onChange) onChange(e.target.checked);
    },
    [onChange],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      getValue: () => checked || false,
      setValue(newState: boolean) {
        setChecked(newState);
      },
    });
  }, [registerField, fieldName, inputRef, checked]);

  useEffect(() => {
    setChecked(defaultValue);
  }, [defaultValue]);

  return (
    <FormControl display="flex" alignItems="center">
      <BaseSwitch
        id={name}
        colorScheme={color}
        isChecked={checked}
        onChange={e => handleOnChange(e)}
        {...rest}
      />

      {label && (
        <FormLabel htmlFor={name} m="0" ml="10px">
          {label}
        </FormLabel>
      )}
    </FormControl>
  );
};

export default Switch;
