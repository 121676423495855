import localStorageConfig from 'Config/localStorage';
import io, { Socket } from 'socket.io-client';
import { DefaultEventsMap } from 'socket.io-client/build/typed-events';
import { backendURL } from './api';

const setupSockets = (): Socket<DefaultEventsMap, DefaultEventsMap> => {
  const socket = io(backendURL, {
    secure: true,
    transports: ['websocket', 'polling'],
    auth: {
      token: localStorage.getItem(localStorageConfig.tokenKey),
    },
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 500,
  });

  return socket;
};

export default setupSockets;
