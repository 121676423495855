import React, { useCallback, useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import { Box, Tag, Button } from '@chakra-ui/react';

import ControlledSwitch from 'Components/Atoms/ControlledSwitch';
import Table from 'Components/Molecules/Table';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import IMarketplaceAccount from 'Types/Entities/IMarketplaceAccount';
import IMarketplace from 'Types/Entities/IMarketplace';
import ISetState from 'Types/Standards/ISetState';
import {
  IPaginatedMarketplaceAccounts,
  useMarketplaces,
} from 'Hooks/marketplaces';
import { FaExternalLinkAlt } from 'react-icons/fa';
import { backendURL } from 'Services/api';

interface IProps {
  marketplace: IMarketplace;
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  marketplaceAccounts: IPaginatedMarketplaceAccounts;
  setMarketplaceAccounts: ISetState<IPaginatedMarketplaceAccounts>;
  onOpenForm(): void;
}

const MarketplaceAccountsTable: React.FC<IProps> = ({
  onOpenForm,
  marketplace,
  loadingTable,
  setLoadingTable,
  marketplaceAccounts,
  setMarketplaceAccounts,
}) => {
  const [page, setPage] = useState(1);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const {
    indexMarketplaceAccounts,
    setCurrentMarketplaceAccount,
    deleteMarketplaceAccount,
    updateMarketplaceAccountStatus,
  } = useMarketplaces();

  const handleIndex = useCallback(async () => {
    setLoadingTable(true);
    const accounts = await indexMarketplaceAccounts({
      data: {
        page,
        marketplace_id: marketplace.id,
      },
    });
    setLoadingTable(false);
    if (accounts) {
      setMarketplaceAccounts(accounts);
    }
  }, [
    indexMarketplaceAccounts,
    page,
    setLoadingTable,
    marketplace,
    setMarketplaceAccounts,
  ]);

  useEffect(() => {
    handleIndex();
  }, [handleIndex]);

  const handleConnectToPaxful = useCallback(() => {
    window.open(`${backendURL}/marketplaces/accounts/paxful/connect`, '_blank');
  }, []);

  return (
    <Table
      isLoading={loadingTable}
      pagination={{
        currentPage: page,
        limit: 25,
        setPage,
        total: marketplaceAccounts.total,
      }}
      columns={[
        {
          key: 'enabled',
          title: 'enabled',
          render: (row: IMarketplaceAccount) => (
            <ControlledSwitch
              defaultValue={row.enabled}
              onChange={async ({ value, setChecked, setLoading }) => {
                setLoading(true);
                await updateMarketplaceAccountStatus({
                  data: {
                    id: row.id,
                    enabled: value,
                  },
                  setChecked,
                });
                setLoading(false);
              }}
            />
          ),
        },
        {
          key: 'username',
          title: 'username',
          dataIndex: 'username',
        },
        {
          key: 'balance',
          title: 'balance',
          dataIndex: 'balance',
          render: (row: IMarketplaceAccount) => row.balance * 1e-8,
        },
        {
          key: 'address',
          title: 'address',
          dataIndex: 'address',
        },
        {
          key: 'pax_connected',
          title: 'Connected to Paxful',
          dataIndex: 'pax_connected',
          visible: marketplace.name === 'paxful',
          render: (row: IMarketplaceAccount) => (
            <Box>
              {row.paxful_access_token &&
              row.paxful_refresh_token &&
              row.paxful_token_expires_at ? (
                <Tag size="lg" colorScheme="green">
                  Connected
                </Tag>
              ) : (
                <Button
                  rightIcon={<FaExternalLinkAlt />}
                  onClick={handleConnectToPaxful}
                  colorScheme="blue"
                  size="sm"
                  variant="solid"
                >
                  Connect to Paxful
                </Button>
              )}
            </Box>
          ),
        },
        {
          key: 'last_auto_incoming_funds',
          title: 'last auto funds',
          dataIndex: 'last_auto_incoming_funds',
        },
        {
          key: 'actions',
          title: 'actions',
          render: row => (
            <ActionsMenu
              items={[
                {
                  text: 'Edit',
                  icon: FiEdit,
                  onClick: () => {
                    onOpenForm();
                    setCurrentMarketplaceAccount(row as IMarketplaceAccount);
                  },
                },
                {
                  text: 'Delete',
                  icon: FiTrash,
                  loading: loadingDelete,
                  onClick: async () => {
                    setLoadingDelete(true);
                    await deleteMarketplaceAccount({
                      data: {
                        id: row.id,
                      },
                    });
                    setLoadingDelete(false);
                    handleIndex();
                  },
                  confirmation: {
                    title: `Delete marketplace account ${row.username}`,
                    body: 'Are you sure you want to delete this marketplace account and all data attached to it?',
                    buttonText: "Yes, I'm sure",
                    colorScheme: 'red',
                  },
                },
              ]}
            />
          ),
        },
      ]}
      rows={marketplaceAccounts.entities}
    />
  );
};

export default MarketplaceAccountsTable;
