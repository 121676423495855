import React, { useEffect, useMemo } from 'react';
import { Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react';
import { useParams } from 'react-router-dom';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import PrepaidCardProcessingForm from 'Components/Organisms/PrepaidCardProcessingForm';
import TradeActions from 'Components/Organisms/TradeActions';
import VipPayment from 'Components/Organisms/VipPayment';
import Card from 'Components/Atoms/Card';
import { useColor } from 'Hooks/color';
import IdentityVerification from 'Components/Organisms/IdentityVerification';
import { useTrades } from 'Hooks/trades';

const Trading: React.FC = () => {
  const color = useColor();

  const { trade } = useTrades();
  const { hash } = useParams<{ hash: string }>();

  useEffect(() => {
    if (trade.buyer || trade.responder_username) {
      document.title = `${
        Number(trade.chat?.unread) > 0 ? `(${trade?.chat.unread})` : ''
      }${trade.buyer ? trade.buyer : trade.responder_username}'s Trade - Orion`;
    } else {
      document.title = 'Trading - Orion';
    }
  }, [trade.buyer, trade.responder_username, trade.chat]);

  const tabs = useMemo(
    () => [
      <TradeActions hash={hash} />,
      <PrepaidCardProcessingForm hash={hash} />,
      <VipPayment hash={hash} />,
      <IdentityVerification tradeId={hash} />,
    ],
    [hash],
  );

  const pages = useMemo(
    () => [
      {
        id: 'trade_actions',
        name: 'Trade actions',
      },
      {
        id: 'prepaid_payment',
        name: 'Prepaid payment',
      },
      {
        id: 'vip_payment',
        name: 'VIP Payment',
      },
      {
        id: 'identity_verification',
        name: 'Identity verification',
      },
    ],
    [],
  );

  return (
    <WithDrawer>
      <PageContainer>
        <Card title="Trade">
          <Tabs variant="enclosed" colorScheme={color}>
            <TabList mb="1em">
              {pages.map(page => (
                <Tab textTransform="capitalize" key={page.id}>
                  {page.name}
                </Tab>
              ))}
            </TabList>
            <TabPanels>
              {tabs.map((tab, i) => (
                <TabPanel key={i}>{tab}</TabPanel>
              ))}
            </TabPanels>
          </Tabs>
        </Card>
      </PageContainer>
    </WithDrawer>
  );
};

export default Trading;
