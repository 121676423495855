import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { FormHandles } from '@unform/core';

import ISetState from 'Types/Standards/ISetState';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import { useVendors } from 'Hooks/vendors';
import { useAuth } from 'Hooks/auth';

interface IProps {
  setVendorId: ISetState<string | undefined>;
  vendorId?: string;
}

interface IFormData {
  vendor_id: string;
}

const DashboardVendorsForm: React.FC<IProps> = ({ setVendorId, vendorId }) => {
  const { allVendors, indexAllVendors } = useVendors();
  const { user } = useAuth();

  const formRef = useRef<FormHandles>(null);

  const vendorsOptions = useMemo<ISelectOption[]>(
    () =>
      allVendors.map(vendor => ({
        label: vendor.name,
        value: vendor.id,
      })),
    [allVendors],
  );

  const handleSwitchVendor = useCallback(
    ({ vendor_id }: IFormData) => {
      setVendorId(vendor_id);
    },
    [setVendorId],
  );

  const handleSubmitForm = useCallback(() => {
    formRef.current?.submitForm();
  }, []);

  useEffect(() => {
    indexAllVendors();
  }, [indexAllVendors]);

  useEffect(() => {
    setVendorId(vendorId || user?.vendor_id || '');
  }, [user, setVendorId, vendorId]);

  return (
    <Card>
      <Form ref={formRef} onSubmit={handleSwitchVendor}>
        <Select
          name="vendor_id"
          label="Select a vendor"
          options={vendorsOptions}
          onChange={handleSubmitForm}
          defaultValue={vendorId}
        />
      </Form>
    </Card>
  );
};

export default DashboardVendorsForm;
