import React, { createContext, useCallback, useContext, useState } from 'react';
import { useToast } from '@chakra-ui/react';

import { IFilterPrepaidCards, PrepaidCard } from '@spiry-capital/modules';
import IProcessCardData from 'Types/DTOs/IProcessCardData';
import IRslPaymentData from 'Types/DTOs/IRslPaymentData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import IPaginated from 'Types/Standards/IPaginated';
import processCardSchema from 'Schemas/processCard';
import rslPaymentSchema from 'Schemas/rslPayment';
import api from 'Services/api';
import { fourDigitsCardsLinks, sixDigitsCardsLinks } from 'Data/cardsLinks';
import shapeObject from 'Helpers/shapeObject';
import { useErrors } from './errors';

interface IProcessCard extends IDefaultRequest<IProcessCardData> {
  ignoreToast?: boolean;
}
type IRslPayment = Omit<IDefaultRequest<IRslPaymentData>, 'formRef'>;

interface IGiftCardsContext {
  prepaidCards: IPaginated<PrepaidCard>;
  processCard(props: IProcessCard): Promise<void>;
  rslPayment(props: IRslPayment): Promise<void>;
  checkBalance(cardNumber: string): void;
  indexPrepaidCards(data: IFilterPrepaidCards): Promise<void>;
  findDuplicate(card_number: string): Promise<PrepaidCard | null>;
}

const GiftCardsContext = createContext<IGiftCardsContext>(
  {} as IGiftCardsContext,
);

export const GiftCardsProvider: React.FC = ({ children }) => {
  const toast = useToast();
  const { handleErrors } = useErrors();

  const [prepaidCards, setPrepaidCards] = useState<IPaginated<PrepaidCard>>(
    {} as IPaginated<PrepaidCard>,
  );

  const checkBalance = useCallback(
    (cardNumber: string) => {
      let card = Object.entries(fourDigitsCardsLinks).find(([prefix]) => {
        return cardNumber.slice(0, 4) === prefix;
      });

      card =
        Object.entries(sixDigitsCardsLinks).find(([prefix]) => {
          return cardNumber.slice(0, 6) === prefix;
        }) || card;

      if (!card) {
        toast({
          duration: 5000,
          isClosable: true,
          position: 'top-right',
          status: 'error',
          title: 'Error when trying to open balance checker website',
          description:
            'Card prefix is not registered in our system. Please contact our team to add it to the list.',
        });
        return;
      }

      window.open(
        card[1],
        'Check prepaid card balance',
        'resizable,scrollbars,status',
      );
    },
    [toast],
  );

  // {{ baseURL }}/gift-cards/rsl-payment-page

  const rslPayment = useCallback(
    async ({ data }: IRslPayment) => {
      try {
        await rslPaymentSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/gift-cards/rsl-payment-page');
      } catch (err) {
        handleErrors('Error when trying to send link', err);
      }
    },
    [handleErrors],
  );

  const processCard = useCallback(
    async ({ data, formRef, ignoreToast }: IProcessCard) => {
      try {
        formRef.current?.setErrors({});
        shapeObject(data);
        await processCardSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/gift-cards/process-card', data);
        if (ignoreToast) {
          toast({
            duration: 5000,
            isClosable: true,
            position: 'top-right',
            status: 'success',
            title: 'Card processed successfully',
          });
        }
        formRef.current?.reset();
      } catch (err) {
        if (!ignoreToast) {
          handleErrors('Error when trying to process card', err, formRef);
          return;
        }
        throw err;
      }
    },
    [handleErrors, toast],
  );

  const indexPrepaidCards = useCallback(
    async (data: IFilterPrepaidCards): Promise<void> => {
      try {
        const response = await api.get('/gift-cards', {
          params: {
            limit: 25,
            ...data,
          },
        });
        setPrepaidCards({
          entities: response.data.prepaid_cards,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index prepaid cards', err);
      }
    },
    [handleErrors],
  );

  const findDuplicate = useCallback(
    async (card_number: string): Promise<PrepaidCard | null> => {
      try {
        const response = await api.get(`/gift-cards/duplicate/${card_number}`);
        return response.data;
      } catch {
        return null;
      }
    },
    [],
  );

  return (
    <GiftCardsContext.Provider
      value={{
        processCard,
        checkBalance,
        rslPayment,
        indexPrepaidCards,
        prepaidCards,
        findDuplicate,
      }}
    >
      {children}
    </GiftCardsContext.Provider>
  );
};

export const useGiftCards = (): IGiftCardsContext => {
  const context = useContext(GiftCardsContext);
  if (!context) {
    throw new Error('useGiftCards must be used within GiftCardsProvider');
  }
  return context;
};

export default GiftCardsProvider;
