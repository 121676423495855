import React, { createContext, useCallback, useContext, useState } from 'react';

import ISetting from 'Types/Entities/ISetting';
import IObject from 'Types/Standards/IObject';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import IGetSettingData from 'Types/DTOs/IGetSettingData';
import IIndexSettingData from 'Types/DTOs/IIndexSettingData';
import IUpsertFTXSettingsData from 'Types/DTOs/IUpsertFTXSettingsData';
import IUpsertKrakenSettingsData from 'Types/DTOs/IUpsertKrakenSettingsData';
import IUpsertKimaiSettingsData from 'Types/DTOs/IUpsertKimaiSettingsData';
import IIndexSelectedSettingsData from 'Types/DTOs/IIndexSelectedSettingsData';
import IUpsertPayQuickSettingsData from 'Types/DTOs/IUpsertPayQuickSettingsData';
import IUpsertReplenishmentSettingsData from 'Types/DTOs/IUpsertReplenishmentSettingsData';
import IUpsertNamescanSettingsData from 'Types/DTOs/IUpsertNamescanSettingsData';
import upsertKimaiSettingsSchema from 'Schemas/upsertKimaiSettings';
import upsertPayQuickSettingsSchema from 'Schemas/upsertPayQuickSettings';
import upsertReplenishmentSettingsSchema from 'Schemas/upsertReplenishmentSettingsSchema';
import upsertFTXSettingsSchema from 'Schemas/upsertFTXSettings';
import upsertKrakenSettingsSchema from 'Schemas/upsertKrakenSettings';
import upsertNamescanSettingsSchema from 'Schemas/upsertNamescanSettings';
import api from 'Services/api';
import ISetState from 'Types/Standards/ISetState';

import { useErrors } from './errors';

type IGetSetting = Omit<IDefaultRequest<IGetSettingData>, 'formRef'>;
type IIndexSetting = Omit<IDefaultRequest<IIndexSettingData>, 'formRef'>;
type IUpsertKimaiSettings = IDefaultRequest<IUpsertKimaiSettingsData>;
type IUpsertBotSettings = IDefaultRequest<IObject>;
type IUpsertGlobalSettings = IDefaultRequest<IObject>;
type IUpsertPayQuickSettings = IDefaultRequest<IUpsertPayQuickSettingsData>;
type IUpsertReplenishmentSettings =
  IDefaultRequest<IUpsertReplenishmentSettingsData>;
type IUpsertNamescanSettings = IDefaultRequest<IUpsertNamescanSettingsData>;
type IUpsertFTXSettings = IDefaultRequest<IUpsertFTXSettingsData>;
type IUpsertKrakenSettings = IDefaultRequest<IUpsertKrakenSettingsData>;
interface IIndexSelectedSettings
  extends Omit<
    IDefaultRequest<IIndexSelectedSettingsData>,
    'formRef' | 'setLoading'
  > {
  setLoading?: ISetState<boolean>;
}
interface IIndexSelectedGlobalSettings
  extends Omit<
    IDefaultRequest<IIndexSelectedSettingsData>,
    'formRef' | 'setLoading'
  > {
  setLoading?: ISetState<boolean>;
}
type IUpsertVendorSettings = IDefaultRequest<IObject>;

interface ISettingsContext {
  settings: ISetting[];
  indexSettings(props: IIndexSetting): Promise<ISetting[] | null>;
  getSetting(props: IGetSetting): Promise<ISetting | null>;
  upsertKimaiSettings(props: IUpsertKimaiSettings): Promise<void>;
  upsertBotSettings(props: IUpsertBotSettings): Promise<void>;
  upsertGlobalSettings(props: IUpsertGlobalSettings): Promise<void>;
  upsertPayQuickSettings(props: IUpsertPayQuickSettings): Promise<void>;
  upsertNamescanSettings(props: IUpsertNamescanSettings): Promise<void>;
  upsertReplenishmentSettings(
    props: IUpsertReplenishmentSettings,
  ): Promise<void>;
  upsertFTXSettings(props: IUpsertFTXSettings): Promise<void>;
  upsertKrakenSettings(props: IUpsertKrakenSettings): Promise<void>;
  upsertSettings(fields: IObject): Promise<void>;
  indexSelectedSettings<T>(props: IIndexSelectedSettings): Promise<T>;
  indexSelectedGlobalSettings<T>(
    props: IIndexSelectedGlobalSettings,
  ): Promise<T>;
  upsertVendorSettings(props: IUpsertVendorSettings): Promise<void>;
}

const SettingsContext = createContext<ISettingsContext>({} as ISettingsContext);

export const SettingsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [settings, setSetting] = useState<ISetting[]>([]);

  const indexSettings = useCallback(
    async ({ data }: IIndexSetting): Promise<ISetting[] | null> => {
      try {
        const response = await api.get(`/settings/`, {
          params: {
            marketplace_account_id: data.marketplace_account_id,
          },
        });
        setSetting(response.data);
        return response.data;
      } catch (err) {
        handleErrors('Error when trying to index settings', err);
      }
      return null;
    },
    [handleErrors],
  );

  const getSetting = useCallback(
    async ({ data: { name } }: IGetSetting): Promise<ISetting | null> => {
      try {
        const response = await api.get(`/settings/${name}`);
        return response.data;
      } catch {
        return null;
      }
    },
    [],
  );

  const upsertKimaiSettings = useCallback(
    async ({ data, formRef }: IUpsertKimaiSettings) => {
      try {
        await upsertKimaiSettingsSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/settings/', {
          name: 'kimai_user',
          value: data.kimai_user,
        });
        await api.post('/settings/', {
          name: 'kimai_token',
          value: data.kimai_token,
        });
      } catch (err) {
        handleErrors(
          'Error when trying to upsert kimai settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertBotSettings = useCallback(
    async ({
      data: { marketplace_account_id, ...data },
      formRef,
    }: IUpsertBotSettings) => {
      try {
        const promises = Object.entries(data).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
            marketplace_account_id,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors('Error when trying to upsert bot settings', err, formRef);
      }
    },
    [handleErrors],
  );

  const upsertGlobalSettings = useCallback(
    async ({ data, formRef }: IUpsertBotSettings) => {
      try {
        const promises = Object.entries(data).map(
          ([name, value]) =>
            value &&
            api.post('/settings/global', {
              name,
              value,
            }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors(
          'Error when trying to upsert global settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertPayQuickSettings = useCallback(
    async ({ data, formRef }: IUpsertPayQuickSettings) => {
      try {
        await upsertPayQuickSettingsSchema.validate(data, {
          abortEarly: false,
        });
        await Promise.all([
          api.post('/settings/', {
            name: 'payquick_prepaid_hash_key',
            value: data.payquick_prepaid_hash_key,
          }),
          api.post('/settings/', {
            name: 'payquick_prepaid_merchant_number',
            value: data.payquick_prepaid_merchant_number,
          }),
          api.post('/settings/', {
            name: 'payquick_vip_hash_key',
            value: data.payquick_vip_hash_key,
          }),
          api.post('/settings/', {
            name: 'payquick_vip_merchant_number',
            value: data.payquick_vip_merchant_number,
          }),
        ]);
      } catch (err) {
        handleErrors(
          'Error when trying to upsert payquick settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertReplenishmentSettings = useCallback(
    async ({
      data: { marketplace_account_id, ...data },
      formRef,
    }: IUpsertReplenishmentSettings) => {
      try {
        await upsertReplenishmentSettingsSchema.validate(
          { marketplace_account_id, ...data },
          {
            abortEarly: false,
          },
        );

        const promises = Object.entries(data).map(([name, value]) => {
          return api.post('/settings/', {
            marketplace_account_id,
            name,
            value,
          });
        });
        await Promise.all(promises);
      } catch (err) {
        handleErrors(
          'Error when trying to upsert replenishment settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertNamescanSettings = useCallback(
    async ({ data, formRef }: IUpsertNamescanSettings) => {
      try {
        await upsertNamescanSettingsSchema.validate(data, {
          abortEarly: false,
        });
        const promises = Object.entries(data).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors(
          'Error when trying to upsert NameScan settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertFTXSettings = useCallback(
    async ({ data, formRef }: IUpsertFTXSettings) => {
      try {
        await upsertFTXSettingsSchema.validate(data, {
          abortEarly: false,
        });
        const promises = Object.entries(data).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors('Error when trying to upsert FTX settings', err, formRef);
      }
    },
    [handleErrors],
  );

  const upsertKrakenSettings = useCallback(
    async ({ data, formRef }: IUpsertKrakenSettings) => {
      try {
        await upsertKrakenSettingsSchema.validate(data, {
          abortEarly: false,
        });
        const promises = Object.entries(data).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors(
          'Error when trying to upsert Kraken settings',
          err,
          formRef,
        );
      }
    },
    [handleErrors],
  );

  const upsertSettings = useCallback(
    async (fields: IObject) => {
      try {
        const promises = Object.entries(fields).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors('Error when trying to upsert settings', err);
      }
    },
    [handleErrors],
  );

  const indexSelectedSettings = useCallback(
    async ({
      data: { names, marketplace_account_id },
      setLoading,
    }: IIndexSelectedSettings): Promise<any> => {
      if (setLoading) {
        setLoading(true);
      }
      const values = {} as IObject;

      for (const name of names) {
        try {
          const response = await api.get(`/settings/${name}`, {
            params: {
              marketplace_account_id,
            },
          });
          const { value } = response.data;
          if (value === 'true') {
            values[name] = true;
            continue;
          }
          if (value === 'false') {
            values[name] = false;
            continue;
          }
          values[name] = response.data.value;
        } catch {
          values[name] = null;
        }
      }

      if (setLoading) {
        setLoading(false);
      }
      return values;
    },
    [],
  );

  const indexSelectedGlobalSettings = useCallback(
    async ({
      data: { names, marketplace_account_id },
      setLoading,
    }: IIndexSelectedGlobalSettings): Promise<any> => {
      if (setLoading) {
        setLoading(true);
      }
      const values = {} as IObject;

      for (const name of names) {
        try {
          const response = await api.get(`/settings/global/${name}`, {
            params: {
              marketplace_account_id,
            },
          });
          const { value } = response.data;
          if (value === 'true') {
            values[name] = true;
            continue;
          }
          if (value === 'false') {
            values[name] = false;
            continue;
          }
          values[name] = response.data.value;
        } catch {
          values[name] = null;
        }
      }

      if (setLoading) {
        setLoading(false);
      }

      return values;
    },
    [],
  );

  const upsertVendorSettings = useCallback(
    async ({ data, formRef }: IUpsertVendorSettings) => {
      try {
        const promises = Object.entries(data).map(([name, value]) =>
          api.post('/settings/', {
            name,
            value,
          }),
        );
        await Promise.all(promises);
      } catch (err) {
        handleErrors('Error when trying to upsert settings', err, formRef);
      }
    },
    [handleErrors],
  );

  return (
    <SettingsContext.Provider
      value={{
        settings,
        getSetting,
        upsertBotSettings,
        upsertKimaiSettings,
        indexSettings,
        upsertPayQuickSettings,
        upsertReplenishmentSettings,
        indexSelectedSettings,
        indexSelectedGlobalSettings,
        upsertGlobalSettings,
        upsertFTXSettings,
        upsertKrakenSettings,
        upsertSettings,
        upsertNamescanSettings,
        upsertVendorSettings,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export const useSettings = (): ISettingsContext => {
  const context = useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within SettingsProvider');
  }
  return context;
};

export default SettingsProvider;
