import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import IUpdateVendorData from 'Types/DTOs/IUpdateVendorData';
import { useVendors } from 'Hooks/vendors';
import { useAuth } from 'Hooks/auth';
import { useColor } from 'Hooks/color';
import Select from 'Components/Atoms/Select';
import { useUsers } from 'Hooks/users';

const VendorForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { getUserVendor, updateUserVendor, currentVendor } = useVendors();
  const { getUser, user } = useUsers();
  const { updateUser } = useAuth();
  const color = useColor();

  const [loadingVendor, setLoadingVendor] = useState(true);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const options = [
    {
      label: 'Gray',
      value: 'gray',
    },
    {
      label: 'Red',
      value: 'red',
    },
    {
      label: 'Orange',
      value: 'orange',
    },
    {
      label: 'Green',
      value: 'green',
    },
    {
      label: 'Blue',
      value: 'blue',
    },
    {
      label: 'Teal',
      value: 'teal',
    },
    {
      label: 'Cyan',
      value: 'cyan',
    },
    {
      label: 'Purple',
      value: 'purple',
    },
    {
      label: 'Pink',
      value: 'pink',
    },
  ];

  const handleUpdate = useCallback(
    async (data: Omit<IUpdateVendorData, 'name' | 'id'>) => {
      setLoadingUpdate(true);
      // @ts-ignore
      delete data.name;
      await updateUserVendor({
        data,
        formRef,
      });
      await getUserVendor();
      await updateUser();
      await getUser();
      const vendor = { ...currentVendor, theme: data.theme };
      localStorage.setItem('@SPIRY:USER', JSON.stringify({ ...user, vendor }));
      setLoadingUpdate(false);
    },
    [updateUserVendor, getUserVendor, updateUser, getUser, currentVendor, user],
  );

  useEffect(() => {
    setLoadingVendor(true);
    getUserVendor().finally(() => {
      setLoadingVendor(false);
    });
  }, [getUserVendor]);

  useEffect(() => {
    getUser();
  }, [getUser]);

  return (
    <Card title="Vendor settings" description="">
      <Skeleton isLoaded={!loadingVendor}>
        <Form
          ref={formRef}
          onSubmit={handleUpdate}
          initialData={currentVendor || {}}
          spacing="20px"
        >
          <Input type="text" name="name" label="Name" isDisabled />
          <Input type="email" name="email" label="Email" />
          <Select
            name="theme"
            label="Color"
            placeholder="Select a color"
            options={options}
            defaultValue={color}
          />

          <Box>
            <Button type="submit" isLoading={loadingUpdate} isPrimary>
              Save
            </Button>
          </Box>
        </Form>
      </Skeleton>
    </Card>
  );
};

export default VendorForm;
