import React, { createContext, useContext, useState } from 'react';
import {
  useBreakpointValue,
  useColorModeValue,
  useDisclosure,
} from '@chakra-ui/react';

import ISetState from 'Types/Standards/ISetState';

interface IDrawerContext {
  navSize: 'small' | 'large';
  changeNavSize: ISetState<this['navSize']>;
  backgroundColor: string;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  onToggle: () => void;
  isFixed?: boolean;
}

const DrawerContext = createContext<IDrawerContext>({} as IDrawerContext);

export const DrawerProvider: React.FC = ({ children }) => {
  // For desktop
  const [navSize, changeNavSize] = useState<IDrawerContext['navSize']>('large');
  const backgroundColor = useColorModeValue('white', 'gray.700');

  // For mobile
  const { isOpen, onOpen, onClose, onToggle } = useDisclosure();

  // Switch between desktop and mobile
  const isFixed = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <DrawerContext.Provider
      value={{
        navSize,
        changeNavSize,
        backgroundColor,
        isOpen,
        onClose,
        onOpen,
        onToggle,
        isFixed,
      }}
    >
      {children}
    </DrawerContext.Provider>
  );
};

export const useDrawer = (): IDrawerContext => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error('useDrawer must be used within DrawerProvider');
  }
  return context;
};

export default DrawerProvider;
