import React, {
  useRef,
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { useField } from '@unform/core';
import ReactSelect, {
  OptionTypeBase,
  Props as SelectProps,
  Styles,
  GroupTypeBase,
} from 'react-select';
import {
  Box,
  FormControl,
  FormLabel,
  InputRightElement,
  Skeleton,
  useColorModeValue,
  useTheme,
} from '@chakra-ui/react';

import InputGroup from 'Components/Molecules/InputGroup';
import { useColor } from 'Hooks/color';
import InputError from '../InputError';

type IStyles = Styles<OptionTypeBase, true, GroupTypeBase<OptionTypeBase>>;
export interface IMultiSelectOption {
  label: string;
  value: string;
}

interface IProps extends SelectProps<OptionTypeBase, true> {
  name: string;
  label?: string;
  isLoading?: boolean;
}

const MultiSelect: React.FC<IProps> = ({
  name,
  isLoading,
  label,
  defaultValue,
  ...rest
}) => {
  const theme = useTheme();
  const color = useColor();
  const selectRef = useRef(null);
  const { fieldName, registerField, error } = useField(name);

  const [selectedOptions, setSelectedOptions] = useState<IMultiSelectOption[]>(
    [],
  );

  const backgroundColor = useColorModeValue('white', '#2D3748');
  const multiBackgroundColor = useColorModeValue('#edf2f7', '#4e5765');
  const inputColor = useColorModeValue('black', 'white');
  const styles = useMemo<IStyles>(
    () => ({
      control: props => ({
        ...props,
        backgroundColor: 'inherit',
      }),
      menu: props => ({ ...props, backgroundColor, zIndex: 100 }),
      option: (props, { isFocused }) => ({
        ...props,
        backgroundColor: isFocused ? theme.colors[color]['300'] : 'inherit',
      }),
      multiValue: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
      }),
      multiValueLabel: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
        color: inputColor,
      }),
      multiValueRemove: props => ({
        ...props,
        backgroundColor: multiBackgroundColor,
        color: inputColor,
      }),
      input: props => ({
        ...props,
        color: inputColor,
      }),
    }),
    [backgroundColor, color, multiBackgroundColor, theme.colors, inputColor],
  );

  const handleUpdateValues = useCallback((options: any) => {
    setSelectedOptions(options as IMultiSelectOption[]);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: () => {
        return selectedOptions.map(option => option.value);
      },
      setValue: (_: any, newValue: string[]) => {
        setSelectedOptions(
          newValue.map(value => ({
            label: value,
            value,
          })),
        );
      },
      clearValue: () => {
        setSelectedOptions([]);
      },
    });
  }, [fieldName, registerField, rest.isMulti, selectedOptions]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    const parsedDefault = defaultValue as string[];
    setSelectedOptions(
      parsedDefault.map(value => ({
        label: value,
        value,
      })),
    );
  }, [defaultValue]);

  return (
    <Skeleton isLoaded={!isLoading}>
      <FormControl>
        {label && <FormLabel mb="5px">{label}</FormLabel>}

        <InputGroup>
          <Box w="100%">
            <ReactSelect
              isMulti
              value={selectedOptions}
              ref={selectRef}
              classNamePrefix="react-select"
              styles={styles}
              onChange={options => handleUpdateValues(options)}
              isSearchable
              {...rest}
            />
          </Box>
          {error && (
            <InputRightElement mr="25px">
              <InputError error={error} />
            </InputRightElement>
          )}
        </InputGroup>
      </FormControl>
    </Skeleton>
  );
};

export default MultiSelect;
