import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Trade } from '@spiry-capital/modules';

import api from 'Services/api';
import download from 'Helpers/download';
import IPaginated from 'Types/Standards/IPaginated';
import IPaginationData from 'Types/Standards/IPaginationData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import ISetState from 'Types/Standards/ISetState';

import { useErrors } from './errors';
import { useSockets } from './sockets';

type IPaginatedTrades = IPaginated<Trade>;
interface IIndexTrades {
  data: {
    page: number;
    search?: string;
    status?: string[];
    ended?: string | boolean;
  };
}
interface IGetTrade
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
}
interface IPostStartDispute
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
  reason: string;
  reasonType: string;
}
interface IPostReleaseBTC
  extends Omit<IDefaultRequest<IPaginationData>, 'formRef' | 'data'> {
  hash: string;
}
interface IDownloadTradesAsCSV {
  limit: number;
  from: string;
  to: string;
  search?: string;
  status?: string[];
  ended?: string | boolean;
}
type IIndexActiveTrades = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;

interface IUpdateTradeAutoMessages
  extends Omit<
    IDefaultRequest<{ trade_hash: string; auto_messages_disabled: boolean }>,
    'formRef'
  > {
  setChecked: ISetState<boolean>;
}

interface ITradesContext {
  trades: IPaginatedTrades;
  trade: Trade;
  activeTrades: IPaginatedTrades;
  disputedTrades: IPaginatedTrades;
  recentModerated: string[];
  addRecentModerated(hash: string): void;
  removeRecentModerated(hash: string): void;
  indexTrades(props: IIndexTrades): Promise<void>;
  getTrade(props: IGetTrade): Promise<void>;
  indexActiveTrades(props: IIndexActiveTrades): Promise<void>;
  indexDisputedTrades(props: IIndexActiveTrades): Promise<void>;
  startDispute(props: IPostStartDispute): Promise<void>;
  releaseBTC(props: IPostReleaseBTC): Promise<void>;
  updateAutoMessages(props: IUpdateTradeAutoMessages): Promise<void>;
  downloadTradesAsCSV(data: IDownloadTradesAsCSV): Promise<void>;
}

const TradesContext = createContext<ITradesContext>({} as ITradesContext);

export const TradesProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [trades, setTrades] = useState({} as IPaginatedTrades);
  const [trade, setTrade] = useState({} as Trade);
  const [activeTrades, setActiveTrades] = useState({} as IPaginatedTrades);
  const [disputedTrades, setDisputedTrades] = useState({} as IPaginatedTrades);
  const [recentModerated, setRecentModerated] = useState<string[]>([]);

  const socket = useSockets();

  const indexTrades = useCallback(
    async ({ data: { page, ...rest } }: IIndexTrades) => {
      try {
        const response = await api.get('/trades', {
          params: {
            page,
            limit: 25,
            ...rest,
          },
        });
        setTrades({
          total: response.data.total,
          entities: response.data.trades,
        });
      } catch (err) {
        handleErrors('Error when trying to index trades', err);
      }
    },
    [handleErrors],
  );

  const getTrade = useCallback(
    async ({ hash }: IGetTrade) => {
      try {
        const response = await api.get<Trade>(`/trades/info/${hash}`);
        setTrade(response.data);
      } catch (err) {
        handleErrors('Error when trying to get the trade', err);
      }
    },
    [handleErrors],
  );

  const indexActiveTrades = useCallback(
    async ({ data: { page } }: IIndexActiveTrades) => {
      try {
        const response = await api.get('/trades', {
          params: {
            page,
            limit: 25,
            status: [
              'Active funded',
              'Paid',
              'Active',
              'Funds processing',
              'Funds processed',
              'Not funded',
            ],
            ended: false,
          },
        });
        setActiveTrades({
          total: response.data.total,
          entities: response.data.trades,
        });
      } catch (err) {
        handleErrors('Error when trying to index active trades', err);
      }
    },
    [handleErrors],
  );

  const indexDisputedTrades = useCallback(
    async ({ data: { page } }: IIndexActiveTrades) => {
      try {
        const response = await api.get('/trades', {
          params: {
            page,
            limit: 25,
            status: ['Dispute open'],
            ended: false,
          },
        });
        setDisputedTrades({
          total: response.data.total,
          entities: response.data.trades,
        });
      } catch (err) {
        handleErrors('Error when trying to index disputed trades', err);
      }
    },
    [handleErrors],
  );

  const downloadTradesAsCSV = useCallback(
    async ({ limit, from, to, ...rest }: IDownloadTradesAsCSV) => {
      try {
        const response = await api.get('/trades/csv', {
          params: {
            page: 1,
            limit,
            from,
            to,
            ...rest,
          },
        });
        download(`trades-export-${from}-${to}.csv`, response.data, 'text/csv');
      } catch (err) {
        handleErrors('Error when trying to index active trades', err);
      }
    },
    [handleErrors],
  );

  const startDispute = useCallback(
    async ({ reason, reasonType, hash }: IPostStartDispute) => {
      try {
        await api.post(`/trades/disputes`, {
          reason,
          reason_type: reasonType,
          trade_hash: hash,
        });
      } catch (err) {
        handleErrors('Error when trying to start dispute', err);
      }
    },
    [handleErrors],
  );

  const releaseBTC = useCallback(
    async ({ hash }: IPostStartDispute) => {
      try {
        await api.post(`/trades/releasebtcs`, {
          trade_hash: hash,
        });
      } catch (err) {
        handleErrors('Error when trying to release bitcoins', err);
      }
    },
    [handleErrors],
  );

  const updateAutoMessages = useCallback(
    async ({ data, setChecked }: IUpdateTradeAutoMessages) => {
      try {
        await api.patch('/trades/auto-messages', data);
        setChecked(!data.auto_messages_disabled);
      } catch (err) {
        handleErrors('Error when trying to update trade auto messages', err);
      }
    },
    [handleErrors],
  );

  const addRecentModerated = useCallback(
    (hash: string) => {
      if (!recentModerated.includes(hash)) {
        setRecentModerated([...recentModerated, hash]);
      }
    },
    [recentModerated],
  );

  const removeRecentModerated = useCallback(
    (hash: string) => {
      const exists = recentModerated.find(h => h === hash);
      if (exists) {
        const newState = recentModerated.filter(h => h !== exists);
        setRecentModerated(newState);
      }
    },
    [recentModerated],
  );

  useEffect(() => {
    if (!socket.connected) {
      return;
    }

    socket.on('event', event => {
      const { content } = event;

      if (!content) return;

      if (content.type === 'trade.chat.message') {
        if (content.payload && content.payload.isForModerator) {
          const exists = activeTrades.entities.find(
            t => t.hash === content.payload.trade_hash,
          );
          if (!exists) {
            return;
          }
          addRecentModerated(exists.hash);
        }
      }
    });
    // eslint-disable-next-line
  }, [socket, activeTrades]);

  return (
    <TradesContext.Provider
      value={{
        indexTrades,
        getTrade,
        startDispute,
        indexActiveTrades,
        indexDisputedTrades,
        trades,
        trade,
        activeTrades,
        disputedTrades,
        releaseBTC,
        updateAutoMessages,
        recentModerated,
        addRecentModerated,
        removeRecentModerated,
        downloadTradesAsCSV,
      }}
    >
      {children}
    </TradesContext.Provider>
  );
};

export const useTrades = (): ITradesContext => {
  const context = useContext(TradesContext);
  if (!context) {
    throw new Error('useTrades must be used within TradesProvider');
  }
  return context;
};

export default TradesProvider;
