import React, { createContext, useCallback, useContext, useState } from 'react';

import api from 'Services/api';
import IVendor from 'Types/Entities/IVendor';

import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import ISetState from 'Types/Standards/ISetState';
import IPaginated from 'Types/Standards/IPaginated';
import IPaginationData from 'Types/Standards/IPaginationData';
import ICreateVendorData from 'Types/DTOs/ICreateVendorData';
import IUpdateVendorData from 'Types/DTOs/IUpdateVendorData';
import IDefaultDeleteData from 'Types/DTOs/IDefaultDeleteData';
import createVendorSchema from 'Schemas/createVendor';
import updateUserVendorSchema from 'Schemas/updateUserVendor';
import { useErrors } from './errors';

export type IPaginatedVendors = IPaginated<IVendor>;
type IIndexVendors = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;
interface ICreateVendor extends IDefaultRequest<ICreateVendorData> {
  onClose(): void;
}
interface IUpdateVendor extends IDefaultRequest<IUpdateVendorData> {
  onClose(): void;
}
type IDeleteVendor = Omit<IDefaultRequest<IDefaultDeleteData>, 'formRef'>;
type IUpdateUserVendor = IDefaultRequest<
  Omit<IUpdateVendorData, 'name' | 'id'>
>;

interface IVendorsContext {
  vendors: IPaginatedVendors;
  allVendors: IVendor[];
  currentVendor: IVendor | null;
  setCurrentVendor: ISetState<IVendor | null>;
  indexVendors(props: IIndexVendors): Promise<void>;
  indexAllVendors(): Promise<void>;
  createVendor(props: ICreateVendor): Promise<void>;
  updateVendor(props: IUpdateVendor): Promise<void>;
  deleteVendor(props: IDeleteVendor): Promise<void>;
  getUserVendor(): Promise<void>;
  updateUserVendor(props: IUpdateUserVendor): Promise<void>;
}

const VendorsContext = createContext<IVendorsContext>({} as IVendorsContext);

export const VendorsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [vendors, setVendors] = useState<IPaginatedVendors>(
    {} as IPaginatedVendors,
  );
  const [currentVendor, setCurrentVendor] = useState<IVendor | null>(null);
  const [allVendors, setAllVendors] = useState<IVendor[]>([]);

  const indexVendors = useCallback(
    async ({ data: { page } }: IIndexVendors): Promise<void> => {
      try {
        const response = await api.get('/vendors', {
          params: {
            page,
            limit: 25,
          },
        });
        setVendors({
          entities: response.data.vendors,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index vendors', err);
      }
    },
    [handleErrors],
  );

  const indexAllVendors = useCallback(async (): Promise<void> => {
    let page = 1;
    let shouldEnd = false;
    const limit = 25;
    const newVendors = [] as IVendor[];

    while (!shouldEnd) {
      try {
        const response = await api.get('/vendors', {
          params: {
            page,
            limit,
          },
        });
        if (!response.data.vendors) {
          shouldEnd = true;
        } else {
          newVendors.push(...response.data.vendors);
        }
        if (response.data.vendors.length < limit) {
          shouldEnd = true;
        }
        page++;
      } catch (err) {
        handleErrors('Error when trying to index all vendors', err);
        shouldEnd = true;
      }
    }

    setAllVendors(newVendors);
  }, [handleErrors]);

  const createVendor = useCallback(
    async ({ data, formRef, onClose }: ICreateVendor): Promise<void> => {
      try {
        await createVendorSchema.validate(data, {
          abortEarly: false,
        });
        await api.post('/vendors', data);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to create vendor', err, formRef);
      }
    },
    [handleErrors],
  );

  const updateVendor = useCallback(
    async ({
      data: { id, ...rest },
      formRef,
      onClose,
    }: IUpdateVendor): Promise<void> => {
      try {
        await createVendorSchema.validate(rest, {
          abortEarly: false,
        });
        await api.put(`/vendors/${id}`, rest);
        onClose();
      } catch (err) {
        handleErrors('Error when trying to update vendor', err, formRef);
      }
    },
    [handleErrors],
  );

  const deleteVendor = useCallback(
    async ({ data: { id } }: IDeleteVendor): Promise<void> => {
      try {
        await api.delete(`/vendors/${id}`);
      } catch (err) {
        handleErrors('Error when trying to delete vendor', err);
      }
    },
    [handleErrors],
  );

  const getUserVendor = useCallback(async (): Promise<void> => {
    try {
      const response = await api.get(`/vendors/me`);
      setCurrentVendor(response.data);
    } catch (err) {
      handleErrors('Error when trying to get vendor', err);
    }
  }, [handleErrors]);

  const updateUserVendor = useCallback(
    async ({ data, formRef }: IUpdateUserVendor): Promise<void> => {
      try {
        await updateUserVendorSchema.validate(data, {
          abortEarly: false,
        });
        await api.put('/vendors/me', data);
      } catch (err) {
        handleErrors('Error when trying to delete vendor', err, formRef);
      }
    },
    [handleErrors],
  );

  return (
    <VendorsContext.Provider
      value={{
        vendors,
        indexVendors,
        createVendor,
        deleteVendor,
        updateVendor,
        currentVendor,
        setCurrentVendor,
        getUserVendor,
        updateUserVendor,
        allVendors,
        indexAllVendors,
      }}
    >
      {children}
    </VendorsContext.Provider>
  );
};

export const useVendors = (): IVendorsContext => {
  const context = useContext(VendorsContext);
  if (!context) {
    throw new Error('useVendors must be used within VendorsProvider');
  }
  return context;
};

export default VendorsProvider;
