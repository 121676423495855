import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, Tag } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import { IFilterJobs, Job } from '@spiry-capital/modules';

import IJobStatus from 'Types/Enums/IJobStatus';
import IPaginated from 'Types/Standards/IPaginated';
import Card from 'Components/Atoms/Card';
import Input from 'Components/Atoms/Input';
import Select, { ISelectOption } from 'Components/Atoms/Select';
import Table from 'Components/Molecules/Table';
import InputGroup from 'Components/Molecules/InputGroup';
import formatDate from 'Helpers/formatDate';
import enumToArray from 'Helpers/enumToArray';
import { useJobs } from 'Hooks/jobs';
import Form from 'Components/Atoms/Form';
import shapeObject from 'Helpers/shapeObject';
import capitalize from 'Helpers/capitalize';

const JobsTable: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [page, setPage] = useState(1);
  const [loadingJobs, setLoadingJobs] = useState(false);
  const [jobs, setJobs] = useState({} as IPaginated<Job>);
  const [searchTimeout, setSearchTimeout] = useState<NodeJS.Timeout>();
  const [searchData, setSearchData] = useState<IFilterJobs>({});

  const { indexJobs } = useJobs();

  const statusOptions = useMemo<ISelectOption[]>(
    () =>
      enumToArray(IJobStatus).map(status => ({
        label: capitalize(status),
        value: status,
      })),
    [],
  );

  const getRowStatusColor = useCallback((status: IJobStatus) => {
    switch (status) {
      case IJobStatus.completed:
        return 'green';
      case IJobStatus.errored:
        return 'red';
      default:
        return 'yellow';
    }
  }, []);

  const handleSetSearchData = useCallback(() => {
    const data = formRef.current?.getData();
    setSearchData(data as IFilterJobs);
  }, []);

  const handleIndexJobs = useCallback(
    async (data: IFilterJobs) => {
      const newJobs = await indexJobs(data);

      if (!newJobs) {
        return;
      }

      setJobs(newJobs);
    },
    [indexJobs],
  );

  useEffect(() => {
    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    setSearchTimeout(
      setTimeout(() => {
        setLoadingJobs(true);
        handleIndexJobs({
          page,
          ...shapeObject(searchData),
        }).finally(() => {
          setLoadingJobs(false);
        });
      }, 500),
    );

    return () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleIndexJobs, searchData]);

  useEffect(() => {
    setLoadingJobs(true);
    handleIndexJobs({
      page,
      ...shapeObject(searchData),
    }).finally(() => {
      setLoadingJobs(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handleIndexJobs, page]);

  return (
    <Card title="Jobs">
      <Box mb={5}>
        <Form
          ref={formRef}
          onChange={handleSetSearchData}
          onSubmit={handleSetSearchData}
        >
          <InputGroup>
            <Input type="text" name="queue" placeholder="Filter by queue" />
            <Input type="text" name="event" placeholder="Filter by event" />
          </InputGroup>
          <InputGroup>
            <Select
              name="status"
              options={statusOptions}
              placeholder="Filter by status"
              onChange={handleSetSearchData}
              isClearable
            />
          </InputGroup>
        </Form>
      </Box>

      <Table
        isLoading={loadingJobs}
        columns={[
          {
            key: 'queue',
            title: 'queue',
            dataIndex: 'queue',
          },
          {
            key: 'event',
            title: 'event',
            dataIndex: 'event',
          },
          {
            key: 'status',
            title: 'status',
            dataIndex: 'status',
            render: row => (
              <Tag
                textTransform="capitalize"
                colorScheme={getRowStatusColor(row.status)}
              >
                {row.status}
              </Tag>
            ),
          },
          {
            key: 'error',
            title: 'error',
            dataIndex: 'error',
          },
          {
            key: 'created_at',
            title: 'created at',
            render: row => formatDate(row.created_at, 'Pp'),
          },
        ]}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: jobs.total,
        }}
        rows={jobs.entities}
      />
    </Card>
  );
};

export default JobsTable;
