import React, { useCallback, useEffect, useState } from 'react';
import { Flex, Skeleton } from '@chakra-ui/react';

import { useMarketplaces } from 'Hooks/marketplaces';
import { useChat } from 'Hooks/chats';
import { useTrades } from 'Hooks/trades';
import { useSockets } from 'Hooks/sockets';

import TradeInfo from '../TradeInfo';
import TradeChat from '../TradeChat';
import AttachmentsModal from '../AttachmentsModal';

interface IProps {
  hash: string;
}

const TradeActions: React.FC<IProps> = ({ hash }) => {
  const { trade, getTrade } = useTrades();
  const socket = useSockets();
  const { allMarketplaceAccounts, indexAllMarketplaceAccounts } =
    useMarketplaces();
  const { chat, getChat } = useChat();
  const [refreshTimeout, setRefreshTimeout] = useState<NodeJS.Timeout>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    (async () => {
      await Promise.all([
        indexAllMarketplaceAccounts({
          data: { marketplace_id: trade?.marketplace_account?.marketplace.id },
        }),

        getTrade({
          hash,
        }),

        getChat({
          hash,
        }),
      ]);

      setLoading(false);
    })();
  }, [
    getChat,
    getTrade,
    hash,
    indexAllMarketplaceAccounts,
    trade?.marketplace_account?.marketplace.id,
  ]);

  const refresh = useCallback(async () => {
    if (refreshTimeout) {
      clearTimeout(refreshTimeout);
    }

    await getChat({
      hash,
    });

    setRefreshTimeout(setTimeout(refresh, 5000));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getChat, hash]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  useEffect(() => {
    return () => {
      socket.off('event');
    };
  }, [hash, refresh, socket]);

  useEffect(() => {
    return () => {
      if (refreshTimeout) {
        clearTimeout(refreshTimeout);
      }
    };
  }, [refreshTimeout]);

  return (
    <Skeleton isLoaded={!loading}>
      <AttachmentsModal hash={hash} />
      <Flex justifyContent="space-between">
        <TradeChat
          chat={chat}
          allMarketplaceAccounts={allMarketplaceAccounts}
          loading={loading}
        />
        <TradeInfo trade={trade} loading={loading} hash={hash} />
      </Flex>
    </Skeleton>
  );
};

export default TradeActions;
