import React, { useEffect, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';

import Card from 'Components/Atoms/Card';
import ControlledSwitch from 'Components/Atoms/ControlledSwitch';
import Table from 'Components/Molecules/Table';
import ActiveIcon from 'Components/Atoms/StatusIcon';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import ISetState from 'Types/Standards/ISetState';
import IOfferGroup from 'Types/Entities/IOfferGroup';
import { useOffers } from 'Hooks/offers';

interface IProps {
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  onOpenForm(): void;
}

const OfferGroupsTable: React.FC<IProps> = ({
  onOpenForm,
  loadingTable,
  setLoadingTable,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [page, setPage] = useState(1);

  const {
    deleteOfferGroup,
    indexOfferGroups,
    offerGroups,
    setCurrentOfferGroup,
    updateOfferGroupStatus,
  } = useOffers();

  useEffect(() => {
    setLoadingTable(true);
    indexOfferGroups({
      data: {
        page,
      },
    }).finally(() => {
      setLoadingTable(false);
    });
  }, [indexOfferGroups, setLoadingTable, page]);

  return (
    <Card title="Offer groups">
      <Table
        isLoading={loadingTable}
        columns={[
          {
            key: 'active',
            title: 'active',
            render: row => (
              <ControlledSwitch
                defaultValue={row.active}
                onChange={async ({ value, setChecked, setLoading }) => {
                  setLoading(true);
                  await updateOfferGroupStatus({
                    data: {
                      id: row.id,
                      active: value,
                    },
                    setChecked,
                  });
                  setLoading(false);
                }}
              />
            ),
          },
          {
            key: 'name',
            title: 'name',
            dataIndex: 'name',
          },
          {
            key: 'offers',
            title: 'offers',
            render: row => row.has_offers?.length,
          },
          {
            key: 'watson_enabled',
            title: 'watson enabled',
            render: row => <ActiveIcon isActive={row.watson_enabled} />,
          },
          {
            key: 'static_message_enabled',
            title: 'static message enabled',
            render: row => <ActiveIcon isActive={row.static_message_enabled} />,
          },
          {
            key: 'margin',
            title: 'margin',
            dataIndex: 'margin',
            render: row => `${(Number(row.margin) || 0).toLocaleString()}%`,
          },
          {
            key: 'processing_fee',
            title: 'processing fee',
            render: row =>
              `${(Number(row.processing_fee) || 0).toLocaleString()}%`,
          },
          {
            key: 'actions',
            title: 'actions',
            render: row => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick() {
                      onOpenForm();
                      setCurrentOfferGroup(row as IOfferGroup);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteOfferGroup({
                        data: {
                          id: row.id,
                        },
                      });
                      setLoadingDelete(false);
                      setLoadingTable(true);
                      await indexOfferGroups({
                        data: {
                          page,
                        },
                      });
                      setLoadingTable(false);
                    },
                    confirmation: {
                      title: `Delete offer group ${row.name}`,
                      body: 'Are you sure you want to delete this offer group?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        pagination={{
          setPage,
          currentPage: page,
          limit: 25,
          total: offerGroups.total,
        }}
        rows={offerGroups.entities}
      />
    </Card>
  );
};

export default OfferGroupsTable;
