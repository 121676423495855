import React, { useCallback, useRef, useState } from 'react';
import { FormHandles } from '@unform/core';
import {
  Box,
  Flex,
  Heading,
  Stack,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import ILogin from 'Types/DTOs/ILogin';
import { useAuth } from 'Hooks/auth';
import NavLink from 'Components/Atoms/NavLink';

const LoginForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);
  const { login } = useAuth();

  const [loading, setLoading] = useState(false);

  const containerWidth = useBreakpointValue({
    base: '100%',
    lg: 'initial',
  });
  const formWidth = useBreakpointValue({
    base: '100%',
    md: '300px',
  });

  const handleLogin = useCallback(
    async (data: ILogin) => {
      setLoading(true);
      await login({ data, formRef });
      setLoading(false);
    },
    [login, formRef],
  );

  return (
    <Flex w={containerWidth} p="55px" justify="center" align="center">
      <Stack mx="20px" w={formWidth} spacing="40px">
        <Box>
          <Heading as="h1" size="lg" mb="15px">
            Enter Orion
          </Heading>
          <Text>Sign-in to your account and start the battle!</Text>
        </Box>

        <Form ref={formRef} onSubmit={handleLogin} spacing="10px">
          <Input
            placeholder="john_doe"
            label="Username"
            name="username"
            type="text"
          />
          <Input
            placeholder="Secret"
            label="Password"
            name="password"
            type="password"
            mb="10px"
          />
          <Button isLoading={loading} type="submit" isPrimary>
            Sign In
          </Button>
        </Form>

        <Flex justifyContent="center">
          <NavLink
            text="I've forgot my password"
            to="/request-recovery-password"
          />
        </Flex>
      </Stack>
    </Flex>
  );
};

export default LoginForm;
