import React, { createContext, useCallback, useContext, useState } from 'react';

import IPaginated from 'Types/Standards/IPaginated';
import api from 'Services/api';
import { Charge } from '@spiry-capital/modules';
import IPaginationData from 'Types/Standards/IPaginationData';
import IDefaultRequest from 'Types/Standards/IDefaultRequest';
import { useErrors } from './errors';

type IIndexCharges = Omit<IDefaultRequest<IPaginationData>, 'formRef'>;

interface IChargesContext {
  charges: IPaginated<Charge>;
  indexCharges(data: IIndexCharges): Promise<void>;
}

const ChargesContext = createContext<IChargesContext>({} as IChargesContext);

export const ChargesProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const [charges, setCharges] = useState<IPaginated<Charge>>(
    {} as IPaginated<Charge>,
  );

  const indexCharges = useCallback(
    async ({ data: { page } }: IIndexCharges) => {
      try {
        const response = await api.get('/charges', {
          params: {
            page,
            limit: 25,
          },
        });
        setCharges({
          entities: response.data.charges,
          total: response.data.total,
        });
      } catch (err) {
        handleErrors('Error when trying to index charges', err);
      }
    },
    [handleErrors],
  );

  return (
    <ChargesContext.Provider
      value={{
        charges,
        indexCharges,
      }}
    >
      {children}
    </ChargesContext.Provider>
  );
};

export const useCharges = (): IChargesContext => {
  const context = useContext(ChargesContext);
  if (!context) {
    throw new Error('useCharges must be used within ChargesProvider');
  }
  return context;
};

export default ChargesProvider;
