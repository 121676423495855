import React, { useMemo } from 'react';
import { useColorModeValue, useTheme } from '@chakra-ui/react';
import Chart from 'react-apexcharts';

import { useColor } from 'Hooks/color';
import { useReports } from 'Hooks/reports';

interface IProps {
  marketplaceId: string;
  vendorId?: string;
}

const DashboardHeatmap: React.FC<IProps> = ({ marketplaceId, vendorId }) => {
  const color = useColor();
  const theme = useTheme();
  const { getReport } = useReports();

  const blackhole = useMemo(() => theme.colors.black, [theme]);
  const veryLow = useMemo(() => theme.colors[color]['200'], [theme, color]);
  const low = useMemo(() => theme.colors[color]['300'], [theme, color]);
  const high = useMemo(() => theme.colors[color]['400'], [theme, color]);
  const extreme = useMemo(() => theme.colors[color]['500'], [theme, color]);
  const foreColor = useColorModeValue(theme.colors.black, theme.colors.white);
  const labelColor = useColorModeValue(theme.colors.white, theme.colors.black);

  const heatmap = useMemo(
    () =>
      getReport({
        name: 'heatmap',
        marketplace_id: marketplaceId,
        vendor_id: vendorId,
      }),
    [getReport, marketplaceId, vendorId],
  );
  const categories = useMemo(() => (heatmap ? heatmap.y : []), [heatmap]);
  const series = useMemo(() => (heatmap ? heatmap.x : []), [heatmap]);

  return (
    <Chart
      options={{
        chart: {
          id: 'heatmap',
          foreColor,
        },
        plotOptions: {
          heatmap: {
            colorScale: {
              ranges: [
                {
                  from: -1,
                  to: 0,
                  color: blackhole,
                  name: 'Blackhole',
                },
                {
                  from: 1,
                  to: 2,
                  color: veryLow,
                  name: 'Very low',
                },
                {
                  from: 2,
                  to: 5,
                  color: low,
                  name: 'Low',
                },
                {
                  from: 6,
                  to: 12,
                  color: high,
                  name: 'High',
                },
                {
                  from: 13,
                  to: 25,
                  color: extreme,
                  name: 'Extreme',
                },
              ],
            },
          },
        },
        xaxis: {
          categories,
        },
        dataLabels: {
          enabled: false,
        },
      }}
      type="heatmap"
      height={600}
      series={series}
      style={{
        color: labelColor,
      }}
    />
  );
};

export default DashboardHeatmap;
