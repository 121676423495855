import React, { useEffect } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithDrawer from 'Components/Templates/WithDrawer';
import NotificationsTable from 'Components/Organisms/NotificationsTable';

const Notifications: React.FC = () => {
  useEffect(() => {
    document.title = 'Notifications - Orion';
  }, []);

  return (
    <WithDrawer>
      <PageContainer>
        <NotificationsTable />
      </PageContainer>
    </WithDrawer>
  );
};

export default Notifications;
