import React, { createContext, useCallback, useContext } from 'react';

import { IFilterJobs, Job } from '@spiry-capital/modules';

import IPaginated from 'Types/Standards/IPaginated';
import api from 'Services/api';
import { useErrors } from './errors';

interface IJobsContext {
  indexJobs(props: IFilterJobs): Promise<IPaginated<Job> | null>;
}

const JobsContext = createContext<IJobsContext>({} as IJobsContext);

export const JobsProvider: React.FC = ({ children }) => {
  const { handleErrors } = useErrors();

  const indexJobs = useCallback(
    async (filters: IFilterJobs): Promise<IPaginated<Job> | null> => {
      try {
        const response = await api.get('/jobs', {
          params: {
            limit: 25,
            ...filters,
          },
        });

        return {
          entities: response.data.jobs,
          total: response.data.total,
        };
      } catch (err) {
        handleErrors('Error when trying to index jobs', err);
        return null;
      }
    },
    [handleErrors],
  );

  return (
    <JobsContext.Provider value={{ indexJobs }}>
      {children}
    </JobsContext.Provider>
  );
};

export const useJobs = (): IJobsContext => {
  const context = useContext(JobsContext);
  if (!context) {
    throw new Error('useJobs must be used within JobsProvider');
  }
  return context;
};

export default JobsProvider;
