import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Skeleton } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import Button from 'Components/Atoms/Button';
import { useMarketplaces } from 'Hooks/marketplaces';
import { useSettings } from 'Hooks/settings';
import IUpsertReplenishmentSettingsData from 'Types/DTOs/IUpsertReplenishmentSettingsData';
import IUpsertReplenishmentAccountSettingsData from 'Types/DTOs/IUpsertReplenishmentAccountSettingsData';
import Switch from 'Components/Atoms/Switch';

interface IProps {
  onClose: () => void;
}

const FundsSettingsForm: React.FC<IProps> = ({ onClose }) => {
  const formRef = useRef<FormHandles>(null);

  const { currentMarketplaceAccount } = useMarketplaces();
  const { indexSelectedSettings, upsertReplenishmentSettings } = useSettings();

  const [loadingForm, setLoadingForm] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);
  const [data, setData] = useState<IUpsertReplenishmentAccountSettingsData>(
    {} as IUpsertReplenishmentAccountSettingsData,
  );

  const getSettings = useCallback(async () => {
    if (!currentMarketplaceAccount) return;
    setLoadingForm(true);

    try {
      const settings =
        await indexSelectedSettings<IUpsertReplenishmentSettingsData>({
          data: {
            names: ['funds_auto_enabled', 'funds_minimum', 'funds_target'],
            marketplace_account_id: currentMarketplaceAccount.id,
          },
        });

      setData(settings);
    } finally {
      setLoadingForm(false);
    }
  }, [currentMarketplaceAccount, indexSelectedSettings]);

  const handleUpsertSettings = useCallback(
    async (
      data: Omit<IUpsertReplenishmentSettingsData, 'marketplace_account_id'>,
    ) => {
      if (!currentMarketplaceAccount) return;

      setLoadingUpsert(true);

      await upsertReplenishmentSettings({
        data: {
          marketplace_account_id: currentMarketplaceAccount.id,
          ...data,
        },
        formRef,
      })
        .then(() => {
          getSettings();
        })
        .finally(() => {
          setLoadingUpsert(false);
          onClose();
        });
    },
    [
      currentMarketplaceAccount,
      getSettings,
      onClose,
      upsertReplenishmentSettings,
    ],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Skeleton isLoaded={!loadingForm}>
      <Form
        ref={formRef}
        onSubmit={handleUpsertSettings}
        initialData={data}
        spacing="10px"
      >
        <Flex alignItems="center" mb={1}>
          <Switch label="Active" name="funds_auto_enabled" />
        </Flex>
        <Input
          type="number"
          name="funds_minimum"
          label="Minimum amount"
          placeholder="00.00"
        />
        <Input
          type="number"
          name="funds_target"
          label="Target amount"
          mb="10px"
          placeholder="00.00"
        />
        <Box>
          <Button type="submit" isLoading={loadingUpsert} isPrimary>
            Save settings
          </Button>
        </Box>
      </Form>
    </Skeleton>
  );
};

export default FundsSettingsForm;
