import React, { useEffect, useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

import PageContainer from 'Components/Atoms/PageContainer';
import UserPayrollSettingsForm from 'Components/Organisms/UserPayrollSettingsForm';
import UserPayrollSettingsTable from 'Components/Organisms/UserPayrollSettingsTable';
import WithDrawer from 'Components/Templates/WithDrawer';

const UserPayrollSettings: React.FC = () => {
  useEffect(() => {
    document.title = 'User Payroll Settings - Orion';
  }, []);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const [loadingTable, setLoadingTable] = useState(true);

  return (
    <WithDrawer>
      <PageContainer>
        <UserPayrollSettingsForm
          isOpen={isOpen}
          onOpen={onOpen}
          onClose={onClose}
          setLoadingTable={setLoadingTable}
        />
        <UserPayrollSettingsTable
          onOpenForm={onOpen}
          loadingTable={loadingTable}
          setLoadingTable={setLoadingTable}
        />
      </PageContainer>
    </WithDrawer>
  );
};

export default UserPayrollSettings;
