import * as Yup from 'yup';

import IUserPayrollSettingFrequencies from 'Types/Enums/IUserPayrollSettingFrequencies';
import enumToArray from 'Helpers/enumToArray';

export default Yup.object().shape({
  user_id: Yup.string().required('user required'),
  frequency: Yup.string()
    .equals(enumToArray(IUserPayrollSettingFrequencies))
    .required('frequency required'),
  amount_usd: Yup.number()
    .typeError('usd amount must be a number')
    .required('usd amount required'),
  active: Yup.boolean().required('active required'),
});
