import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@chakra-ui/react';

import Tabs, { ITab } from 'Components/Molecules/Tabs';
import BotSettingsTab from 'Components/Organisms/BotSettingsTab';
import { useMarketplaces } from 'Hooks/marketplaces';
import Card from 'Components/Atoms/Card';

const BotSettingsContent: React.FC = () => {
  const { indexMarketplaces, marketplaces } = useMarketplaces();

  const [loadingMarketplaces, setLoadingMarketplaces] = useState(true);

  const tabs = useMemo<ITab[]>(() => {
    const data = [
      { title: 'general', component: <BotSettingsTab /> },
    ] as ITab[];
    data.push(
      ...marketplaces.map(marketplace => ({
        title: marketplace.name,
        component: <BotSettingsTab marketplace={marketplace} />,
      })),
    );
    return data;
  }, [marketplaces]);

  useEffect(() => {
    setLoadingMarketplaces(true);
    indexMarketplaces().finally(() => {
      setLoadingMarketplaces(false);
    });
  }, [indexMarketplaces]);

  return (
    <Card title="Bots settings">
      <Skeleton isLoaded={!loadingMarketplaces}>
        <Tabs tabs={tabs} />
      </Skeleton>
    </Card>
  );
};

export default BotSettingsContent;
