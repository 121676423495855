import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Heading, Skeleton, VStack } from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import IUpsertPayQuickSettingsData from 'Types/DTOs/IUpsertPayQuickSettingsData';
import ISetState from 'Types/Standards/ISetState';
import { useSettings } from 'Hooks/settings';

const PayQuickSettingsForm: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { getSetting, upsertPayQuickSettings } = useSettings();

  const [vipMerchantNumber, setVipMerchantNumber] = useState('');
  const [vipHashKey, setVipHashKey] = useState('');
  const [prepaidMerchantNumber, setPrepaidMerchantNumber] = useState('');
  const [prepaidHashKey, setPrepaidHashKey] = useState('');
  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);

  const setSetting = useCallback(
    async (name: string, set: ISetState<string>) => {
      setLoadingGet(true);
      const setting = await getSetting({
        data: {
          name,
        },
      });
      setLoadingGet(false);
      const value = setting?.value || '';
      set(value);
      formRef.current?.setFieldValue(name, value);
    },
    [setLoadingGet, getSetting],
  );

  const getSettings = useCallback(async () => {
    setSetting('payquick_vip_merchant_number', setVipMerchantNumber);
    setSetting('payquick_vip_hash_key', setVipHashKey);
    setSetting('payquick_prepaid_merchant_number', setPrepaidMerchantNumber);
    setSetting('payquick_prepaid_hash_key', setPrepaidHashKey);
  }, [setSetting]);

  const handleUpsertSetting = useCallback(
    async (data: IUpsertPayQuickSettingsData) => {
      setLoadingUpsert(true);
      await upsertPayQuickSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      await getSettings();
    },
    [upsertPayQuickSettings, formRef, getSettings],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Card title="PayQuick settings" description="">
      <Skeleton isLoaded={!loadingGet}>
        <Form ref={formRef} onSubmit={handleUpsertSetting} spacing="20px">
          <VStack spacing="10px" alignItems="flex-start">
            <Heading as="h2" size="sm">
              Generate Payment Page
            </Heading>
            <Input
              type="text"
              name="payquick_vip_merchant_number"
              label="Merchant number"
              defaultValue={vipMerchantNumber}
            />
            <Input
              type="text"
              name="payquick_vip_hash_key"
              label="Hash key"
              defaultValue={vipHashKey}
            />
          </VStack>

          <VStack spacing="10px" alignItems="flex-start">
            <Heading as="h2" size="sm">
              Process Prepaid Cards (POS)
            </Heading>
            <Input
              type="text"
              name="payquick_prepaid_merchant_number"
              label="Merchant number"
              defaultValue={prepaidMerchantNumber}
            />
            <Input
              type="text"
              name="payquick_prepaid_hash_key"
              label="Hash key"
              defaultValue={prepaidHashKey}
            />
          </VStack>

          <Box>
            <Button type="submit" isLoading={loadingUpsert} isPrimary>
              Save settings
            </Button>
          </Box>
        </Form>
      </Skeleton>
    </Card>
  );
};

export default PayQuickSettingsForm;
