import React from 'react';
import { Flex, Heading, Text, VStack, Box, Progress } from '@chakra-ui/react';

import Card from 'Components/Atoms/Card';
import { useColor } from 'Hooks/color';

interface IProps {
  title?: string;
  maxMargin: number;
  avgMargin: number;
  progress?: number;
}

const GlasswireCard: React.FC<IProps> = ({ title, maxMargin, avgMargin }) => {
  const color = useColor();

  return (
    <Card flex="1" minWidth="350px" maxWidth="350px">
      {title && (
        <Heading mb={5} size="md" isTruncated fontWeight="bold">
          {title}
        </Heading>
      )}

      <VStack mb="20px" spacing="10px" alignItems="flex-start">
        <Flex alignItems="center" w="100%">
          <Box w="100%">
            <Text fontSize="xl">Maximum Margin:</Text>
            <Text
              fontSize="2xl"
              mb={3}
              color={`${color}.400`}
              fontWeight="bold"
            >
              {maxMargin.toFixed(2)}%
            </Text>
            <Flex alignItems="center" gridGap={3}>
              <Text fontSize="xl">Average Margin:</Text>
            </Flex>

            <Box display="flex" alignItems="center" gridGap={3}>
              <Text color={`${color}.400`} fontSize="2xl" fontWeight="bold">
                {avgMargin.toFixed(2)}%
              </Text>
              <Progress
                borderRadius={5}
                size="lg"
                value={avgMargin}
                colorScheme={color}
                isAnimated
                hasStripe
                w="100%"
              />
            </Box>
          </Box>
        </Flex>
      </VStack>
    </Card>
  );
};

export default GlasswireCard;
