import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  Box,
  Skeleton,
} from '@chakra-ui/react';
import { FormHandles } from '@unform/core';

import Button from 'Components/Atoms/Button';
import Card from 'Components/Atoms/Card';
import Form from 'Components/Atoms/Form';
import Input from 'Components/Atoms/Input';
import NavLink from 'Components/Atoms/NavLink';
import IUpsertSettingData from 'Types/DTOs/IUpsertKimaiSettingsData';
import { useSettings } from 'Hooks/settings';

const PayrollSettingsKimaiAPI: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { getSetting, upsertKimaiSettings } = useSettings();

  const [kimaiUser, setKimaiUser] = useState('');
  const [kimaiToken, setKimaiToken] = useState('');
  const [loadingGet, setLoadingGet] = useState(true);
  const [loadingUpsert, setLoadingUpsert] = useState(false);

  const getSettings = useCallback(async () => {
    setLoadingGet(true);
    const user = await getSetting({
      data: {
        name: 'kimai_user',
      },
    });
    setLoadingGet(false);
    const userValue = user?.value || '';
    setKimaiUser(userValue);

    setLoadingGet(true);
    const token = await getSetting({
      data: {
        name: 'kimai_token',
      },
    });
    setLoadingGet(false);
    const tokenValue = token?.value || '';
    setKimaiToken(tokenValue);

    formRef.current?.setData({
      kimai_user: userValue,
      kimai_token: tokenValue,
    });
  }, [getSetting, setLoadingGet]);

  const handleUpsertSetting = useCallback(
    async (data: IUpsertSettingData) => {
      setLoadingUpsert(true);
      await upsertKimaiSettings({
        data,
        formRef,
      });
      setLoadingUpsert(false);
      await getSettings();
    },
    [upsertKimaiSettings, formRef, getSettings],
  );

  useEffect(() => {
    getSettings();
  }, [getSettings]);

  return (
    <Card title="Kimai API" description="">
      <Skeleton isLoaded={!loadingGet}>
        <Form ref={formRef} onSubmit={handleUpsertSetting} spacing="10px">
          <Input
            type="text"
            name="kimai_user"
            label="Kimai user"
            defaultValue={kimaiUser}
          />
          <Input
            type="text"
            name="kimai_token"
            label="Kimai token"
            mb="10px"
            defaultValue={kimaiToken}
          />
          <Box>
            <Button type="submit" isLoading={loadingUpsert} isPrimary>
              Save settings
            </Button>
          </Box>
        </Form>
      </Skeleton>

      <Alert mt="20px" status="warning" borderRadius={5}>
        <AlertIcon />
        <AlertDescription>
          These settings will be used to get the worked hours of the employees
          and pay them based on that.
          <br />
          You also need to setup{' '}
          <NavLink text="exchange settings" to="/funds-settings" /> as it will
          be used to pay the employees.
        </AlertDescription>
      </Alert>
    </Card>
  );
};

export default PayrollSettingsKimaiAPI;
