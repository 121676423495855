import React, { useMemo } from 'react';
import { Stack, StackDirection, useBreakpointValue } from '@chakra-ui/react';

import DashboardCard from 'Components/Molecules/DashboardCard';
import { useReports } from 'Hooks/reports';
import getCurrencyString from 'Helpers/getCurrencyString';

interface ICard {
  profit: string;
  amount: string;
  period: string;
}

interface IProps {
  marketplaceId: string;
  vendorId?: string;
}

const DashboardAverageCards: React.FC<IProps> = ({
  marketplaceId,
  vendorId,
}) => {
  const { getReport } = useReports();

  const direction = useBreakpointValue<StackDirection>({
    base: 'column',
    md: 'row',
  });

  const stats = useMemo(
    () =>
      getReport({
        name: 'stats',
        marketplace_id: marketplaceId,
        vendor_id: vendorId,
      }),
    [getReport, marketplaceId, vendorId],
  );

  const cards = useMemo<ICard[]>(() => {
    if (!stats) return [];

    const periods = ['yearly', 'monthly', 'weekly', 'daily'];

    return periods.map<ICard>(period => ({
      profit: getCurrencyString(stats[period].average_profit),
      amount: getCurrencyString(stats[period].average_margin),
      period,
    }));
  }, [stats]);

  return (
    <Stack
      direction={direction}
      spacing="25px"
      mb={cards.length > 0 ? '25px' : '0px'}
    >
      {cards.map((card, i) => (
        <DashboardCard
          key={i}
          items={[
            {
              title: {
                text: 'Average profit:',
              },
              subtitle: {
                text: card.profit,
                color: 'primary',
                size: 'large',
              },
            },
            {
              title: {
                text: 'Average amount:',
              },
              subtitle: {
                text: card.amount,
                color: 'primary',
                size: 'large',
              },
            },
          ]}
          period={card.period}
        />
      ))}
    </Stack>
  );
};

export default DashboardAverageCards;
