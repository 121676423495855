import React, { useEffect, useMemo, useState } from 'react';
import { Skeleton } from '@chakra-ui/react';

import Tabs from 'Components/Molecules/Tabs';
import MarketplaceAccountsTab from 'Components/Organisms/MarketplaceAccountsTab';
import { useMarketplaces } from 'Hooks/marketplaces';
import Card from 'Components/Atoms/Card';

const MarketplaceAccountsContent: React.FC = () => {
  const { indexMarketplaces, marketplaces } = useMarketplaces();

  const [loadingMarketplaces, setLoadingMarketplaces] = useState(true);

  const tabs = useMemo(
    () =>
      marketplaces.map(marketplace => ({
        title: marketplace.name,
        component: <MarketplaceAccountsTab marketplace={marketplace} />,
      })),
    [marketplaces],
  );

  useEffect(() => {
    setLoadingMarketplaces(true);
    indexMarketplaces().finally(() => {
      setLoadingMarketplaces(false);
    });
  }, [indexMarketplaces]);

  return (
    <Card title="Marketplace Accounts">
      <Skeleton isLoaded={!loadingMarketplaces}>
        <Tabs tabs={tabs} />
      </Skeleton>
    </Card>
  );
};

export default MarketplaceAccountsContent;
