import React, { useCallback, useEffect, useState } from 'react';

import Table from 'Components/Molecules/Table';
import { useOffers } from 'Hooks/offers';
import ActionsMenu from 'Components/Molecules/ActionsMenu';
import { FiEdit, FiTrash } from 'react-icons/fi';
import IMarketplace from 'Types/Entities/IMarketplace';
import ISetState from 'Types/Standards/ISetState';
import IOffer from 'Types/Entities/IOffer';
import IPaginated from 'Types/Standards/IPaginated';
import ControlledSwitch from 'Components/Atoms/ControlledSwitch';

interface IProps {
  marketplace: IMarketplace;
  loadingTable: boolean;
  setLoadingTable: ISetState<boolean>;
  offers: IPaginated<IOffer>;
  setOffers: ISetState<IPaginated<IOffer>>;
  onOpenForm(): void;
}

const OffersTable: React.FC<IProps> = ({
  onOpenForm,
  marketplace,
  loadingTable,
  setLoadingTable,
  setOffers,
  offers,
}) => {
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [page, setPage] = useState(1);

  const {
    indexOffers,
    deleteOffer,
    setCurrentOffer,
    setCurrentOfferAccountId,
    updateOfferStatus,
  } = useOffers();

  const handleIndex = useCallback(async () => {
    setLoadingTable(true);
    const newOffers = await indexOffers({
      data: {
        page,
      },
      marketplace_id: marketplace.id,
    });
    if (newOffers) {
      setOffers(newOffers);
    }
    setLoadingTable(false);
  }, [setLoadingTable, indexOffers, page, marketplace.id, setOffers]);

  useEffect(() => {
    handleIndex();
  }, [handleIndex]);

  return (
    <>
      <Table
        isLoading={loadingTable}
        columns={[
          {
            key: 'active',
            title: 'active',
            render: row => (
              <ControlledSwitch
                defaultValue={row.active}
                onChange={async ({ value, setChecked, setLoading }) => {
                  setLoading(true);
                  await updateOfferStatus({
                    data: {
                      id: row.id,
                      active: value,
                    },
                    setChecked,
                  });
                  setLoading(false);
                }}
              />
            ),
          },
          {
            key: 'marketplace',
            title: 'marketplace',
            render: row => row.marketplace_account?.marketplace?.name,
          },
          {
            key: 'account',
            title: 'account',
            render: row => row.marketplace_account?.username,
          },
          {
            key: 'type',
            title: 'type',
            dataIndex: 'type',
          },
          {
            key: 'hash',
            title: 'hash',
            dataIndex: 'hash',
          },
          {
            key: 'payment_method_name',
            title: 'payment method',
            dataIndex: 'payment_method_name',
          },
          {
            key: 'fiat_currency_code',
            title: 'currency code',
            dataIndex: 'fiat_currency_code',
          },
          {
            key: 'margin',
            title: 'margin',
            dataIndex: 'margin',
          },
          {
            key: 'actions',
            title: 'actions',
            render: (row: IOffer) => (
              <ActionsMenu
                items={[
                  {
                    text: 'Edit',
                    icon: FiEdit,
                    onClick: () => {
                      onOpenForm();
                      setCurrentOffer(row as IOffer);
                      setCurrentOfferAccountId(row.marketplace_account_id);
                    },
                  },
                  {
                    text: 'Delete',
                    icon: FiTrash,
                    loading: loadingDelete,
                    onClick: async () => {
                      setLoadingDelete(true);
                      await deleteOffer({
                        data: {
                          hash: row.hash,
                          account_id: row.marketplace_account_id,
                        },
                      });
                      setLoadingDelete(false);
                      handleIndex();
                    },
                    confirmation: {
                      title: `Delete offer`,
                      body: 'Are you sure you want to delete this offer and all data attached to it?',
                      buttonText: "Yes, I'm sure",
                      colorScheme: 'red',
                    },
                  },
                ]}
              />
            ),
          },
        ]}
        pagination={{
          currentPage: page,
          limit: 25,
          setPage,
          total: offers.total,
        }}
        rows={offers.entities}
      />
    </>
  );
};

export default OffersTable;
