import React, { useEffect, useMemo, useState } from 'react';

import PageContainer from 'Components/Atoms/PageContainer';
import WithoutDrawer from 'Components/Templates/WithoutDrawer';
import MarketplaceAccountsContent from 'Components/Organisms/MarketplaceAccountsContent';
import PayQuickSettingsForm from 'Components/Organisms/PayQuickSettingsForm';
import NamescanSettingsForm from 'Components/Organisms/NamescanSettingsForm';
import BotSettingsContent from 'Components/Organisms/BotSettingsContent';
import GettingStartedContent from 'Components/Organisms/GettingStartedContent';
import { useHistory } from 'react-router-dom';

import { Box, Flex, HStack, useColorModeValue } from '@chakra-ui/react';
import { Step } from 'Components/Organisms/Step';
import Button from 'Components/Atoms/Button';
import { useColor } from 'Hooks/color';

interface IStepInterface {
  name: string;
  component: JSX.Element | undefined;
}

const SetupWizard: React.FC = () => {
  useEffect(() => {
    document.title = 'Initial Setup - Orion';
  }, []);

  const color = useColor();
  const history = useHistory();

  const [step, setStep] = useState<number>(0);
  const cardColor = useColorModeValue('white', 'gray.700');

  const stepsContent = useMemo<IStepInterface[]>(() => {
    return [
      {
        name: 'Getting Started',
        component: <GettingStartedContent />,
      },
      {
        name: 'Marketplace Accounts',
        component: <MarketplaceAccountsContent />,
      },
      {
        name: 'Payment Processor',
        component: <PayQuickSettingsForm />,
      },
      {
        name: 'Namescan',
        component: <NamescanSettingsForm />,
      },
      {
        name: 'Automations',
        component: <BotSettingsContent />,
      },
    ];
  }, []);

  const stepsForm = useMemo(() => {
    if (stepsContent[step].component) {
      return stepsContent[step].component;
    }

    return <></>;
  }, [step, stepsContent]);

  return (
    <WithoutDrawer>
      <PageContainer>
        <Box
          mx="auto"
          maxW="3xl"
          py="10"
          px={{ base: '6', md: '8' }}
          overflow="auto"
          sx={{
            '&::-webkit-scrollbar': {
              display: 'none',
            },
            '&::-webkit-scrollbar-thumb': {
              display: 'none',
            },
          }}
        >
          <nav aria-label="Progress steps">
            <HStack as="ol" listStyleType="none" spacing="0" width="700px">
              {stepsContent.map(({ name }, index) => (
                <Step isCurrent={step === index} setStep={setStep} step={index}>
                  {name}
                </Step>
              ))}
            </HStack>
          </nav>
        </Box>
        <Flex width="100%" justifyContent="center">
          <Box
            maxW="1366px"
            minH="520px"
            width="100%"
            bgColor={cardColor}
            borderRadius="7px"
          >
            {stepsForm}
          </Box>
        </Flex>
        <Flex width="100%" justifyContent="center" mt={10} gridGap={4}>
          {step !== 0 && (
            <Button colorScheme={color} onClick={() => setStep(step - 1)}>
              Previous
            </Button>
          )}
          {step !== stepsContent.length - 1 && (
            <Button colorScheme={color} onClick={() => setStep(step + 1)}>
              Next
            </Button>
          )}
          {step === stepsContent.length - 1 && (
            <Button colorScheme={color} onClick={() => history.push('/trades')}>
              Finish
            </Button>
          )}
        </Flex>
      </PageContainer>
    </WithoutDrawer>
  );
};

export default SetupWizard;
